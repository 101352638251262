import MuiLink from "@mui/material/Link";
import React from "react";

export const HowToDeployLink = () => {
  return (
    <MuiLink href={"https://docs.side-eye.io/installing/"} target={"_blank"}>
      How to deploy agents on new machines
    </MuiLink>
  );
};
