import {
  ProgramSelection,
  ProgramSelectionType,
} from "../../../__generated__/graphql.ts";
import {ProcessInfo, ProgramSelectionStatus} from "../@types.ts";
import {ProcessHierarchy} from "./processHierarchy.class.ts";

export class EnvironmentSelectionState {
  // The selected environment. If undefined then no environment has been
  // selected; if environments are not used, then this will be undefined. If
  // environments are used, then an undefined value will cause the Capture
  // Snapshot button to be disabled. Note that a selection of the "<unknown>"
  // environment is represented as undefined.
  readonly environment: string | undefined;
  // programsSelection is a map from program name to the selection status of
  // that program. The programs correspond to the selected environment.
  //
  // TODO: this map should be immutable; we currently mutate it but, when we do,
  // we always return a new environmentSelectionState.
  readonly programsSelection: Map<string, ProgramSelectionStatus>;

  constructor(
    environment?: string,
    programsSelection: Map<string, ProgramSelectionStatus> = new Map<
      string,
      ProgramSelectionStatus
    >(),
  ) {
    this.environment = environment;
    this.programsSelection = programsSelection;
  }

  clone = (): EnvironmentSelectionState => {
    return new EnvironmentSelectionState(
      this.environment,
      this.programsSelection,
    );
  };

  // empty returns true if no programs or processes are selected.
  empty = (): boolean => {
    for (let [k, v] of this.programsSelection) {
      if (v.type == "all") {
        return false;
      }
      if (v.type == "some" && v.selectedProcs.length != 0) {
        return false;
      }
    }
    return true;
  };

  // Convert to the GraphQL representation of the selection. Returns the
  // selected environment and the selected programs/processes.
  toGraph = (): [string | undefined, ProgramSelection[]] => {
    return [
      this.environment,
      Array.from(this.programsSelection.entries()).map(
        ([program, sel]): ProgramSelection => {
          let selectionType: ProgramSelectionType;
          let processTokens: string[] | undefined = undefined;
          switch (sel.type) {
            case "all":
              selectionType = ProgramSelectionType.AllProcesses;
              break;
            case "none":
              selectionType = ProgramSelectionType.NoProcesses;
              break;
            case "some":
              selectionType = ProgramSelectionType.SomeProcesses;
              processTokens = sel.selectedProcs.map((p) => p.processToken);
              break;
          }
          return {
            program: program,
            type: selectionType,
            processTokens: processTokens,
          };
        },
      ),
    ];
  };

  setProgramSelection(
    programName: string,
    state: "all" | "none",
  ): EnvironmentSelectionState {
    this.programsSelection.set(programName, {type: state});
    return new EnvironmentSelectionState(
      this.environment,
      this.programsSelection,
    );
  }

  setProcessSelection(
    process: ProcessInfo,
    selected: boolean,
    report: ProcessHierarchy,
  ): EnvironmentSelectionState {
    const env = process.environment;
    const program = process.programName;

    const programSelection = this.programsSelection.get(program);
    if (programSelection === undefined) {
      console.warn("bug: no program selection status for process", process);
      return this;
    }
    switch (programSelection.type) {
      case "all":
        if (selected) {
          console.warn(
            "bug: attempting to select a process that is already implicitly selected",
          );
          return this;
        }
        this.programsSelection.set(program, {
          type: "some",
          // All processes for the program are selected except the de-selected
          // one.
          selectedProcs: report
            .processesForEnvAndProgram(env, program)
            .filter((p) => p.processToken != process.processToken),
        });
        return new EnvironmentSelectionState(
          this.environment,
          this.programsSelection,
        );
      case "none":
        if (!selected) {
          console.warn(
            "bug: attempting to deselect a process that is already implicitly deselected",
          );
          return this;
        }
        this.programsSelection.set(program, {
          type: "some",
          // Only this process is selected.
          selectedProcs: [process],
        });
        return new EnvironmentSelectionState(
          this.environment,
          this.programsSelection,
        );
      case "some":
        const selectedProcs = programSelection.selectedProcs;
        if (selected) {
          if (
            selectedProcs.some((p) => p.processToken == process.processToken)
          ) {
            console.warn(
              "bug: attempting to select a process that is already selected",
            );
            return this;
          }
          selectedProcs.push(process);
          if (
            selectedProcs.length ==
            report.processesForEnvAndProgram(env, program).length
          ) {
            this.programsSelection.set(program, {type: "all"});
          }
        } else {
          if (
            !selectedProcs.some((p) => p.processToken == process.processToken)
          ) {
            console.warn(
              "bug: attempting to deselect a process that is already deselected",
            );
            return this;
          }
          programSelection.selectedProcs = selectedProcs.filter(
            (p) => p.processToken != process.processToken,
          );
          if (programSelection.selectedProcs.length == 0) {
            this.programsSelection.set(program, {type: "none"});
          }
        }
        return new EnvironmentSelectionState(
          this.environment,
          this.programsSelection,
        );
    }
  }
}
