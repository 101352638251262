/*
1. PathKeys Type:
PathKeys<T, K> recursively generates all possible key paths for the given object T.
This type helps in ensuring that the keyChain string matches valid key paths in the object.
It handles nested objects by concatenating keys using the dot (.) operator.

2. getValueByKeyChain Function:
The getValueByKeyChain function takes an object obj of type T and a keyChain string that must be a valid key path in the object (as validated by PathKeys).
The function splits the keyChain string into an array of keys and then iteratively accesses the nested properties of the object.

3. Return Type Inference:
The return type of the function is inferred based on the keyChain provided, ensuring type safety.
If any part of the key chain is invalid, TypeScript will raise a type error.

4. Usage:
The function can be used to safely access nested values in the object, with TypeScript ensuring that the key chain is valid.
*/

export type PathKeys<T> = T extends object
  ? {
      [K in keyof T & string]: T[K] extends object
        ? `${K}` | `${K}.${PathKeys<T[K]>}`
        : `${K}`;
    }[keyof T & string]
  : never;

export function getValueByKeyChain<T, K extends PathKeys<T>>(
  obj: T,
  keyChain: K,
): K extends `${infer F}.${infer R}`
  ? F extends keyof T
    ? R extends PathKeys<T[F]>
      ? ReturnType<typeof getValueByKeyChain<T[F], R>>
      : never
    : never
  : K extends keyof T
    ? T[K]
    : never {
  const keys = keyChain.split(".") as Array<keyof any>;
  let result: any = obj;

  for (const key of keys) {
    result = result[key];
    if (result === undefined) {
      break;
    }
  }

  return result;
}
