import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import toast from "react-hot-toast";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {IconEdit} from "../components/icons/IconEdit.tsx";

// EditableLabel is a span of text with an edit button next to it. Clicking the button
// turns the text into an editable textbox.
export default function EditableLabel(props: {
  label?: string;
  labelTooltip?: string;
  text: string;
  emptyText?: string;
  allowEmpty: boolean;
  disableEdit?: boolean;
  size?: "small" | "medium";
  showAsTextField?: boolean;
  // onSave is called when the user clicks the save button. It returns a
  // Promise; while the Promise is pending, the Save button shows a progress
  // spinner. If the Promise resolves to false, the saving is considered to have
  // been canceled (for example because the user rejected a confirmation dialog
  // or some error was encountered), and the EditableLabel stays in the editing
  // mode. If the Promise is rejected, the error is displayed in a toast and the
  // EditableLabel stays in editing mode.
  onSave: (newText: string) => Promise<boolean>;
  sx?: SxProps<Theme>;
  // editTooltip is the tooltip text for the edit button.
  editTooltip?: string;
}): React.JSX.Element {
  const [editing, setEditing] = useState(false);
  const [editText, setEditText] = useState(props.text);
  const [saving, setSaving] = useState(false);

  // Handle props.text changing after the initial render. In such cases, if we
  // didn't enter edit mode, we update editText.
  if (!editing && props.text != editText) {
    setEditText(props.text);
  }

  function onSave() {
    void (async () => {
      setSaving(true);
      await props
        .onSave(editText)
        .then(
          (res) => {
            if (res) {
              setEditing(false);
            }
          },
          (err) => {
            console.error(err);
            toast.error(`Failed to save: ${err}`);
          },
        )
        .finally(() => {
          setSaving(false);
        });
    })();
  }

  function onCancel() {
    setEditing(false);
  }

  const EditButton = () => {
    if (props.disableEdit) {
      return <></>;
    }

    return (
      <IconButton onClick={() => setEditing(true)} sx={{p: 0}}>
        <Tooltip title={props.editTooltip}>
          <span>
            <IconEdit />
          </span>
        </Tooltip>
      </IconButton>
    );
  };

  return (
    <Box sx={props.sx}>
      <Stack direction={"row"} spacing={1}>
        {props.label && (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography variant="caption">{props.label}</Typography>
            {props.labelTooltip && <HelpCircle tip={props.labelTooltip} />}
          </Stack>
        )}

        <Stack direction="row" alignItems="center" gap={1}>
          {editing ? (
            <>
              <TextField
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                autoFocus
                size={props.size}
              />
              <LoadingButton
                loadingPosition={"center"}
                loading={saving}
                onClick={onSave}
                disabled={!props.allowEmpty && editText == ""}
                sx={{p: "8px", minWidth: 0}}
              >
                <SaveIcon />
              </LoadingButton>
              <IconButton onClick={onCancel} color="primary">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              {props.showAsTextField ? (
                <TextField
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  autoFocus
                  size={props.size}
                  disabled={!editing}
                  slotProps={{
                    input: {
                      endAdornment: <EditButton />,
                    },
                  }}
                />
              ) : (
                <>
                  <Typography variant="body4">
                    {props.text || props.emptyText || props.text}
                  </Typography>
                  <EditButton />
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
