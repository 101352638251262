import {FrameInfo} from "@graphql/graphql.ts";
import React, {CSSProperties, useState} from "react";
import {
  IconButton,
  Paper,
  Stack as MuiStack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import FrameDataTable from "@components/frame-data-table.tsx";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewListIcon from "@mui/icons-material/ViewList";
import {useSnapshotState} from "src/providers/snapshot-state.tsx";
import {Link} from "react-router-dom";
import {schemaInfo, tableSchemaExt} from "src/util/spec.ts";
import {calculateColor} from "@components/Flamegraph/FlamegraphColors.ts";
import {ClickedFrame, parseNumber} from "@util/types";

type StackProps = {
  // The stack frames, in leaf-to-root order (i.e. frames[0] is the innermost call).
  frames: FrameInfo[];
  showOnlyFramesWithData: boolean;
  // rootToLeaf order, if set, indicates that the frames should be displayed
  // from root to leaf. If not set, they are displayed from leaf to root.
  rootToLeafOrder: boolean;
  schema: schemaInfo;
};

// Stack renders a stack trace, with captured data for each frame (for the
// frames that have any data). The stack trace can correspond to one goroutine,
// or to a group of goroutines with identical stacks.
export default function Stack(props: StackProps): React.JSX.Element {
  let frames = props.frames;
  if (props.rootToLeafOrder) {
    frames = [...frames].reverse();
  }
  type frameWithIdx = FrameInfo & {idx: number};
  let framesWithIdx: frameWithIdx[] = frames.map(
    (frame, idx): frameWithIdx => ({
      ...frame,
      idx,
    }),
  );
  if (props.showOnlyFramesWithData) {
    framesWithIdx = framesWithIdx.filter((frame) => frame.Data.length > 0);
  }

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        style={{tableLayout: "fixed"}}
        className={"stack-table"}
      >
        <TableBody>
          {framesWithIdx.map((frame) => (
            <TableRow key={frame.idx}>
              {/*Frame Index*/}
              <TableCell
                style={{
                  padding: "5px",
                  verticalAlign: "top",
                }}
                size="small"
                sx={{width: 26}}
              >
                {frame.idx}
              </TableCell>

              <TableCell
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  overflowWrap: "break-word",
                  verticalAlign: "top",
                  backgroundColor: calculateColor(frame.FuncName.QualifiedName),
                  paddingRight: "0px",
                  paddingLeft: "5px",
                  fontFamily: "monospace",
                }}
              >
                <StackFrame
                  frame={frame}
                  funcTableSchema={
                    props.schema.functionNameToTableSchema.get(
                      frame.FuncName.QualifiedName,
                    )?.framesTable
                  }
                  schema={props.schema}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function trimPath(file: string): string {
  // Keep only the dir name and the file name.
  const parts = file.split("/");
  return parts.slice(-2).join("/");
}

type StackFrameProps = {
  frame: FrameInfo;
  // funcTableSchema is the schema of the table corresponding to this frame's
  // function. undefined if the function does not have a frames table (i.e. no
  // data is captured for this function in snapshots).
  funcTableSchema: tableSchemaExt | undefined;
  schema: schemaInfo;
};

// StackFrame renders the name of the function corresponding to this frame and a
// table with the frame's data.
function StackFrame(props: StackFrameProps) {
  const {frame, funcTableSchema} = props;
  const [dataExpanded, setDataExpanded] = useState(true);
  const [style, setStyle] = useState<CSSProperties>({visibility: "hidden"});
  const snapshotState = useSnapshotState();
  const hasData = frame.Data.length > 0;
  if (hasData && !funcTableSchema) {
    throw new Error(
      "Frame has data, but no schema for the table corresponding to the function",
    );
  }

  const tableURL = funcTableSchema
    ? snapshotState.tablesController.showTableURL({
        __typename: "FunctionReference",
        FuncQualifiedName: frame.FuncName.QualifiedName,
      })
    : "";

  function showFrameSidebar() {
    const clickedFrame: ClickedFrame = {
      BinaryID: frame.BinaryID,
      FuncQualifiedName: frame.FuncName.QualifiedName,
      File: frame.File,
      Line: frame.Line,
      PC: parseNumber(frame.PC),
    };
    snapshotState.setSelectedFrame(clickedFrame);
  }

  return (
    <>
      <div
        style={{display: "flex"}}
        onMouseEnter={() => {
          setStyle({visibility: "visible"});
        }}
        onMouseLeave={() => {
          setStyle({visibility: "hidden"});
        }}
      >
        <MuiStack direction={"row"} style={style} mt={1}>
          <Tooltip
            title={
              "Filter to stacks containing " + frame.FuncName.QualifiedName
            }
          >
            <Link
              to={snapshotState.functionFilterURL(frame.FuncName)}
              style={{color: "#000"}}
            >
              <FilterAltIcon className={"no-padding"} fontSize={"small"} />
            </Link>
          </Tooltip>

          <Tooltip title={"Select expressions to capture for this frame"}>
            <MenuIcon
              className={"no-padding"}
              fontSize={"small"}
              sx={{cursor: "pointer"}}
              onClick={() => showFrameSidebar()}
            />
          </Tooltip>
        </MuiStack>

        <span
          style={{flexGrow: 1, cursor: hasData ? "pointer" : "auto"}}
          onClick={(event: React.MouseEvent) => {
            if (event.altKey) {
              showFrameSidebar();
            } else {
              if (!hasData) {
                return;
              }
              setDataExpanded(!dataExpanded);
            }
          }}
        >
          {frame.FuncName.QualifiedName}
          <br />
          {trimPath(frame.File)}:{frame.Line}
        </span>

        {hasData && (
          <MuiStack direction="row" alignItems={"center"}>
            <Tooltip
              title={
                "Navigate to the data table corresponding to this function"
              }
            >
              <Link to={tableURL} style={{color: "#000"}}>
                <ViewListIcon />
              </Link>
            </Tooltip>
            <IconButton
              onClick={() => setDataExpanded(!dataExpanded)}
              style={{backgroundColor: "rgba(255,255,255, 0.5)"}}
            >
              {dataExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </MuiStack>
        )}
      </div>

      {hasData && dataExpanded && (
        <FrameDataTable
          data={frame.Data}
          tableSchema={funcTableSchema!}
          schema={props.schema}
        />
      )}
    </>
  );
}
