import {gql} from "../../__generated__";

export const GET_SCHEDULE = gql(/* GraphQL */ `
  query GetSchedule($env: String) {
    getSchedule(environment: $env) {
      id
      Schedule {
        id
        environment
        enabled
        periodMillis
      }
      Next
    }
  }
`);

export const UPDATE_SCHEDULE = gql(/* GraphQL */ `
  mutation UpdateSchedule($input: ScheduleInput!) {
    updateSchedule(input: $input) {
      id
      Schedule {
        id
        environment
        enabled
        periodMillis
      }
      Next
    }
  }
`);
