import React, {Suspense, useContext, useState} from "react";
import {Outlet} from "react-router-dom";
import {AppConfigContext} from "@providers/app-config-provider.tsx";
import {Toaster} from "react-hot-toast";
import {
  getMenuBarOpenFromStorage,
  setMenuBarOpenInStorage,
} from "@util/local-storage.ts";
import Box from "@mui/material/Box";
import {CssBaseline, styled, useMediaQuery} from "@mui/material";
import {Sidebar} from "./components/Sidebar.tsx";
import {Header, headerHeight} from "./components/Header.tsx";

// Main is the main content area of the page. It is styled to move out of the way
// when the sidebar is open.
export const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({theme, open}) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// PageLayout sets up the sidebar and places a router Outlet in the main section
// of the page.
export default function PageLayout() {
  const appCfg = useContext(AppConfigContext);
  const user = appCfg.User;

  const [menuOpen, setMenuOpenInner] = useState(getMenuBarOpenFromStorage());
  const setOpen = (open: boolean) => {
    setMenuBarOpenInStorage(open);
    setMenuOpenInner(open);
  };

  const matches = useMediaQuery("(min-width:968px)");

  return (
    <Box sx={{display: "flex"}}>
      <Toaster />
      <CssBaseline />

      <Header open={menuOpen} />

      {(!appCfg.AuthEnabled || user) && (
        <Sidebar open={menuOpen} setOpen={setOpen} />
      )}

      <div
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
          width: "100%",
          marginTop: `${headerHeight}px`,
          overflowY: "auto",
        }}
      >
        <Main
          open={menuOpen}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: matches ? 5 : 2,
            pb: 4,
            height: "100%",
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </Main>
      </div>
    </Box>
  );
}
