import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiDivider = (): Pick<Components<typeof theme>, "MuiDivider"> => ({
  MuiDivider: {
    styleOverrides: {
      root: ({theme}) => ({
        fontSize: 12,
        color: theme.palette.text.secondary,
      }),
    },
    variants: [
      {
        props: {color: "gradient"},
        style: {
          background: "linear-gradient(90deg, #8453FF 0%, #47454A 100%)",
          border: "none",
          height: 1,
        },
      },
      {
        props: {color: "secondary"},
        style: ({theme}) => ({
          borderColor: theme.palette.background.default,
        }),
      },
    ],
  },
});
