import {useQuery} from "@apollo/client";
import {Divider, Stack, Tab, Typography} from "@mui/material";
import React, {Suspense, useContext} from "react";
import {useSearchParams} from "react-router-dom";
import LinksEditor from "./components/LinksEditor.tsx";
import {AppConfigContext} from "src/providers/app-config-provider";
import {GET_USER_INFO} from "../LoginPage/gqlHelpers.ts";
import {Tables} from "@components/tables/tables.tsx";
import {ModulesEditor} from "./components/ModulesEditor.tsx";
import RawSpecEditor from "./components/RawSpecEditor.tsx";
import {ProgramsEditor} from "./components/ProgramsEditor.tsx";
import {TabContext, TabList} from "@mui/lab";

// SnapshotSpecEditor is the editor of the spec used to collect snapshots,
// generated events, and assign friendly names to processes.
export default function SnapshotSpecEditor() {
  const appConfig = useContext(AppConfigContext);
  const user = appConfig.User;

  const {data: getUserInfoRes} = useQuery(GET_USER_INFO, {
    skip: !user,
  });

  let isSuperuser: boolean;
  if (!appConfig.Auth) {
    isSuperuser = true;
  } else {
    isSuperuser = getUserInfoRes?.getUserInfo?.superuser ?? false;
  }

  const [queryParams, setQueryParams] = useSearchParams();
  const setActiveTab = (newTab: string) => {
    queryParams.set("tab", newTab);
    setQueryParams(queryParams);
  };

  const activeTab = queryParams.get("tab") ?? "modules";

  function onTabClick(ev: React.SyntheticEvent<unknown>, tab: string) {
    const newWindow = ev["ctrlKey"] as boolean;
    if (!newWindow) {
      setActiveTab(tab);
    } else {
      const newParams = new URLSearchParams(queryParams);
      newParams.set("tab", tab);
      window.open(`${location.pathname}?${newParams.toString()}`, "_blank");
    }
  }

  return (
    <>
      <Stack sx={{my: 3}}>
        <Typography variant="h1">Spec</Typography>
      </Stack>

      <TabContext value={activeTab}>
        <TabList
          variant="scrollable"
          onChange={(ev, value) => onTabClick(ev, value as string)}
        >
          <Tab label="Data collection" value="modules" />
          <Tab label="Tables" value="tables" />
          <Tab label="Links" value="links" />
          <Tab label="Programs" value="programs" />
          {isSuperuser && <Tab label="Raw Spec" value="raw" />}
        </TabList>

        <Divider color="gradient"></Divider>

        <Suspense fallback={`Loading ${activeTab}...`}>
          {activeTab == "modules" && <ModulesEditor />}
          {activeTab == "programs" && <ProgramsEditor />}
          {activeTab == "tables" && <Tables />}
          {activeTab == "links" && <LinksEditor />}
          {isSuperuser && activeTab == "raw" && <RawSpecEditor />}
        </Suspense>
      </TabContext>
    </>
  );
}
