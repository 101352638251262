import {LabelRule} from "../../../../__generated__/graphql.ts";
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {Typography} from "@mui/material";
import {Label} from "../../../../util/labels.ts";

type Props = {
  applyLabel: string;
  labelRules: LabelRule[];
};

export const ProgramLabelMissing: React.FC<Props> = ({
  applyLabel,
  labelRules,
}) => {
  // If we're asked to apply the "program" label, we display a warning if there
  // is no rule for it.
  const programLabelMissing =
    applyLabel == Label.Program &&
    !labelRules.find((rule) => rule.label === Label.Program);

  if (!programLabelMissing) {
    return null;
  }

  return (
    <Typography variant="warning">
      <WarningAmberIcon color={"warning"} />
      There are no program rules. This means that no processes are being
      monitored. Add one or more rules specifying which processes should be
      monitored using either the "Add program rule" button above", or by finding
      a process you want to monitor in the list of processes below and clicking
      "Monitor process".
    </Typography>
  );
};
