import React, {useContext, useState} from "react";
import {Card, CardContent, CardHeader, Divider, Tab} from "@mui/material";
import {HowToDeployLink} from "./HowToDeployLink.tsx";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {TableAgents} from "./TableAgents.tsx";
import {groupBy} from "lodash";
import {AgentReportsContext} from "../../../providers/agent-reports-provider.tsx";

export const TabsEnvironments: React.FC = () => {
  const agentsReport = useContext(AgentReportsContext);
  const reports = agentsReport!.Report.Reports;

  const groupedReportsByEnvironment = groupBy(
    reports,
    (r) => r.Environment || "Environment not set",
  );

  const [selectedEnvTab, setSelectedEnvTab] = useState(
    Object.keys(groupedReportsByEnvironment)[0],
  );

  return (
    <Card>
      <CardHeader title="Agents" subheader={<HowToDeployLink />} />
      <CardContent>
        <TabContext value={selectedEnvTab}>
          <TabList
            variant="standard"
            onChange={(_, env) => setSelectedEnvTab(env as string)}
          >
            {Object.keys(groupedReportsByEnvironment).map((env) => (
              <Tab
                key={env}
                value={env}
                label={
                  env + ` (${groupedReportsByEnvironment[env]?.length || 0})`
                }
              />
            ))}
          </TabList>

          <Divider color="gradient"></Divider>

          {Object.keys(groupedReportsByEnvironment).map((env) => (
            <TabPanel key={env} value={env} sx={{padding: 0, mt: 3}}>
              <TableAgents reports={groupedReportsByEnvironment[env]} />
            </TabPanel>
          ))}
        </TabContext>
      </CardContent>
    </Card>
  );
};
