import {FC} from "react";
import {Tooltip} from "react-tooltip";
import {flip, offset, shift, size} from "@floating-ui/react";

// We're using react-tooltip instead of the usual MUI Tooltip because it's much faster for this large table.
export const TableTooltip: FC = () => {
  return (
    <Tooltip
      id="chip-tooltip"
      // Make the text wrap. I got this snipped from
      // https://github.com/ReactTooltip/react-tooltip/issues/1139#issuecomment-1860784133
      middlewares={[
        offset(10),
        flip(),
        size({
          apply: ({availableWidth, availableHeight, elements}) => {
            Object.assign(elements.floating.style, {
              maxWidth: `${Math.max(200, availableWidth - 50)}px`,
            });
          },
        }),
        shift({padding: 5}),
        flip({
          fallbackAxisSideDirection: "start",
        }),
      ]}
    />
  );
};
