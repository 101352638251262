import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import AllProcesses, {ProcessInfo} from "./AllProcesses.tsx";
import React from "react";
import {useApolloClient, useSuspenseQuery} from "@apollo/client";
import {GET_LABEL_RULES} from "../../gqlHelper.ts";
import {LabelRule} from "../../../../__generated__/graphql.ts";
import {getLabelValue, Label} from "../../../../util/labels.ts";
import {addLabelRule} from "../../label-helpers/add-label-rule.ts";
import {v4 as uuidv4} from "uuid";
import toast from "react-hot-toast";

export const AllProcessesWrapper = () => {
  const client = useApolloClient();
  // Check whether we have any program labeling rules defined; if we don't, the
  // AllProcesses component is expanded by default.
  let {data: labelRulesResult} = useSuspenseQuery(GET_LABEL_RULES, {});
  const anyProgramRules = labelRulesResult.getLabelRules.some(
    (rule: LabelRule) => rule.label == Label.Program,
  );
  const [allProcessesExpanded, setAllProcessesExpanded] =
    React.useState(!anyProgramRules);

  // monitorProcess adds a rule to assign the "program" label to processes that
  // use the same executable name as the process p. The executable name is also
  // used as the program name.
  async function monitorProcess(p: ProcessInfo) {
    const executableName = getLabelValue(Label.ExecutableName, p.labels);

    if (!executableName) {
      throw new Error(
        "monitorProcess: process does not have an executable name label",
      );
    }

    const errors = await addLabelRule(client, {
      label: Label.Program,
      value: executableName,
      predicates: [
        {
          label: Label.ExecutableName,
          valueRegex: executableName,
          id: uuidv4(),
        },
      ],
    });

    if (errors) {
      console.error(errors);
      toast.error(`Failed to add program rule: ${errors}`);
    } else {
      toast(
        `Added program rule for processes using the ${executableName} executable`,
      );
    }
  }

  return (
    <Accordion
      defaultExpanded={false}
      expanded={allProcessesExpanded}
      onChange={(_, expanded) => setAllProcessesExpanded(expanded)}
      disableGutters={true}
    >
      <AccordionSummary>
        <Typography variant="h2">All go processes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {allProcessesExpanded && (
          <AllProcesses onMonitorProcess={monitorProcess} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
