import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

export const MuiAutocomplete = (): Pick<
  Components<typeof theme>,
  "MuiAutocomplete"
> => ({
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <KeyboardArrowDownIcon />,
    },
    styleOverrides: {
      root: {
        fontSize: 14,
        ".MuiOutlinedInput-root": {
          padding: "0 14px",
        },
      },
      listbox: {
        paddingBlock: 0,
        ".MuiAutocomplete-option": {
          fontSize: 14,
          paddingBlock: 14,
        },
      },
      noOptions: {
        fontSize: 14,
      },
    },
  },
});
