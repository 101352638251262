import React from "react";

const IconHideLeft = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8585 29.4101C24.0925 29.2839 24.2384 29.0438 24.2384 28.7827V24.7173H32.2657C32.671 24.7173 33 24.396 33 24C33 23.6041 32.671 23.2828 32.2657 23.2828H24.2384V19.2173C24.2384 18.9553 24.0925 18.7152 23.8585 18.5899C23.6245 18.4628 23.3386 18.4714 23.1125 18.61L15.3427 23.3927C15.1292 23.5247 15 23.7533 15 24C15 24.2468 15.1292 24.4753 15.3427 24.6073L23.1125 29.39C23.2319 29.4627 23.368 29.5 23.5041 29.5C23.6255 29.5 23.7479 29.4694 23.8585 29.4101Z"
        fill="#47454A"
      />
    </svg>
  );
};

export default IconHideLeft;
