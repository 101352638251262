import {ApolloClient} from "@apollo/client";
import {LabelRuleInput} from "../../../__generated__/graphql.ts";
import {
  ADD_LABEL_RULE,
  GET_ALL_PROCESSES,
  GET_LABEL_RULES,
} from "../gqlHelper.ts";

export async function addLabelRule(
  client: ApolloClient<any>,
  input: LabelRuleInput,
) {
  const {errors} = await client.mutate({
    mutation: ADD_LABEL_RULE,
    variables: {
      input,
    },
    // refetch GET_ALL_PROCESSES because the computed labels might have changed.
    refetchQueries: [GET_LABEL_RULES, GET_ALL_PROCESSES],
  });
  return errors;
}
