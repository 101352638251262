import React, {ReactElement} from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

export interface MenuItem {
  matcher: (location: string) => boolean;
  link: string;
  name: string;
  icon: ReactElement;
  iconActive: ReactElement;
}

type Props = {
  item: MenuItem;
  open: boolean;
};

export const MenuItem: React.FC<Props> = ({item, open}) => {
  const location = useLocation();
  const isActive = item.matcher(location.pathname);

  return (
    <ListItem
      key={item.name}
      disablePadding
      sx={{display: "block", color: (theme) => theme.palette.info.dark}}
    >
      <Link to={item.link} style={{color: "inherit", textDecoration: "none"}}>
        <ListItemButton
          sx={{
            display: "flex",
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: (theme) =>
                isActive ? theme.palette.background.paper : null,
              borderRadius: "10px",
              mr: open ? 2 : "auto",
              width: "48px",
              height: "48px",
            }}
          >
            <Tooltip title={open ? null : item.name}>
              <ListItemIcon sx={{minWidth: 0}}>
                {isActive ? item.iconActive : item.icon}
              </ListItemIcon>
            </Tooltip>
          </Stack>
          <ListItemText
            primary={item.name}
            primaryTypographyProps={{
              color: isActive ? "text.primary" : "inherit",
              fontSize: 16,
            }}
            sx={{
              opacity: open ? 1 : 0,
              display: open ? "initial" : "none",
            }}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};
