import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiLink = (): Pick<Components<typeof theme>, "MuiLink"> => ({
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        ":hover": {
          color: "#535bf2",
        },
      },
    },
  },
});
