import React, {memo, PropsWithChildren} from "react";
import {IconButton, Stack, TableCell} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = PropsWithChildren & {state: boolean; onClick: () => void};

export const ExpandableCell = memo(({state, onClick, children}: Props) => (
  <TableCell
    sx={{
      padding: "16px !important",
      verticalAlign: "top",
      ...(state && {
        borderColor: (theme) => theme.palette.primary.main + " ! important",
      }),
    }}
  >
    <Stack flexDirection="row" gap={1} justifyContent="space-between">
      {children}

      <IconButton onClick={onClick}>
        {state ? (
          <ExpandLessIcon color="primary" />
        ) : (
          <ExpandMoreIcon color="action" />
        )}
      </IconButton>
    </Stack>
  </TableCell>
));
