import {ApolloClient, useApolloClient} from "@apollo/client";
import {Auth, signOut} from "firebase/auth";
import {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {AppConfigContext} from "src/providers/app-config-provider";

async function signOutFromAuth(auth: Auth, client?: ApolloClient<unknown>) {
  signOut(auth).catch((error) => {
    console.log("sign-out error", error);
  });

  // Reset the Apollo cache.
  //
  // Note that we use resetStore(), which causes active queries to be refetched.
  // These queries will fail (since there is no longer an auth token). This is
  // unfortunate, although benign (since we don't actually need the results of
  // the active queries any more after a sign-out). There is also
  // client.clearStore(), which does not have this refetch behavior, but that
  // method is dangerous: if any query remains active from before the sign-out
  // to after a new sign-in (e.g. if the query's component is not unmounted on
  // sign-out), then the query will return stale data after the new sign-in.
  if (client) {
    await client.resetStore();
  }
}

// useSignOut is a hook that returns a callback function that logs the user out
// and redirects them to the login page. Even if the user is not signed in, the
// returned callback function will still redirect them to the login page.
export default function useSignOut() {
  const appCfg = useContext(AppConfigContext);
  const client = useApolloClient();
  const navigationFunction = useNavigate();
  return useCallback(async () => {
    if (appCfg.Auth) {
      const auth = appCfg.Auth;
      await signOutFromAuth(auth, client);
    }
    // Redirect to the login page after signing out.
    navigationFunction("/login");
  }, [appCfg, client, navigationFunction]);
}
