import {useQuery} from "@apollo/client";
import {GET_HISTOGRAM} from "src/pages/EventsTrace";
import React from "react";
import DurationHistogramChart from "@components/DurationHistogramChart"; // LiveHistogram polls the server for a histogram of the latencies of events in

// LiveHistogram polls the server for a histogram of the latencies of events in
// a given log.
export default function LiveHistogram({
  logID,
  numBuckets,
  poll,
}: {
  logID: number;
  numBuckets: number;
  // If set, the histogram will poll the server for updates once a second.
  poll: boolean;
}): React.JSX.Element {
  const {
    loading,
    error,
    data: histogramRes,
  } = useQuery(GET_HISTOGRAM, {
    variables: {id: logID, numBuckets},
    pollInterval: poll ? 1000 : undefined,
  });

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>Error: {error.message}</>;
  }
  const latencyHistogram = histogramRes!.getDurationHistogramForLog;

  return <DurationHistogramChart histogram={latencyHistogram} />;
}
