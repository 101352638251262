import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {skipToken, useApolloClient, useSuspenseQuery} from "@apollo/client";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {GET_SCHEDULE, UPDATE_SCHEDULE} from "./gqlHelpers.ts";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import SelectorEnvironment from "../../components/SelectorEnvironment.tsx";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {noEnvSymbol} from "src/pages/Agents/components/AllProcesses/AllProcesses.tsx";
import {
  formatDurationNanos,
  NANOS_PER_MILLI,
  NANOS_PER_SECOND,
} from "@util/util.ts";

export default function Schedule() {
  const [env, setEnv] = useState<string | undefined | typeof noEnvSymbol>(
    undefined,
  );
  const {data: scheduleRes} = useSuspenseQuery(
    GET_SCHEDULE,
    env != undefined
      ? {
          variables: {
            env: env != noEnvSymbol ? env : null,
          },
        }
      : skipToken,
  );
  const schedule = scheduleRes?.getSchedule!.Schedule;
  const [editing, setEditing] = useState(false);
  const client = useApolloClient();

  let nextExecutionTime: string = "N/A";
  if (scheduleRes != undefined && scheduleRes.getSchedule?.Next) {
    nextExecutionTime = new Date(scheduleRes.getSchedule.Next).toLocaleString();
  }

  function onEdit() {
    setEditing(true);
  }

  function onUpdatePeriod(periodMillis: number) {
    if (schedule == undefined) {
      throw new Error("bug: updating schedule but schedule not set");
    }

    setEditing(false);
    void client.mutate({
      mutation: UPDATE_SCHEDULE,
      variables: {
        input: {
          id: schedule.id,
          periodMillis: periodMillis,
          enabled: schedule.enabled,
        },
      },
    });
  }

  function toggleEnabled(enabled: boolean) {
    void client.mutate({
      mutation: UPDATE_SCHEDULE,
      variables: {
        input: {
          id: schedule!.id,
          periodMillis: schedule!.periodMillis,
          enabled: enabled,
        },
      },
    });
  }

  return (
    <>
      <Box sx={{my: 3}}>
        <Typography variant="h1">Snapshots schedule</Typography>
      </Box>

      <Stack direction="row" alignItems="center" gap={2}>
        <SelectorEnvironment
          allowEmpty={false}
          selectedEnv={env}
          onChange={setEnv}
        />
        <HelpCircle tip="Select an environment" />
      </Stack>

      {schedule == undefined ? (
        <>No environments</>
      ) : (
        <>
          <Stack direction="row" gap={1} alignItems="center" mt={3}>
            <Switch
              checked={schedule.enabled}
              onChange={(_event, checked) => toggleEnabled(checked)}
            />

            <Box>
              <Card
                component={Stack}
                direction="row"
                gap={3}
                flexGrow={1}
                alignItems="center"
                py={1.5}
                px={3}
                square
              >
                <Stack direction={"column"}>
                  {!schedule.enabled && (
                    <Typography variant="body2">
                      Scheduled snapshots are currently disabled.
                    </Typography>
                  )}
                  <Stack direction={"row"} alignItems="center">
                    <Typography variant="body2" component="span">
                      Capture snapshots from all processes every&nbsp;
                    </Typography>
                    {editing ? (
                      <PeriodEditor
                        periodMillis={schedule.periodMillis}
                        onCancel={() => setEditing(false)}
                        onSave={onUpdatePeriod}
                      />
                    ) : (
                      <>
                        <Typography variant="body2" component="span">
                          {formatDurationNanos(
                            schedule.periodMillis * NANOS_PER_MILLI,
                          )}
                          .
                          {schedule.enabled && (
                            <>
                              &nbsp;Next snapshot would be captured at:{" "}
                              {nextExecutionTime}.
                            </>
                          )}
                          &nbsp;
                        </Typography>
                        <Link
                          variant="body2"
                          color="primary"
                          onClick={onEdit}
                          sx={{cursor: "pointer"}}
                        >
                          Change time
                        </Link>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
}

type unitEnum = "second" | "minute" | "hour";

function unitToMillis(unit: unitEnum): number {
  switch (unit) {
    case "second":
      return 1000;
    case "minute":
      return 60 * 1000;
    case "hour":
      return 60 * 60 * 1000;
  }
}

function durationToUnits(millis: number): [number, unitEnum] {
  const nanos = millis * NANOS_PER_MILLI;
  const NANOS_PER_MINUTE = NANOS_PER_SECOND * 60;
  const NANOS_PER_HOUR = NANOS_PER_SECOND * 3600;

  function toUnit(): unitEnum {
    const NANOS_PER_MINUTE = NANOS_PER_SECOND * 60;
    const NANOS_PER_HOUR = NANOS_PER_SECOND * 3600;
    if (nanos % NANOS_PER_HOUR == 0) {
      return "hour";
    }
    if (nanos % NANOS_PER_MINUTE == 0) {
      return "minute";
    }
    return "second";
  }

  const unit = toUnit();
  let units: number;
  switch (unit) {
    case "second":
      units = nanos / NANOS_PER_SECOND;
      break;
    case "minute":
      units = nanos / NANOS_PER_MINUTE;
      break;
    case "hour":
      units = nanos / NANOS_PER_HOUR;
      break;
  }
  return [units, unit];
}

type periodEditorProps = {
  periodMillis: number;
  onCancel: () => void;
  onSave: (periodMillis: number) => void;
};

// PeriodEditor renders the editor for the schedule duration: a textbox for a
// number and a selector for the unit.
function PeriodEditor(props: periodEditorProps): React.JSX.Element {
  const [inputUnits, inputUnit] = durationToUnits(props.periodMillis);
  const [period, setPeriod] = useState(inputUnits);
  const [unit, setUnit] = useState(inputUnit);
  const [invalid, setInvalid] = useState(false);

  const periodMillis = period * unitToMillis(unit);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Stack direction="row" alignItems="stretch">
        <TextField
          color="secondary"
          value={period}
          type="number"
          sx={{
            width: 80,
            ".MuiInputBase-root.MuiOutlinedInput-root": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          onChange={(e) => {
            const p = parseInt(e.target.value);
            if (!Number.isNaN(p)) {
              setPeriod(p);
              setInvalid(false);
            } else {
              setInvalid(true);
            }
          }}
        />
        <Divider
          orientation="vertical"
          sx={{
            height: "48px",
            backgroundColor: (theme) => theme.palette.info.main,
          }}
        />
        <Select
          color="secondary"
          value={unit}
          onChange={(e) => setUnit(e.target.value as unitEnum)}
          sx={{
            width: 120,
            "&.MuiOutlinedInput-root": {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
        >
          <MenuItem value="second">seconds</MenuItem>
          <MenuItem value="minute">minutes</MenuItem>
          <MenuItem value="hour">hours</MenuItem>
        </Select>
      </Stack>

      <Button
        variant="outlined"
        onClick={() => props.onSave(periodMillis)}
        color="info"
        disabled={invalid}
      >
        <SaveIcon color="primary" />
      </Button>
      <Button variant="outlined" onClick={props.onCancel} color="info">
        <CloseIcon color="primary" />
      </Button>
    </Stack>
  );
}
