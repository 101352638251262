import React from "react";

export const IconDownload: React.FC<{color?: string}> = ({color}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2301 5.29052V1.2815C9.2301 0.855229 9.5701 0.5 10.0001 0.5C10.3851 0.5 10.7113 0.798491 10.763 1.17658L10.7701 1.2815V5.29052L15.55 5.29083C17.93 5.29083 19.8853 7.23978 19.9951 9.67041L20 9.88609V14.9254C20 17.373 18.1127 19.3822 15.768 19.495L15.56 19.5H4.44C2.06 19.5 0.11409 17.5608 0.00483778 15.1213L0 14.9047L0 9.87576C0 7.4281 1.87791 5.40921 4.22199 5.29585L4.43 5.29083H9.23V11.6932L7.63 10.041C7.33 9.73119 6.84 9.73119 6.54 10.041C6.39 10.1959 6.32 10.4024 6.32 10.6089C6.32 10.7659 6.3648 10.9295 6.45952 11.0679L6.54 11.1666L9.45 14.1819C9.59 14.3368 9.79 14.4194 10 14.4194C10.1667 14.4194 10.3333 14.362 10.4653 14.2533L10.54 14.1819L13.45 11.1666C13.75 10.8568 13.75 10.3508 13.45 10.041C13.1773 9.75936 12.7475 9.73375 12.4462 9.96418L12.36 10.041L10.77 11.6932V5.29083L9.2301 5.29052Z"
        fill={color || "#8453FF"}
      />
    </svg>
  );
};
