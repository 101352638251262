import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiCard = (): Pick<Components<typeof theme>, "MuiCard"> => ({
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 24,
      },
    },
    variants: [
      {
        props: {square: true},
        style: {
          borderRadius: 10,
        },
      },
      {
        props: {color: "default"},
        style: ({theme}) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
      {
        props: {variant: "dense"},
        style: {
          ".MuiCardContent-root, .MuiCardHeader-root": {
            paddingInline: 0,
          },
        },
      },
    ],
  },
});
