import {IconButton, Tooltip, Typography} from "@mui/material";
import DeleteModuleDialog from "./DeleteModuleDialog.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {ModuleSpec} from "@graphql/graphql.ts";
import {useApolloClient} from "@apollo/client";
import {useConfirmationDialog} from "@providers/confirmation-dialog.tsx";

type Props = {
  module: ModuleSpec;
};

export const DeleteModuleButton: React.FC<Props> = ({module}) => {
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();

  return (
    <Tooltip
      title={
        <>
          <Typography>Delete module spec</Typography>
          Remove this module and all the function specs in it. No data will be
          collected any more for any function in this module.
        </>
      }
    >
      <span>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            void DeleteModuleDialog(
              module.pkgPath,
              client,
              showConfirmationDialog,
            );
          }}
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
