import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiToolbar = (): Pick<Components<typeof theme>, "MuiToolbar"> => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingInline: "40px !important",
      },
    },
  },
});
