import {
  FunctionSpec,
  LinkSpec,
  ProgramSpec,
  SnapshotSpec,
  TableDetails,
  TableReference,
  TableSchema,
  TableSpec,
  TypeSpec,
} from "src/__generated__/graphql.ts";
import {exhaustiveCheck} from "src/util/util.ts";
import {DeepPartial} from "@apollo/client/utilities";

export function getFunctionSpec(
  spec: SnapshotSpec,
  funcQualifiedName: string,
): FunctionSpec | undefined {
  for (const m of spec.modules) {
    for (const f of m.functionSpecs) {
      if (f.funcName.QualifiedName == funcQualifiedName) {
        return f;
      }
    }
  }
  return undefined;
}

export function getDerivedTableSpec(
  spec: SnapshotSpec,
  tableID: string,
): TableSpec | undefined {
  return spec.tables.find((t) => t.id == tableID);
}

// tableNameToTableReference looks through the derived tables, function tables
// and event tables in the spec to find one with the given name.
export function tableNameToTableReference(
  spec: SnapshotSpec,
  tableName: string,
): TableReference | undefined {
  // Look through the derived tables.
  const derivedTableSpec = spec.tables.find((t) => t.name == tableName);
  if (derivedTableSpec) {
    return {
      __typename: "DerivedTableReference",
      TableID: derivedTableSpec.id,
    };
  }
  // Look through the function and event tables.
  for (const m of spec.modules) {
    for (const f of m.functionSpecs) {
      if (f.snapshotSpec?.tableName == tableName) {
        return {
          __typename: "FunctionReference",
          FuncQualifiedName: f.funcName.QualifiedName,
        };
      }
      if (f.functionStartEvent?.tableName == tableName) {
        return {
          __typename: "FunctionStartEventReference",
          FuncQualifiedName: f.funcName.QualifiedName,
        };
      }
    }
  }

  // TODO: deal with the builtin tables. It's not trivial because they are not
  // present in spec currently.

  return undefined;
}

export type tableInfo = {
  tableName: string;
  // A table identifier suitable to be printed for the user. Note that, for
  // references to functions, this will be the function's qualified name, not
  // the function's table name.
  displayName: string;
  tableReference: TableReference;
};

// tableReferenceToTableInfo resolves a TableReference to info about that table
// by looking for the table in the spec (both in derived tables and function
// specs).
//
// Note that if you have a schemaInfo at your disposal, you should use
// schemaInfo.resolveTableReference() instead; that is more efficient.
export function tableReferenceToTableInfo(
  ref: TableReference,
  spec: SnapshotSpec,
): tableInfo | undefined {
  switch (ref.__typename) {
    case "DerivedTableReference": {
      const derivedTableSpec = getDerivedTableSpec(spec, ref.TableID);
      if (!derivedTableSpec) {
        return undefined;
      }
      return {
        tableName: derivedTableSpec.name,
        displayName: derivedTableSpec.name,
        tableReference: ref,
      };
    }
    case "FunctionReference": {
      const funcSpec = getFunctionSpec(spec, ref.FuncQualifiedName);
      if (!funcSpec) {
        return undefined;
      }
      return {
        tableName: funcSpec.snapshotSpec!.tableName,
        displayName: ref.FuncQualifiedName,
        tableReference: ref,
      };
    }
    case "FunctionStartEventReference": {
      const funcSpec = getFunctionSpec(spec, ref.FuncQualifiedName);
      if (!funcSpec) {
        return undefined;
      }
      return {
        tableName: funcSpec.functionStartEvent!.tableName,
        displayName: ref.FuncQualifiedName,
        tableReference: ref,
      };
    }
    case "BuiltinTableReference":
      return {
        tableReference: ref,
        displayName: ref.TableName,
        tableName: ref.TableName,
      };
    case undefined:
      throw new Error("tableReferenceToTableName: __typename is undefined");
    default:
      exhaustiveCheck(ref);
  }
}

export function tableDetailsToTableReference(
  table: DeepPartial<TableDetails>,
  tableName: string,
): TableReference {
  switch (table.__typename) {
    case "DerivedTableDetails":
      return {
        __typename: "DerivedTableReference",
        TableID: table.Spec!.id!,
      };
    case "FunctionTableDetails":
      return {
        __typename: "FunctionReference",
        FuncQualifiedName: table.FuncName!.QualifiedName!,
      };
    case "EventsTableDetails":
      return {
        __typename: "FunctionStartEventReference",
        FuncQualifiedName: table.FuncName!.QualifiedName!,
      };
    case "BuiltinTableDetails":
      return {
        __typename: "BuiltinTableReference",
        TableName: tableName,
      };
    case undefined:
      throw new Error("table.__typename is undefined");
    default:
      exhaustiveCheck(table);
  }
}

export function tableReferenceToInputID(ref: TableReference): string {
  switch (ref.__typename) {
    case "DerivedTableReference":
      return ref.TableID;
    case "FunctionReference":
      return ref.FuncQualifiedName;
    case "FunctionStartEventReference":
      throw new Error("unimplemented table ID for events table");
    case "BuiltinTableReference":
      return ref.TableName;
    case undefined:
      throw new Error("tableReferenceToTableName: __typename is undefined");
    default:
      exhaustiveCheck(ref);
  }
}

export function getTypeSpec(
  spec: SnapshotSpec,
  typeQualifiedName: string,
): TypeSpec | undefined {
  for (const m of spec.modules) {
    for (const t of m.typeSpecs) {
      if (t.typeQualifiedName === typeQualifiedName) {
        return t;
      }
    }
  }
  return undefined;
}

export function getProgram(
  spec: SnapshotSpec,
  programName: string,
): ProgramSpec | undefined {
  for (const p of spec.programs) {
    if (p.programName == programName) {
      return p;
    }
  }
  return undefined;
}

export function getLinkSpec(
  spec: SnapshotSpec,
  linkID: string,
): LinkSpec | undefined {
  return spec.links.find((l) => l.id == linkID);
}

type functionTables = {
  framesTable: tableSchemaExt | undefined;
  eventsTable: tableSchemaExt | undefined;
};

export type tableSchemaExt = {
  schema: TableSchema;
  // The index of the goroutine_id column within the tables columns.
  goroutineIDColumnIdx: number;
};

// schemaInfo is a distilled version of a collection of TableSchema. It contains
// indexes by function and table name, and it looks for the goroutine_id columns
// in function tables.
export class schemaInfo {
  schema: TableSchema[];
  // Function-qualified name to schema for the table corresponding to the
  // function. Functions that don't have tables (i.e. functions for which no
  // data is collected) do not appear in this index.
  functionNameToTableSchema: Map<string, functionTables>;
  tableNameToTableSchema: Map<string, TableSchema>;
  derivedTableIDToTableSchema: Map<string, TableSchema>;

  constructor(tableSchemas: TableSchema[]) {
    this.schema = tableSchemas;
    this.functionNameToTableSchema = new Map<string, functionTables>();
    this.tableNameToTableSchema = new Map<string, TableSchema>();
    this.derivedTableIDToTableSchema = new Map<string, TableSchema>();
    for (const tableSchema of tableSchemas) {
      this.tableNameToTableSchema.set(tableSchema.Name, tableSchema);
      if (tableSchema.Details.__typename == "DerivedTableDetails") {
        this.derivedTableIDToTableSchema.set(
          tableSchema.Details.Spec.id,
          tableSchema,
        );
      }

      // For functionNameToTableSchema, we're only interested in function tables
      // and events tables.
      if (
        tableSchema.Details.__typename != "FunctionTableDetails" &&
        tableSchema.Details.__typename != "EventsTableDetails"
      ) {
        continue;
      }
      // Find the index of the goroutine_id column.
      const gid_idx = tableSchema.Columns.findIndex(
        (col) => col.Name == "goroutine_id",
      );
      if (gid_idx == -1) {
        throw new Error("goroutine_id column missing in table schema");
      }
      const info: tableSchemaExt = {
        schema: tableSchema,
        goroutineIDColumnIdx: gid_idx,
      };

      const funcName = tableSchema.Details.FuncName.QualifiedName;
      let funcTables = this.functionNameToTableSchema.get(funcName);
      if (funcTables == undefined) {
        funcTables = {framesTable: undefined, eventsTable: undefined};
      }
      if (tableSchema.Details.__typename == "FunctionTableDetails") {
        funcTables.framesTable = info;
      } else {
        funcTables.eventsTable = info;
      }
      this.functionNameToTableSchema.set(
        tableSchema.Details.FuncName.QualifiedName,
        funcTables,
      );
    }
  }

  // resolveTableReference returns info about the table described by the given
  // reference.
  resolveTableReference(ref: TableReference): tableInfo | undefined {
    switch (ref.__typename) {
      case "FunctionReference": {
        const t = this.functionNameToTableSchema.get(ref.FuncQualifiedName);
        if (t == undefined) {
          return undefined;
        }
        return {
          tableName: t.framesTable!.schema.Name,
          displayName: ref.FuncQualifiedName,
          tableReference: ref,
        };
      }
      case "FunctionStartEventReference": {
        const t = this.functionNameToTableSchema.get(ref.FuncQualifiedName);
        if (t == undefined) {
          return undefined;
        }
        return {
          tableName: t.eventsTable!.schema.Name,
          displayName: `${ref.FuncQualifiedName}-start`,
          tableReference: ref,
        };
      }
      case "DerivedTableReference": {
        const t = this.derivedTableIDToTableSchema.get(ref.TableID);
        if (t == undefined) {
          return undefined;
        }
        return {
          tableName: t.Name,
          displayName: t.Name,
          tableReference: ref,
        };
      }
      case "BuiltinTableReference": {
        const t = this.tableNameToTableSchema.get(ref.TableName);
        if (t == undefined) {
          return undefined;
        }
        return {
          tableName: t.Name,
          displayName: t.Name,
          tableReference: ref,
        };
      }
      case undefined:
        throw new Error("__typename is undefined");
      default:
        exhaustiveCheck(ref);
    }
  }
}
