import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiTypography = (): Pick<
  Components<typeof theme>,
  "MuiTypography"
> => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        ".MuiSvgIcon-root": {
          verticalAlign: "middle",
        },
      },
    },
  },
});
