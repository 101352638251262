import {useRouteError} from "react-router-dom";
import React from "react";

export function MyErrorBoundary() {
  const error = useRouteError();

  console.error(error);

  if (error instanceof Error) {
    return <div>Error: {error.message}</div>;
  }
  return <div>Unhandled error. Details in console</div>;
}
