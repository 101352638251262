import {TableCell, TableRow} from "@mui/material";
import React from "react";

type Props = {
  message?: string;
};

export const TableNoDataMessage: React.FC<Props> = ({message}) => (
  <TableRow>
    <TableCell colSpan={99} color="secondary" align="center">
      {message || "No data"}
    </TableCell>
  </TableRow>
);
