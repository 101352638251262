import React from "react";

const IconSpec: React.FC<{color?: string}> = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76822 7.60981C7.12179 8.03409 7.06446 8.66466 6.64018 9.01822L3.06205 12L6.64018 14.9818C7.06446 15.3353 7.12179 15.9659 6.76822 16.3902C6.41466 16.8145 5.78409 16.8718 5.35982 16.5182L0.859816 12.7682C0.631823 12.5782 0.5 12.2968 0.5 12C0.5 11.7032 0.631823 11.4218 0.859816 11.2318L5.35982 7.48178C5.78409 7.12821 6.41466 7.18554 6.76822 7.60981Z"
        fill={color || "#47454A"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2318 7.60981C17.5854 7.18554 18.2159 7.12821 18.6402 7.48178L23.1402 11.2318C23.3682 11.4218 23.5 11.7032 23.5 12C23.5 12.2968 23.3682 12.5782 23.1402 12.7682L18.6402 16.5182C18.2159 16.8718 17.5854 16.8145 17.2318 16.3902C16.8782 15.9659 16.9356 15.3353 17.3598 14.9818L20.938 12L17.3598 9.01822C16.9356 8.66466 16.8782 8.03409 17.2318 7.60981Z"
        fill={color || "#47454A"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3418 2.81021C15.8608 2.99895 16.1286 3.57271 15.9398 4.09174L9.93982 20.5917C9.75108 21.1108 9.17732 21.3785 8.65828 21.1898C8.13925 21.0011 7.8715 20.4273 8.06023 19.9083L14.0602 3.40826C14.249 2.88922 14.8227 2.62147 15.3418 2.81021Z"
        fill={color || "#47454A"}
      />
    </svg>
  );
};

export default IconSpec;
