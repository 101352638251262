import {z} from "zod";
import {
  jsonMapReviver,
  SessionStorageKeys,
} from "../../../util/local-storage.ts";
import {EnvironmentSelectionState} from "./environmentSelectionState.class.ts";

// selectionFromSessionStorage reads and parses the process selection state from session storage.
export function selectionFromSessionStorage(
  sessionKey: SessionStorageKeys,
): EnvironmentSelectionState | undefined {
  // zod schema for environmentSelectionState.
  const sessionSchema = z.object({
    environment: z.string().optional(),
    programsSelection: z.map(
      z.string(),
      z.union([
        z.object({type: z.literal("all")}),
        z.object({type: z.literal("none")}),
        z.object({
          type: z.literal("some"),
          selectedProcs: z.array(z.any()),
        }),
      ]),
    ),
  });

  // Initialize the process selection from session storage.
  const sessionStateRaw = sessionStorage.getItem(sessionKey);
  if (!sessionStateRaw) {
    return undefined;
  }

  const parseResult = sessionSchema.safeParse(
    JSON.parse(sessionStateRaw, jsonMapReviver),
  );

  if (!parseResult.success) {
    console.error(
      "failed to parse stored process state",
      sessionStateRaw,
      parseResult.error,
    );
    return undefined;
  } else {
    return new EnvironmentSelectionState(
      parseResult.data.environment,
      parseResult.data.programsSelection,
    );
  }
}
