import {ProcessInfo} from "../@types.ts";

type reportDelta = {
  staleProcessesByProgram: Map<string, ProcessInfo[]>;
  newPrograms: string[];
};

export class EnvironmentProcesses {
  programs: Map<string, ProcessInfo[]>;

  constructor() {
    this.programs = new Map<string, ProcessInfo[]>();
  }

  addProcess(process: ProcessInfo) {
    let procs = this.programs.get(process.programName);
    if (procs === undefined) {
      procs = [];
      this.programs.set(process.programName, procs);
    }
    procs.push(process);
  }

  hasProcess(p: ProcessInfo): boolean {
    const procsForProgram = this.programs.get(p.programName);
    if (procsForProgram === undefined) {
      return false;
    }
    return procsForProgram.some((v) => v.processToken == p.processToken);
  }

  // Returns the differences between `this` and `other`.
  delta(other: EnvironmentProcesses): reportDelta {
    const removedProcs = new Map<string, ProcessInfo[]>();
    Array.from(this.programs.keys()).forEach((program) => {
      const myProcs = this.programs.get(program)!;
      const otherProcs = other.programs.get(program);
      if (otherProcs === undefined) {
        removedProcs.set(program, myProcs);
      } else {
        removedProcs.set(
          program,
          myProcs.filter((p) => !other.hasProcess(p)),
        );
      }
    });

    const addedPrograms = Array.from(other.programs.keys()).filter(
      (p) => !this.programs.has(p),
    );
    return {
      newPrograms: addedPrograms,
      staleProcessesByProgram: removedProcs,
    };
  }
}
