import React from "react";
import {Box, Button, Checkbox, Stack, Typography} from "@mui/material";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {CircleExclamationMarkTooltip} from "src/components/util.tsx";

type ExprTreeProps = {
  nodes: treeNode[];
  // disabled, if set, makes all checkboxes in the tree disabled.
  disabled?: boolean;
  onNodeToggle: (expandedNodeIds: string[]) => void;
  onNodeCollectChange: (node: treeNode, checked: boolean) => void;
  // onNodeDelete is called when the "delete" button is clicked on a node that
  // is not compatible with the current binary.
  onNodeDelete: (node: treeNode) => void;
  expandedNodeIDs: string[];
};

export enum treeNodeType {
  VARIABLE,
  PARAMETER,
  RETURN_VALUE,
  STRUCT_FIELD,
}

export type treeNode = {
  name: string;
  nodeType: treeNodeType;
  fullExpr: string;
  // typeName is the name of the type of the variable or field represented by
  // this node. If it's undefined, that means that the node corresponds to an
  // expression that is not compatible with the current binary. In that case,
  // the tree will display a button for deleting the expression.
  typeName: string | undefined;
  // childrenNotLoaded, if set, means that the node might have children, but the
  // `children` field has not been populated. treeNode's start out with
  // childrenNotLoaded if they have children but the children should not be
  // visible because the tree node is not expanded. treeNode's with
  // childrenNotLoaded set get an arrow button that expands the node on click.
  childrenNotLoaded: boolean;
  // If children is undefined and childrenNotLoaded is false, then the node has
  // no children. Otherwise, the respective node in the tree will be rendered
  // with a button to expand it.
  children?: treeNode[];
  locListAvailable: boolean;
  // collected indicates if the expression represented by this node is being
  // collected. If the node represents a collected expression, the collected is
  // "yes". If one or more of the node's children are collected (i.e. the node's
  // expression is a prefix of a collected expression), then collected is
  // "partial". Otherwise, collected is "no".
  collected: "yes" | "no" | "partial";
  // If note is set, it will be shown in an exclamation mark tooltip next to the
  // variable name.
  note: string | undefined;
};

// ExpressionTree renders a tree of variables and their fields. The tree is
// passed in through props, and represents either the list of variables
// available in a stack frame, or the fields of a type. For nodes of a struct
// type, children are the struct fields. When a node is expanded or collapsed,
// props.onNodeToggle is called; this lets the parent lazily load a node's
// children.
export default function ExpressionTree(
  props: ExprTreeProps,
): React.JSX.Element {
  const onNodeToggle = (event: unknown, nodeIds: string[]): void => {
    props.onNodeToggle(nodeIds);
  };

  const onNodeCollectChange = async (
    event: React.MouseEvent<HTMLButtonElement>,
    node: treeNode,
  ): Promise<void> => {
    event.stopPropagation();
    const checked = (event.target as HTMLInputElement).checked;
    props.onNodeCollectChange(node, checked);
  };

  const renderTree = (nodes: treeNode[]): React.JSX.Element[] => {
    if (nodes.length == 0) {
      return [
        <Box key={"no-fields"}>
          <Typography variant={"explanation"}>Struct has no fields.</Typography>
        </Box>,
      ];
    }
    return nodes.map((node) => {
      return (
        <TreeItem
          key={node.fullExpr}
          itemId={node.fullExpr}
          label={
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              sx={{
                whiteSpace: "nowrap",
                color: node.locListAvailable ? "" : "grey",
              }}
            >
              <Checkbox
                checked={node.collected === "yes"}
                indeterminate={node.collected === "partial"}
                onClick={(event) => onNodeCollectChange(event, node)}
                tabIndex={-1}
                disabled={node.typeName == undefined || props.disabled}
              />

              <Typography variant="body3">{node.name}</Typography>

              {node.note && <CircleExclamationMarkTooltip tip={node.note} />}

              {node.typeName != undefined ? (
                <Typography variant="body3" color="secondary">
                  {node.nodeType == treeNodeType.RETURN_VALUE
                    ? "return value"
                    : node.nodeType == treeNodeType.PARAMETER
                      ? "param"
                      : node.nodeType == treeNodeType.VARIABLE
                        ? "variable"
                        : "field"}{" "}
                  : {node.typeName ?? "<unknown type>"}
                </Typography>
              ) : (
                <Button onClick={() => props.onNodeDelete(node)}>Delete</Button>
              )}
            </Stack>
          }
        >
          {renderChildren(node)}
        </TreeItem>
      );
    });
  };

  const renderChildren = (node: treeNode): React.JSX.Element[] | undefined => {
    if (node.children) {
      return renderTree(node.children);
    }
    // Render a dummy child node to force the tree to render an "expand" icon.
    if (node.childrenNotLoaded) {
      const key = node.fullExpr + ".dummy";
      return [<TreeItem itemId={key} key={key}></TreeItem>];
    }
    return undefined;
  };

  return (
    <>
      <SimpleTreeView
        multiSelect
        slots={{expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon}}
        expandedItems={props.expandedNodeIDs}
        onExpandedItemsChange={onNodeToggle}
      >
        {renderTree(props.nodes)}
      </SimpleTreeView>
    </>
  );
}
