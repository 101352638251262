import CodeMirror, {EditorView, keymap, Prec} from "@uiw/react-codemirror";
import {sql} from "@codemirror/lang-sql";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Button from "@mui/material/Button";
import React, {useEffect} from "react";
import {ApolloError, useApolloClient, useSuspenseQuery} from "@apollo/client";
import {
  UserDefinedTable,
  UserDefinedTableData,
} from "src/components/tables/tables.tsx";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {autocompletion, CompletionContext} from "@codemirror/autocomplete";
import {
  addOrUpdateDerivedTableSpec,
  confirmationDialogInfo,
  tableCompletions,
  tablesURLController,
} from "src/components/tables/util.tsx";
import {TableReference} from "src/__generated__/graphql.ts";
import {schemaInfo} from "src/util/spec.ts";
import {SQLShellTheme} from "../../theme/SQLShellTheme.ts";
import {GET_TABLE_NAMES, RUN_SQL_QUERY} from "@util/queries.tsx";

export default function SQLShell(props: {
  snapshotID?: number;
  initialQuery?: string;
  // If set, initialQuery will be run on the first render.
  initialRun?: boolean;
  initialGrouping?: string[];
  showTableValidationErrorConfirmationDialog: (
    _: confirmationDialogInfo,
  ) => Promise<boolean>;
  onNewTableAdded: (newTable: TableReference) => void;
  controller: tablesURLController;
  schema: schemaInfo;
}): React.JSX.Element {
  if (props.initialRun && !props.initialQuery) {
    throw new Error("initialQuery must be set if initialRun is true");
  }
  if (props.initialGrouping && !props.initialRun) {
    throw new Error("initialRun must be set if initialGrouping is set");
  }

  const [query, setQuery] = React.useState(props.initialQuery ?? "");
  const [table, setTable] = React.useState<UserDefinedTableData | undefined>(
    undefined,
  );
  // tableQuery tracks the query that generated `table`, if any - i.e. the last
  // query that was run. This is different from `query`, which tracks the
  // current value of the textbox.
  const [tableQuery, setTableQuery] = React.useState("");
  const client = useApolloClient();
  const [queryError, setQueryError] = React.useState("");

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [tableName, setTableName] = React.useState("");

  // Get the names of all tables to use for auto-completion.
  const {data: tableNamesRes} = useSuspenseQuery(GET_TABLE_NAMES, {
    // Throw on errors (the default).
    errorPolicy: "none",
  });

  const runQuery = async (query: string) => {
    try {
      const {data} = await client.query({
        query: RUN_SQL_QUERY,
        variables: {
          snapshotID: props.snapshotID,
          query: query,
        },
        // Seems like a good idea to not cache the results of arbitrary queries.
        fetchPolicy: "no-cache",
      });
      setTable(data.runSQLQuery);
      setTableQuery(query);
      setQueryError("");
    } catch (e) {
      if (e instanceof ApolloError) {
        setQueryError(e.message);
      } else {
        setQueryError(String(e));
        console.log(e);
      }
    }
  };

  // On the first render, run the initial query if it is set.
  useEffect(
    () => {
      if (props.initialRun) {
        void runQuery(query);
      }
    },
    // This effect runs on the first render only; we don't care about deps
    // changing (we don't support changes in the initialRun prop).
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Hack the theme for the editor so that we can set a minHeight without the
  // horizontal scroll bar appearing in the wrong place. See
  // https://github.com/uiwjs/react-codemirror/issues/615#issuecomment-1890754380
  const minHeight = "100px";
  const theme = EditorView.theme({
    "& div.cm-scroller": {
      minHeight: `${minHeight} !important`,
    },
  });

  const onAddTableToSpecClick = () => {
    setDialogOpen(true);
  };

  const addTableToSpec = async () => {
    void (await addOrUpdateDerivedTableSpec(
      client,
      {
        id: undefined, // Add a new table.
        name: tableName,
        query: query,
      },
      props.showTableValidationErrorConfirmationDialog,
    ).then((res) => {
      if (res != false) {
        props.onNewTableAdded(res);
      }
      setDialogOpen(false);
    }));
  };

  // Make Ctrl-Enter run the query.
  const customKeymap = Prec.highest(
    keymap.of([
      {
        key: "Ctrl-Enter",
        run: (_editor) => {
          void runQuery(query);
          return true;
        },
      },
    ]),
  );

  function onSaveDialogCanceled() {
    setDialogOpen(false);
  }

  return (
    <Box>
      <CodeMirror
        value={query}
        style={{border: "1px solid black", color: "black"}}
        // Make the editor grow vertically to fit the text.
        height="auto"
        theme={SQLShellTheme}
        extensions={[
          sql(),
          theme,
          customKeymap,
          autocompletion({
            override: [
              (context: CompletionContext) =>
                tableCompletions(
                  context,
                  tableNamesRes.getSchema.map((t) => t.Name),
                ),
            ],
          }),
        ]}
        basicSetup={true}
        onChange={(value) => setQuery(value)}
      />
      <Stack direction="row" gap={3} my={3}>
        <Button
          variant={"contained"}
          sx={{width: "1px"}}
          onClick={() => {
            void runQuery(query);
          }}
        >
          Run
        </Button>
        <Button
          variant={"contained"}
          sx={{ml: 1, width: "250px"}}
          onClick={onAddTableToSpecClick}
          disabled={
            props.initialQuery != undefined && query == props.initialQuery
          }
        >
          Save as new table
        </Button>
      </Stack>

      {queryError && (
        <div style={{display: "flex", alignItems: "center"}}>
          <WarningAmberIcon style={{color: "red"}} />
          <div style={{color: "red", marginLeft: "5px"}}>{queryError}</div>
        </div>
      )}

      {table && (
        <UserDefinedTable
          table={table}
          // Reset the table state when the query changes.
          key={tableQuery}
          initialGrouping={
            // We want props.initialGrouping to only be applied to the initial
            // query. Note that we change the key of the UserDefinedTable based
            // on the query, so we have to take care to not pass initialGrouping
            // again.
            tableQuery == props.initialQuery ? props.initialGrouping : undefined
          }
          controller={props.controller}
          schema={props.schema}
        />
      )}

      <Dialog open={dialogOpen} onClose={onSaveDialogCanceled}>
        <DialogTitle>Table name</DialogTitle>
        <DialogContent>
          <TextField
            sx={{mt: 1, width: "100%"}}
            label="Table name"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTableName(event.target.value);
            }}
            value={tableName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            sx={{ml: 1, width: "250px"}}
            onClick={() => {
              void addTableToSpec();
            }}
          >
            Save table definition
          </Button>
          <Button
            variant={"contained"}
            sx={{ml: 1, width: "250px"}}
            onClick={onSaveDialogCanceled}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
