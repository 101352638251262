import {ApolloClient} from "@apollo/client";
import {
  confirmationDialogInfo,
  warningMessageForTableValidationFail,
} from "@components/tables/util.tsx";
import {Typography} from "@mui/material";
import toast from "react-hot-toast";
import React from "react";
import {DELETE_MODULE, VALIDATE_MODULE_DELETION} from "../gqlHelpers.ts";

export default async function DeleteModuleDialog(
  pkgPath: string,
  client: ApolloClient<unknown>,
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
): Promise<boolean> {
  const {data: validationQueryRes} = await client.query({
    query: VALIDATE_MODULE_DELETION,
    variables: {pkgPath},
    fetchPolicy: "no-cache",
  });
  const validationResult = validationQueryRes.validateModuleDeletion;
  const validationFailed =
    validationResult.newlyFailingTables.length > 0 ||
    validationResult.newlyFailingLinks.length > 0;
  const dialogContent = (
    <>
      Are you sure you want to delete module {pkgPath}?<br />
      {validationFailed && (
        <Typography>
          {warningMessageForTableValidationFail(
            "", // tableError
            validationResult.newlyFailingTables,
            validationResult.newlyFailingLinks,
          )}
        </Typography>
      )}
    </>
  );
  // If this change failed validation, show a confirmation dialog asking the
  // user to confirm that they indeed want to break this table or some other
  // tables that depend on it.
  const confirmed: boolean = await showConfirmationDialog({
    title: "Confirm module deletion",
    content: dialogContent,
  });
  if (!confirmed) {
    // User changed their mind.
    return false;
  }

  const {errors} = await client.mutate({
    mutation: DELETE_MODULE,
    variables: {pkgPath},
    errorPolicy: "all",
  });
  if (errors) {
    toast.error(errors[0].message);
    return false;
  }
  return true;
}
