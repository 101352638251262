import {FormControlLabel, Switch, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {VList} from "virtua";
import {
  GoroutinesByProcessSnapshot,
  GoroutinesGroup,
  Row,
  TableSchema,
} from "src/__generated__/graphql.ts";
import GoroutineIDList from "src/components/goroutine-ids.tsx";
import Stack from "./stack";
import {goroutineIDToString, resolvedGoroutineGroup} from "src/util/util";
import {schemaInfo} from "src/util/spec.ts";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";

type StacksProps = {
  snapshot: GoroutinesGroup[];
  tableSchemas: TableSchema[];
};

// Stacks renders a list of stack traces. Identical stack traces can be grouped
// together.
export default function Stacks(props: StacksProps): React.JSX.Element {
  const schema = new schemaInfo(props.tableSchemas);

  // onlyGoroutinesWithData defaults to true if there is any data.
  const [onlyGoroutinesWithData, setOnlyGoroutinesWithData] = useState(
    props.snapshot.some((gg: GoroutinesGroup) =>
      gg.Frames.some((f) => f.Data.length > 0),
    ),
  );
  const [grouped, setGrouped] = useState(true);
  // The order in which to display the frames in goroutines. Defaults to root to
  // leaf, which matches the flamegraph.
  const [rootToLeafOrder, setRootToLeafOrder] = useState(true);
  const [showOnlyFramesWithData, setShowOnlyFramesWithData] = useState(false);

  const processResolver = useProcessResolver();

  let aggregated: GoroutinesGroup[] = props.snapshot;
  if (onlyGoroutinesWithData) {
    // Modify the groups to keep only goroutines with data, and then filter out
    // all groups that are now empty.
    // There are 3 levels of filtering:
    // 1. We have groups of goroutines with similar stack traces, across
    // multiple processes (one group per process). "newGroup" below will be one
    // such group.
    // 2. Within each group of goroutines with similar stack traces, we have subgroups
    // of goroutines from the same process. "idGroup" below will be one such subgroup.
    // 3. Within the subgroups, we have individual goroutines. "gid" below will be one
    // goroutine.
    const newGroups = [] as GoroutinesGroup[];
    aggregated.forEach((group) => {
      // Make a deep copy of the group; we'll modify the group below.
      const newGroup: GoroutinesGroup = {
        ...group,
        Goroutines: group.Goroutines.map(
          (idGroup: GoroutinesByProcessSnapshot) => ({
            Goroutines: idGroup.Goroutines,
            ProcessSnapshotID: idGroup.ProcessSnapshotID,
          }),
        ),
      };

      newGroup.Goroutines = newGroup.Goroutines.filter(
        (processGoroutines: GoroutinesByProcessSnapshot) => {
          processGoroutines.Goroutines = processGoroutines.Goroutines.filter(
            (goroutine) =>
              // Does any frame have data for this goroutine?
              group.Frames.some((frame) => {
                if (frame.Data.length == 0) {
                  return false;
                }

                const tableSchema = schema.functionNameToTableSchema.get(
                  frame.FuncName.QualifiedName,
                );
                if (!tableSchema || !tableSchema.framesTable) {
                  throw new Error(
                    `schema missing for table ${frame.FuncName.QualifiedName}`,
                  );
                }
                return frame.Data.some(
                  (data: Row) =>
                    data.ColumnValues[
                      tableSchema.framesTable!.goroutineIDColumnIdx
                    ] ==
                    goroutineIDToString({
                      ProcessSnapshotID: processGoroutines.ProcessSnapshotID,
                      ID: goroutine.ID.ID,
                    }),
                );
              }),
          );
          return processGoroutines.Goroutines.length > 0;
        },
      );
      if (newGroup.Goroutines.length > 0) {
        newGroups.push(newGroup);
      }
    });
    aggregated = newGroups;
  }

  // Sort by group length, descending.
  aggregated = [...aggregated];
  aggregated.sort((a, b) => {
    if (a.Goroutines.length < b.Goroutines.length) return 1;
    if (a.Goroutines.length == b.Goroutines.length) return 0;
    return -1;
  });

  let groups = aggregated;
  if (!grouped) {
    groups = aggregated.flatMap((group: GoroutinesGroup): GoroutinesGroup[] => {
      // The goroutines are grouped by process; go through each process and then go through
      // each goroutine in that process.
      return group.Goroutines.flatMap(
        (processGroup: GoroutinesByProcessSnapshot) => {
          return processGroup.Goroutines.flatMap((goroutine) => {
            return {
              Goroutines: [
                {
                  ProcessSnapshotID: processGroup.ProcessSnapshotID,
                  Goroutines: [goroutine],
                },
              ],
              Frames: group.Frames.map((frame) => {
                if (frame.Data.length == 0) {
                  return {
                    ...frame,
                  };
                }

                const tableSchema = schema.functionNameToTableSchema.get(
                  frame.FuncName.QualifiedName,
                );
                if (!tableSchema || !tableSchema.framesTable) {
                  throw new Error(
                    `schema missing for table ${frame.FuncName.QualifiedName}`,
                  );
                }
                return {
                  ...frame,
                  // Keep only the data for the current goroutine.
                  Data: frame.Data.filter(
                    (data) =>
                      data.ColumnValues[
                        tableSchema.framesTable!.goroutineIDColumnIdx
                      ] ==
                      `${processGroup.ProcessSnapshotID}: ${goroutine.ID.ID}`,
                  ),
                };
              }),
            };
          });
        },
      );
    });
  }

  // resolveGoroutineIDs converts a list of goroutine IDs from different
  // processes (each referencing a process by its process snapshot id) to a list
  // of groups corresponding to each process, with the process id resolved to
  // process info.
  const resolveGoroutineIDs = function (
    group: GoroutinesGroup,
  ): resolvedGoroutineGroup[] {
    const goroutineGroups = [] as resolvedGoroutineGroup[];
    group.Goroutines.forEach((group) => {
      const proc = processResolver.resolveProcessSnapshotIDOrThrow(
        group.ProcessSnapshotID,
      );
      let myGroup = goroutineGroups.find(
        (g) => g.process.ProcessSnapshotID == proc.ProcessSnapshotID,
      );
      if (myGroup === undefined) {
        myGroup = {
          process: proc,
          goroutines: group.Goroutines,
        };
        goroutineGroups.push(myGroup);
      } else {
        myGroup.goroutines.push(...group.Goroutines);
      }
    });
    return goroutineGroups;
  };

  return (
    <div
      style={{
        flexGrow: 1,
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginBottom: "8px",
          display: "flex",
          gap: 8,
          flexWrap: "wrap",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={onlyGoroutinesWithData}
              onChange={(event) =>
                setOnlyGoroutinesWithData(event.target.checked)
              }
            />
          }
          label="Only goroutines with data"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showOnlyFramesWithData}
              onChange={(event) =>
                setShowOnlyFramesWithData(event.target.checked)
              }
            />
          }
          label="Show only stack frames with data"
        />
        <FormControlLabel
          control={
            <Switch
              checked={grouped}
              onChange={(event) => setGrouped(event.target.checked)}
            />
          }
          label="Group goroutines with similar stack traces"
        />
        <Tooltip
          title={
            <React.Fragment>
              <Typography>
                The order in which to list the stack frames in backtraces
              </Typography>
              Root to leaf matches the order in the flamegraph: caller, then
              callee.
            </React.Fragment>
          }
        >
          <FormControlLabel
            control={
              <Switch
                checked={rootToLeafOrder}
                onChange={(event) => setRootToLeafOrder(event.target.checked)}
              />
            }
            label="Order frames root to leaf"
          />
        </Tooltip>
      </div>

      {/* Render the list of stack traces in a VList instead of rendering all of them
         at once in order to speed up the page. */}
      <VList style={{}}>
        {groups.map((group, idx) => {
          return (
            <div key={idx}>
              <div>
                <GoroutineIDList goroutineGroups={resolveGoroutineIDs(group)} />
              </div>
              <Stack
                key={idx}
                frames={group.Frames}
                showOnlyFramesWithData={showOnlyFramesWithData}
                rootToLeafOrder={rootToLeafOrder}
                schema={schema}
              />
            </div>
          );
        })}
      </VList>
    </div>
  );
}
