import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiMenuItem = (): Pick<
  Components<typeof theme>,
  "MuiMenuItem"
> => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
});
