import {ProcessLabel} from "src/__generated__/graphql.ts";

export enum Label {
  Program = "program",
  CommandLine = "command_line",
  EnvironmentVariables = "environment_variables",
  ExecutablePath = "executable_path",
  ExecutableName = "executable_name",
  Hostname = "hostname",
  PID = "pid",
  Environment = "environment",
}

export const LabelExplanations: Map<Label, string> = new Map([
  [
    Label.Program,
    "The name of the program that the process belongs to, according to the program labeling rules.",
  ],
  [Label.CommandLine, "The command line that started the process."],
  [
    Label.EnvironmentVariables,
    "The environment variables that the process was started with.",
  ],
  [
    Label.ExecutablePath,
    "The path to the executable that the process is running.",
  ],
  [
    Label.ExecutableName,
    "The name of the executable that the process is running; this is the last component of the executable path.",
  ],
  [
    Label.Hostname,
    "The hostname of the machine that the process is running on.",
  ],
  [Label.PID, "The process ID of the process."],
  [
    Label.Environment,
    "The name of the environment reported by the agent on the machine the process is running in.",
  ],
]);

export function getLabelValue(
  label: Label,
  labelValues: ProcessLabel[],
): string | undefined {
  const labelValue = labelValues.find((l) => l.Label === label.valueOf());
  return labelValue?.Value ?? "";
}
