import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiTab = (): Pick<Components<typeof theme>, "MuiTab"> => ({
  MuiTab: {
    styleOverrides: {
      root: ({theme}) => ({
        borderRadius: "10px 10px 0 0",
        color: theme.palette.info.dark,

        "&.Mui-selected": {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.text.primary,
        },
      }),
    },
  },
});
