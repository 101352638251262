import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useSuspenseQuery} from "@apollo/client";
import {GET_PROGRAMS} from "../util/queries.tsx";

type Props = {
  state: string | undefined;
  setState: (state: string | undefined) => void;
};

export const SelectorProgram: React.FC<Props> = ({state, setState}) => {
  const {data: programs} = useSuspenseQuery(GET_PROGRAMS);

  return (
    <Autocomplete
      disablePortal
      options={programs.getPrograms}
      sx={{width: 300}}
      autoHighlight={true}
      renderInput={(params) => (
        <TextField color="secondary" {...params} placeholder="Program" />
      )}
      value={state ?? null}
      onChange={(_event, newValue) => {
        setState(newValue ?? undefined);
      }}
    />
  );
};
