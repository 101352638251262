import {gql} from "@graphql/gql.ts";

export const GET_LOG = gql(/* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      startTime
      durationMillis
      status
      events {
        id
        timestamp
        pid
        goroutineID
        message
        data
        funcDurationNs
      }
    }
  }
`);

export const GET_HISTOGRAM = gql(/* GraphQL */ `
  query getHistogram($id: ID!, $numBuckets: Int!) {
    getDurationHistogramForLog(id: $id, numBuckets: $numBuckets) {
      minNanos
      maxNanos
      counts
    }
  }
`);
