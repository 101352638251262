import React, {useContext} from "react";
import {
  Link,
  Stack,
  styled,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Tooltip,
  Typography,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import toast from "react-hot-toast";
import {CircleExclamationMark} from "src/util/icons.tsx";
import {
  AppConfigContext,
  setAuthHeaders,
} from "src/providers/app-config-provider.tsx";

export function CircleExclamationMarkTooltip({
  tip,
}: {
  tip: string;
}): React.JSX.Element {
  return (
    <Tooltip title={tip}>
      <span
        style={{
          marginLeft: "3px",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <CircleExclamationMark />
      </span>
    </Tooltip>
  );
}

export type AuthenticatedDownloadProps = {
  url: string;
  filename: string;
  children?: React.ReactNode;
};

// AuthenticatedDownloadLink is a wrapper around a link for downloading URLs that
// need an authentication token.
//
// Internally it uses the fetch API to download the file, creates an anchor element
// in the document, and triggers a click event on it to download the file.
export function AuthenticatedDownloadLink({
  url,
  filename,
  children,
}: AuthenticatedDownloadProps) {
  const appConfig = useContext(AppConfigContext);
  const download = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    const headers = appConfig.AuthEnabled
      ? await setAuthHeaders({}, appConfig.Auth)
      : {};
    const response = await fetch(url, {
      headers: headers,
    });
    if (response.status != 200) {
      const msg = await response.text();
      console.error(`Failed to download ${url}: ${msg}`);
      toast.error(`Failed to download ${url}: ${msg}`);
      return;
    }

    const blob = await response.blob();
    const blob_url = URL.createObjectURL(blob);
    // Create a new anchor element
    const a = document.createElement("a");

    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = blob_url;
    a.download = filename || "download";
    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(blob_url);
        removeEventListener("click", clickHandler);
      }, 1500);
    };

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener("click", clickHandler, false);

    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    // Comment out this line if you don't want an automatic download of the blob content
    a.click();
  };
  return <Link onClick={download}>{children}</Link>;
}

// isDefined is a type guard that can be passed to filter() to remove any
// undefined values.
export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

// WarningLabel renders a warning icon and a text message in the error color.
export function WarningLabel({text}: {text: string}): React.JSX.Element {
  return (
    <Stack direction={"row"} sx={{display: "inline-flex"}}>
      <WarningAmberIcon sx={{color: "error.main", ml: 1}} />
      <Typography display={"inline"} color={"error.main"}>
        {text}
      </Typography>
    </Stack>
  );
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));
