import React from "react";

export const IconEdit: React.FC<{color?: string}> = ({color}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25093 13.3519L12.1085 5.77696C12.4269 5.36847 12.5401 4.8962 12.4339 4.41533C12.342 3.97817 12.0731 3.56251 11.6699 3.24719L10.6866 2.46604C9.83057 1.78523 8.76941 1.85689 8.16102 2.63804L7.5031 3.49157C7.41821 3.59835 7.43943 3.75601 7.54554 3.84201C7.54554 3.84201 9.20802 5.17497 9.2434 5.20364C9.35659 5.31114 9.44148 5.45447 9.4627 5.62646C9.49807 5.96329 9.26462 6.27861 8.91797 6.32161C8.75526 6.34311 8.59963 6.29295 8.48644 6.19978L6.73906 4.80948C6.65417 4.7457 6.52683 4.75932 6.45609 4.84532L2.30343 10.2202C2.0346 10.557 1.94263 10.9942 2.0346 11.417L2.56518 13.7174C2.59348 13.8393 2.69959 13.9253 2.82693 13.9253L5.16148 13.8966C5.58594 13.8894 5.98211 13.6959 6.25093 13.3519ZM9.51979 12.6355H13.3265C13.6979 12.6355 14 12.9415 14 13.3178C14 13.6947 13.6979 14 13.3265 14H9.51979C9.14839 14 8.84631 13.6947 8.84631 13.3178C8.84631 12.9415 9.14839 12.6355 9.51979 12.6355Z"
        fill={color || "#47454A"}
      />
    </svg>
  );
};
