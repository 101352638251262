import {gql} from "../../__generated__";

export const GET_ORG_INFO = gql(/* GraphQL */ `
  query GetOrgInfo {
    getOrgInfo {
      apiToken
      orgName
      notes
      agentsOrgRedirect
    }
  }
`);

export const GET_USER_INFO = gql(/* GraphQL */ `
  query GetUserInfo {
    getUserInfo {
      email
      superuser
    }
  }
`);
