import {useParams} from "react-router-dom";
import {useSuspenseQuery} from "@apollo/client";
import React, {Fragment} from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {renderPossiblyJSONValue} from "src/components/cell.tsx";
import {GET_HISTOGRAM, GET_LOG} from "./gql";
import {EventCounter} from "@components/EventFrequencyChart/util.ts";
import EventFrequencyChart from "@components/EventFrequencyChart";
import {formatDurationNanos} from "@util/util.ts";
import DurationHistogramChart from "@components/DurationHistogramChart";

export default function EventLogViewer(): React.JSX.Element {
  const pathParams = useParams();
  const logID = parseInt(pathParams.logID!);

  const {data: traceRes} = useSuspenseQuery(GET_LOG, {
    variables: {id: logID},
  });
  const log = traceRes.getLog;
  const events = log.events!;

  const numBuckets = 30;
  const {data: histogramRes} = useSuspenseQuery(GET_HISTOGRAM, {
    variables: {id: logID, numBuckets},
  });

  const [graphXAxisData, graphSeriesData] = new EventCounter(
    events,
  ).getEventsPerSecond();

  const hist = histogramRes.getDurationHistogramForLog;

  return (
    <div>
      <Box sx={{width: "100%", height: "400px"}}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{width: "100%", height: "400px"}}
        >
          <Box sx={{width: "50%", height: "100%"}}>
            <EventFrequencyChart
              countPerSecond={graphSeriesData}
              xAxisData={graphXAxisData}
            />
          </Box>
          <Box sx={{width: "50%", height: "100%"}}>
            <DurationHistogramChart histogram={hist} />
          </Box>
        </Stack>
      </Box>

      <Table
        style={{
          marginTop: "10px",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{width: "100px"}}>PID</TableCell>
            <TableCell style={{width: "100px"}}>GoroutineID</TableCell>
            <TableCell style={{width: "300px"}}>Timestamp</TableCell>
            <TableCell style={{width: "300px"}}>Duration</TableCell>
            <TableCell style={{width: "100%"}}>Message</TableCell>
            <TableCell style={{width: "100%"}}>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <Fragment key={`${event.id}-header`}>
              <TableRow>
                <TableCell>{event.pid}</TableCell>
                <TableCell>{event.goroutineID}</TableCell>
                <TableCell>{event.timestamp}</TableCell>
                <TableCell>
                  {event.funcDurationNs
                    ? formatDurationNanos(event.funcDurationNs)
                    : ""}
                </TableCell>
                <TableCell>{event.message}</TableCell>
                <TableCell>
                  {renderPossiblyJSONValue(
                    event.data,
                    undefined /* onExpand */,
                    0 /* defaultExpandedLevels */,
                  )}
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
