import {Box, Button, Theme, Tooltip} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {useSnapshotState} from "src/providers/snapshot-state";
import {SxProps} from "@mui/material/styles";
import {Goroutine, GoroutineStatus} from "src/__generated__/graphql";
import {formatDurationNanos} from "src/util/util.ts";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";

type GoroutineLinkProps = {
  processSnapshotID: number;
  goroutineID: number;
  // If true, when clicking the goroutine ID, all other filters are retained.
  retainFilters?: boolean;
  // If set, the process name is rendered as a separate link that filters only
  // on the process (not also on the goroutine ID).
  separateProcessLink?: boolean;
  // If set, more information about the goroutine that can be displayed in
  // the tooltip.
  goroutine?: Goroutine;

  sx?: SxProps<Theme>;

  // color, if set, overrides the default color of the link(s). This is a
  // separate property than sx because sx applies to a parent element to the
  // links, and the links don't inherit the color.
  //
  // TODO: this currently only works properly if separateProcessLink is not set;
  // the separate process link does not use this color.
  color?: string;
};

// GoroutineLink renders a link of the form <process name>:<goroutine id>.
// Clicking on the process name alters the filters to focus on the respective
// process. Clicking on the goroutine ID further alters the filters to focus
// only on the respective goroutine.
export default function GoroutineLink(
  props: GoroutineLinkProps,
): React.JSX.Element {
  const {processSnapshotID, goroutineID, retainFilters = false} = props;
  const processResolver = useProcessResolver();
  const snapshotState = useSnapshotState();

  const processInfo =
    processResolver.resolveProcessSnapshotIDOrThrow(processSnapshotID);
  if (processInfo === undefined) {
    throw new Error(`could not resolve snapshot ID ${props.processSnapshotID}`);
  }

  return (
    <Box display={"inline"} sx={{...props.sx}}>
      {props.separateProcessLink && (
        <>
          <ProcessLink processSnapshotID={props.processSnapshotID} />:
        </>
      )}
      <Tooltip
        title={
          <GoroutineTooltip
            goroutine={props.goroutine}
            processSnapshotID={props.processSnapshotID}
            linkColor={"orange"} // Change the links default color, so it looks good on the tooltip's dark background.
          />
        }
      >
        <span>
          <Button
            sx={{
              minWidth: 0,
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0,
              color: props.color,
            }}
            size={"small"}
            variant={"text"}
            component={Link}
            to={snapshotState.goroutineURL(
              processInfo,
              goroutineID,
              retainFilters,
            )}
          >
            {!props.separateProcessLink && processInfo.FriendlyName + ":"}
            {props.goroutineID}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}

function GoroutineTooltip(props: {
  goroutine?: Goroutine;
  processSnapshotID?: number;
  linkColor?: string;
}): React.JSX.Element {
  if (!props.goroutine) {
    if (props.processSnapshotID == undefined) {
      throw new Error("either goroutine or processSnapshotID must be set");
    }
    return (
      <ProcessLink
        processSnapshotID={props.processSnapshotID}
        color={props.linkColor}
      />
    );
  }

  const goroutine = props.goroutine;
  return (
    <>
      Goroutine status: {goroutine.Status}
      {goroutine.Status == GoroutineStatus.Waiting && (
        <>
          <br />
          Has been waiting for:{" "}
          {goroutine.WaitForNanos ? (
            formatDurationNanos(goroutine.WaitForNanos)
          ) : (
            <>
              unknown
              <HelpCircle
                small={true}
                tip={`Short wait times are not always known. The Go runtime only
                populates this information for a goroutine during a
                garbage-collection cycle. This goroutine was not waiting at the
                time of the last GC run.`}
              />
            </>
          )}
          <Box sx={{mt: 1}}>
            <ProcessLink
              processSnapshotID={goroutine.ID.ProcessSnapshotID}
              color={props.linkColor}
            />
          </Box>
        </>
      )}
    </>
  );
}

type ProcessLinkProps = {
  processSnapshotID: number;
  color?: string;
};

// ProcessLink renders a link rendered as <process name>. Clicking on the
// process name alters the filters to focus on the respective process.
export function ProcessLink(props: ProcessLinkProps) {
  const processResolver = useProcessResolver();
  const snapshotState = useSnapshotState();

  const processInfo = processResolver.resolveProcessSnapshotIDOrThrow(
    props.processSnapshotID,
  );
  if (processInfo === undefined) {
    throw new Error(`could not resolve snapshot ID ${props.processSnapshotID}`);
  }

  return (
    <Button
      sx={{
        minWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: props.color,
      }}
      size={"small"}
      variant={"text"}
      component={Link}
      to={snapshotState.setProcessURL(processInfo)}
    >
      Filter to process {processInfo.FriendlyName}
    </Button>
  );
}
