import IconButton from "@mui/material/IconButton";
import React from "react";
import {Link, Tooltip} from "@mui/material";
import IconPaper from "./icons/IconPaper.tsx";

export const ButtonDocs: React.FC = () => {
  return (
    <Link href="https://docs.side-eye.io" target="_blank">
      <Tooltip title="Documentation">
        <IconButton aria-label="documentation">
          <IconPaper />
        </IconButton>
      </Tooltip>
    </Link>
  );
};
