import {ModuleSpec} from "@graphql/graphql.ts";
import {Stack, Typography} from "@mui/material";
import React from "react";

type Props = {
  module: ModuleSpec;
};

export default function ModuleName({module}: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={2} flexGrow={1}>
      <Typography variant="h2" color="primary">
        {module.name}
      </Typography>
      {/* If the module name is different from the package path, also
                show the package path. */}
      {module.name != module.pkgPath && (
        <Typography variant="body2" color="primary.light" noWrap>
          {module.pkgPath}
        </Typography>
      )}
    </Stack>
  );
}
