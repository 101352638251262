import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import {confirmationDialogInfo} from "src/components/tables/util.tsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const ConfirmationDialogContext = createContext<
  (_: confirmationDialogInfo) => Promise<boolean>
>(undefined as never);

export function useConfirmationDialog() {
  const showDialog = React.useContext(ConfirmationDialogContext);
  if (showDialog === undefined) {
    throw new Error(
      "useConfirmationDialog must be used within a ConfirmationDialogProvider",
    );
  }
  return showDialog;
}

export const ConfirmationDialogProvider: React.FunctionComponent<
  PropsWithChildren
> = ({children}) => {
  const [confirmEditDialogState, setConfirmEditDialogState] = useState<
    tableEditConfirmDialogProps | undefined
  >(undefined);

  // showConfirmationDialog shows a confirmation dialog. It returns a Promise
  // that will be resolved when the user either confirms or aborts the
  // operation.
  //
  // We update the state so that the dialog is shown and bind the result of the
  // dialog to the returned Promise.
  function showConfirmationDialog(
    info: confirmationDialogInfo,
  ): Promise<boolean> {
    return new Promise<boolean>((accept, _reject) => {
      setConfirmEditDialogState({
        title: info.title,
        content: info.content,
        onConfirm: () => {
          accept(true);
          setConfirmEditDialogState(undefined);
        },
        onCancel: () => {
          accept(false);
          setConfirmEditDialogState(undefined);
        },
      });
    });
  }

  return (
    <>
      <ConfirmationDialogContext.Provider value={showConfirmationDialog}>
        {children}
      </ConfirmationDialogContext.Provider>
      {confirmEditDialogState && (
        <ConfirmTableEditDialog {...confirmEditDialogState} />
      )}
    </>
  );
};

export type tableEditConfirmDialogProps = {
  title: string;
  content: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

function ConfirmTableEditDialog(
  props: tableEditConfirmDialogProps,
): React.JSX.Element {
  return (
    <Dialog open={true} onClose={() => props.onCancel()}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          sx={{ml: 1, width: "250px"}}
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          sx={{ml: 1, width: "250px"}}
          color={"error"}
          onClick={() => props.onConfirm()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
