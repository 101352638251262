import {ProcessHierarchy} from "./processHierarchy.class.ts";
import {EnvironmentSelectionState} from "./environmentSelectionState.class.ts";
import {defaultSelection} from "./defaultSelection.function.ts";

// Synchronize the selection status with the report. The new selection is
// returned. The selection status is not modified in place; if any changes are
// made, a new instance is returned. If no changes are made, the input is
// returned. Returns undefined if the input cannot be reconciled with the
// report, and the report does not have a "default selection".
export function syncSelectionWithReport(
  report: ProcessHierarchy | undefined,
  selectionStatus: EnvironmentSelectionState | undefined,
): EnvironmentSelectionState | undefined {
  if (report == undefined) {
    return undefined;
  }

  // If the selection status is not initialized, see if we can initialize it
  // from the report.
  if (selectionStatus == undefined) {
    return defaultSelection(report);
  }

  // If the environment that we had selected has disappeared, then we reset the
  // selection.
  const env: string | undefined = selectionStatus.environment;
  if (!report.environments.has(env)) {
    return defaultSelection(report);
  }

  const reportEnv = report.environments.get(env)!;

  // clone speculatively. If no changes are made, we won't return this clone.
  const clone = selectionStatus.clone();

  // Add any new programs to the selection.
  let anyProgramsAdded = false;
  for (const program of reportEnv.programs.keys()) {
    if (!clone.programsSelection.has(program)) {
      clone.programsSelection.set(program, {type: "all"});
      anyProgramsAdded = true;
    }
  }

  // Remove any stale processes from the selection.
  let anyProcessesRemoved = false;
  for (const [program, procs] of clone.programsSelection) {
    if (!reportEnv.programs.has(program)) {
      clone.programsSelection.delete(program);
      anyProcessesRemoved = true;
      continue;
    }
    if (procs.type == "some") {
      const staleProcs = procs.selectedProcs.filter(
        (p) => !reportEnv.hasProcess(p),
      );
      if (staleProcs.length > 0) {
        procs.selectedProcs = procs.selectedProcs.filter(
          (p) => !staleProcs.some((s) => s.processToken == p.processToken),
        );
        anyProcessesRemoved = true;
      }
    }
  }

  if (!anyProgramsAdded && !anyProgramsAdded && !anyProcessesRemoved) {
    return selectionStatus;
  }
  return clone;
}
