import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const MuiSelect = (): Pick<Components<typeof theme>, "MuiSelect"> => ({
  MuiSelect: {
    defaultProps: {
      IconComponent: KeyboardArrowDownIcon,
    },
  },
});
