// The GetResult type argument allows the getter function to return something
// different from (T | undefined). In particular, it allows the getter to never
// return undefined.
interface ParamUpdaterInterface<
  T,
  GetResult extends T | undefined = T | undefined,
> {
  // param is the name of the URL parameter controlled by this updater, as it
  // appears in the URL.
  readonly param: string;
  get(params: URLSearchParams): GetResult;
  update(params: URLSearchParams, value: T): void;
}

export type ParamUpdaters = Record<string, ParamUpdaterInterface<unknown>>;

// The type of the argument to computeSearchParams -- a specification about
// which part of the URL to update. The properties are derived from a
// ParamUpdaters object: the keys are the ParamUpdaters keys, and the types of
// the values are the type of the second argument to the update function of the
// ParamUpdater (i.e. the values that the respective updater accepts). Note that
// the type of each property value can be different. All the keys of this type
// are optional; see computeSearchParams for the semantics.
export type UpdateSpec<T extends ParamUpdaters> = {
  [Property in keyof T as Property]?: Parameters<T[Property]["update"]>[1];
};

// computeSearchParams takes a base URLSearchParams, an object with the
// properties that need to be updated, a paramUpdaters that controls how the
// respective properties are actually updated and reflected in the new URL, and
// returns an updated URLSearchParams object that represents the updated state.
// Properties in `args` that have the value "undefined" (but properties that
// exist, as opposed to missing keys) are removed from the URL. Properties which
// are not present are left alone.
export function computeSearchParams<T extends ParamUpdaters>(
  urlSearchParams: URLSearchParams,
  args: UpdateSpec<T>,
  paramUpdaters: T,
): URLSearchParams {
  const searchParams = new URLSearchParams(urlSearchParams);
  for (const param in paramUpdaters) {
    const updater = paramUpdaters[param];
    if (Object.hasOwn(args, param)) {
      const paramValue = args[param];
      if (paramValue === undefined) {
        searchParams.delete(updater.param);
      } else {
        updater.update(searchParams, paramValue);
      }
    }
  }
  return searchParams;
}

// searchParamsURL calls computeSearchParams and returns the resulting URL as a
// string.
export function searchParamsURL<T extends ParamUpdaters>(
  params: URLSearchParams,
  args: UpdateSpec<T>,
  paramUpdaters: T,
): string {
  return `?${computeSearchParams(params, args, paramUpdaters).toString()}`;
}

// URLState is a type with properties corresponding to the keys of a
// ParamUpdaters. The types of the values are the return types of the getters.
export type URLState<T extends ParamUpdaters> = {
  [Property in keyof T]: ReturnType<T[Property]["get"]>;
};

// stateFromURL takes a URLSearchParams object and a ParamUpdaters object and
// returns an object with the properties known to the ParamUpdaters parsed out
// of the URL.
export function stateFromURL<T extends ParamUpdaters>(
  params: URLSearchParams,
  paramUpdaters: T,
): URLState<T> {
  const properties = Object.fromEntries(
    Object.entries(paramUpdaters).map(([param, updater]) => [
      param,
      updater.get(params),
    ]),
  );
  return properties as URLState<T>;
}

// ParamUpdater is a class that encapsulates the logic for reading and writing a
// URL parameter. It is more general than the EnumParamUpdater.
//
// The GetResult type argument allows the getter function to return something
// different from (T | undefined). In particular, it allows the getter to never
// return undefined.
export class ParamUpdater<T, GetResult extends T | undefined = T | undefined> {
  readonly param: string;
  private readonly updater: (
    paramName: string,
    searchParams: URLSearchParams,
    newValue: T,
  ) => void;
  private readonly getter: (
    paramName: string,
    searchParams: URLSearchParams,
  ) => GetResult;

  constructor(
    param: string,
    get: (param: string, searchParams: URLSearchParams) => GetResult,
    update: (param: string, searchParams: URLSearchParams, newValue: T) => void,
  ) {
    this.param = param;
    this.getter = get;
    this.updater = update;
    this.update.bind(this);
    this.get.bind(this);
  }

  get(params: URLSearchParams): GetResult {
    return this.getter(this.param, params);
  }

  update(params: URLSearchParams, newValue: T): void {
    this.updater(this.param, params, newValue);
  }
}

// JSONParamUpdater is a ParamUpdater that serializes and deserializes instances
// of type T as JSON.
export class JSONParamUpdater<T> {
  readonly param: string;

  constructor(param: string) {
    this.param = param;
    this.update.bind(this);
    this.get.bind(this);
  }

  get(params: URLSearchParams): T | undefined {
    const valStr = params.get(this.param);
    if (!valStr) {
      return undefined;
    }
    return JSON.parse(valStr) as T;
  }

  update(params: URLSearchParams, newValue: T): void {
    params.set(this.param, JSON.stringify(newValue));
  }
}

// Define a generic type with constraints to a set of string literals.
export class EnumParamUpdater<TEnum extends string, TKeys extends string> {
  readonly param: string;
  readonly defaultValue: TEnum;
  readonly values: ReadonlySet<TEnum>;

  constructor(
    param: string,
    defaultValue: TEnum,
    enumObject: {[key in TKeys]: TEnum},
  ) {
    this.param = param;
    this.defaultValue = defaultValue;
    const values = new Set<TEnum>();
    for (const key in enumObject) {
      const value = enumObject[key];
      if (typeof value != typeof defaultValue) {
        continue;
      }
      values.add(value);
    }
    this.values = values;
  }

  // Update the URLSearchParams object with the new value if it is valid.
  update = (params: URLSearchParams, newValue: TEnum): URLSearchParams => {
    if (this.values.has(newValue)) {
      params.set(this.param, newValue);
    } else {
      console.error(`invalid value for ${this.param}: ${newValue}`);
    }
    return params;
  };

  get = (params: URLSearchParams): TEnum => {
    const stringValue = params.get(this.param);
    if (!stringValue) {
      return this.defaultValue;
    }
    if (!this.values.has(stringValue as TEnum)) {
      return this.defaultValue;
    }
    return stringValue as TEnum;
  };
}

// StringParamUpdater is a ParamUpdater that serializes and deserializes
// strings.
export class StringParamUpdater {
  readonly param: string;

  constructor(param: string) {
    this.param = param;
    this.update.bind(this);
    this.get.bind(this);
  }

  get(params: URLSearchParams): string | undefined {
    const valStr: string | null = params.get(this.param);
    return valStr ?? undefined;
  }

  update(params: URLSearchParams, newValue: string): void {
    params.set(this.param, newValue);
  }
}
