import {createTheme} from "@mui/material";
import {MuiSwitch} from "./MuiSwitch.ts";
import {MuiMenuItem} from "./MuiMenuItem.ts";
import {MuiTable} from "./MuiTable.ts";
import {MuiSelect} from "./MuiSelect.ts";
import {MuiInputBase} from "./MuiInputBase.ts";
import {MuiButton} from "./MuiButton.ts";
import {MuiToolbar} from "./MuiToolbar.ts";
import {MuiDrawer} from "./MuiDrawer.ts";
import {MuiLink} from "./MuiLink.ts";
import {MuiCardContent} from "./MuiCardContent.ts";
import {MuiCard} from "./MuiCard.ts";
import {MuiDivider} from "./MuiDivider.ts";
import {MuiPaper} from "./MuiPaper.ts";
import {MuiAutocomplete} from "./MuiAutocomplete.tsx";
import {MuiTextField} from "./MuiTextField.ts";
import {MuiIconButton} from "./MuiIconButton.ts";
import {MuiCheckbox} from "./MuiCheckbox.tsx";
import {MuiListItem} from "./MuiListItem.ts";
import {MuiList} from "./MuiList.ts";
import {MuiBreadcrumbs} from "./MuiBreadcrumbs.ts";
import {MuiTab} from "./MuiTab.ts";
import {MuiFormControlLabel} from "./MuiFormControlLabel.ts";
import {MuiTableContainer} from "./MuiTableContainer.ts";
import {MuiCssBaseline} from "./MuiCssBaseline.ts";
import {MuiCardHeader} from "./MuiCardHeader.ts";
import {MuiTypography} from "./MuiTypography.ts";
import {MuiAccordion} from "./MuiAccordion.tsx";
import {MuiChip} from "./MuiChip.ts";
import {MuiTooltip} from "./MuiTooltip.ts";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8453FF",
      light: "#D4C2FF",
    },
    secondary: {
      main: "#9D9C9F",
      dark: "#18161D",
    },
    info: {
      main: "#47454A",
      dark: "#666468",
    },
    text: {
      primary: "#ffffff",
      secondary: "#9D9C9F",
    },
    background: {
      default: "#131118",
      paper: "#1F1D24",
      drawer: "#09070E",
      gradient: "linear-gradient(180deg, #28194D 0%, #1A1131 100%)",
    },
  },
  typography: ({text, error}) => ({
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontSize: 20,
      fontWeight: "400",
    },
    body2: {
      fontSize: 16,
      lineHeight: "24px",
    },
    body3: {
      fontSize: 14,
      lineHeight: "20px",
    },
    body4: {
      fontSize: 12,
      fontWeight: 500,
    },
    caption: {
      color: text.secondary,
    },
    warning: {
      color: "orange",
      fontSize: 14,
    },
    error: {
      color: error.main,
      fontSize: 14,
    },
  }),
  components: {
    ...MuiPaper(),
    ...MuiDivider(),
    ...MuiCard(),
    ...MuiCardContent(),
    ...MuiLink(),
    ...MuiDrawer(),
    ...MuiToolbar(),
    ...MuiButton(),
    ...MuiInputBase(),
    ...MuiSelect(),
    ...MuiTable(),
    ...MuiMenuItem(),
    ...MuiSwitch(),
    ...MuiAutocomplete(),
    ...MuiTextField(),
    ...MuiIconButton(),
    ...MuiCheckbox(),
    ...MuiList(),
    ...MuiListItem(),
    ...MuiBreadcrumbs(),
    ...MuiTab(),
    ...MuiFormControlLabel(),
    ...MuiTableContainer(),
    ...MuiCssBaseline(),
    ...MuiCardHeader(),
    ...MuiTypography(),
    ...MuiAccordion(),
    ...MuiChip(),
    ...MuiTooltip(),
  },
});
