import React, {useContext} from "react";
import ReactGoogleButton from "react-google-button";
import {useNavigate} from "react-router-dom";
import {Auth} from "firebase/auth";
import {signInWithGooglePopup} from "../helpers.ts";
import {AppConfigContext} from "../../../providers/app-config-provider.tsx";
import {styled} from "@mui/material";

const StyledReactGoogleButton = styled(ReactGoogleButton)(({theme}) => ({
  backgroundColor: theme.palette.background.default + "!important",
  width: `100% !important`,
  height: `64px !important`,
  padding: `16px 24px 16px 24px !important`,
  boxShadow: ` none !important`,
  borderRadius: `16px !important`,
  display: `flex !important`,
  justifyContent: `center`,
  alignItems: ` center`,

  [`& > div`]: {
    backgroundColor: "transparent !important",
  },

  [`& svg`]: {
    scale: "1.5",
  },
}));

const handleGoogleButtonClick = async (
  auth: Auth | undefined,
): Promise<void> => {
  if (auth) {
    try {
      await signInWithGooglePopup(auth);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  } else {
    console.error("Auth is not available");
  }
};

export const GoogleButton: React.FC = () => {
  const appCfg = useContext(AppConfigContext);
  const navigate = useNavigate();

  const handleClick = (): void => {
    void (async () => {
      await handleGoogleButtonClick(appCfg.Auth);
      navigate("/");
    })();
  };

  return <StyledReactGoogleButton onClick={handleClick} />;
};
