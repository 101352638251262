import {ProcessHierarchy} from "./processHierarchy.class.ts";
import {EnvironmentSelectionState} from "./environmentSelectionState.class.ts";
import {ProgramSelectionStatus} from "../@types.ts";

export function defaultSelectionForEnvironment(
  report: ProcessHierarchy,
  envName: string | undefined,
): EnvironmentSelectionState {
  const env = report.environments.get(envName);
  if (env === undefined) {
    throw new Error(`environment missing: ${envName}`);
  }
  // All programs in the report start off as selected.
  const programsSelection = new Map<string, ProgramSelectionStatus>();
  env.programs.forEach((_, program) => {
    programsSelection.set(program, {type: "all"});
  });
  return new EnvironmentSelectionState(envName, programsSelection);
}
