import {
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";

export function EventFrequencyChart({
  countPerSecond,
  xAxisData,
}: {
  countPerSecond: number[];
  xAxisData: number[];
}): React.JSX.Element {
  return (
    <ResponsiveChartContainer
      series={[
        {
          type: "line",
          data: countPerSecond,
          label: "Events per second",
          showMark: true,
        },
      ]}
      xAxis={[
        {
          scaleType: "linear",
          data: xAxisData,
          id: "x-axis-id",
          tickSize: 6,
          // Don't add fractional ticks.
          tickMinStep: 1,
        },
      ]}
      yAxis={[{scaleType: "linear"}]}
    >
      {/*Show dots for each point in the series.*/}
      <MarkPlot />
      {/*Render the line connecting the dots.*/}
      <LinePlot />
      <ChartsXAxis
        label="Time (seconds since log start)"
        position="bottom"
        axisId="x-axis-id"
      />
      <ChartsYAxis label="Events per second" position="left" />

      {/*Add this for the series' labels to show up.*/}
      {/*<ChartsLegend />*/}

      {/*<ChartsOverlay />*/}

      {/*Highlight the point matching the mouse cursor.*/}
      <LineHighlightPlot />
      <ChartsTooltip
        // Show the tooltip when hovering over a point, not anywhere on the graph.
        trigger={"item"}
      />
    </ResponsiveChartContainer>
  );
}
