import {
  Box,
  Button,
  Card,
  Checkbox,
  Drawer,
  Tooltip,
  Typography,
} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import React, {Suspense} from "react";
import useResize from "src/util/use-resize.tsx";
import {
  FunctionSpec,
  ParseFunctionQualifiedNameQuery,
  QueryParseFunctionQualifiedNameArgs,
  Type_Kind,
  TypeInfo,
  TypeSpec,
} from "src/__generated__/graphql.ts";
import {
  ADD_OR_UPDATE_TYPE_SPEC,
  StructSpecEditorInner,
  TYPE_INFO,
} from "src/components/available-vars.tsx";
import {FunctionSpecCard} from "src/pages/Spec/components/FunctionSpecCard.tsx";
import {
  SkipToken,
  skipToken,
  SuspenseQueryHookOptions,
  useApolloClient,
  useSuspenseQuery,
} from "@apollo/client";
import {ProgramCounter} from "src/util/types.ts";
import {DELETE_TYPE_SPEC, deleteFunctionSpec} from "src/util/queries.tsx";
import {useConfirmationDialog} from "src/providers/confirmation-dialog.tsx";
import {gql} from "../__generated__";

const PARSE_FUNC_NAME = gql(/* GraphQL */ `
  query ParseFunctionQualifiedName($funcQualifiedName: String!) {
    parseFunctionQualifiedName(funcQualifiedName: $funcQualifiedName) {
      Package
      Type
      Name
      QualifiedName
    }
  }
`);

export type FunctionSpecSkeleton = {
  funcQualifiedName: string;
};

// FunctionOrSpecDrawer renders a drawer that opens from the right side with the
// spec/available variables of a single function or type. It wraps
// SpecCardFunction.tsx (if editing a function spec) or TypeSpecCard (if editing a
// type spec).
export default function FunctionOrTypeSpecDrawer(props: {
  // display is the spec to display.
  //
  // Note that the spec for the function or type does not need to actually exist
  // in the database. If we're passed a spec that doesn't exist (probably an
  // empty one), it will be created if the user modifies it in any way.
  display: FunctionSpec | FunctionSpecSkeleton | TypeSpec;

  // showDeleteButton controls whether the delete button is rendered. This
  // should be false when `display` refers to a function or type whose spec does
  // not exist in the database.
  showDeleteButton: boolean;

  // The binary to be used for listing the available variables or struct fields.
  binaryID: string;

  // If display is a function spec, a program counter can be set. It will be
  // used to display variables that are available at this particular code
  // location.
  pc?: ProgramCounter;

  onClose: () => void;
}): React.JSX.Element {
  const {width: varsDrawerWidth, enableResize} = useResize({minWidth: 800});
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();

  let queryArgs:
    | SuspenseQueryHookOptions<
        ParseFunctionQualifiedNameQuery,
        QueryParseFunctionQualifiedNameArgs
      >
    | SkipToken = skipToken;
  const spec = props.display;
  if (isFunctionSpecSkeleton(spec)) {
    queryArgs = {
      variables: {
        funcQualifiedName: spec.funcQualifiedName,
      },
    };
  }
  const {data: funcNameRes} = useSuspenseQuery(PARSE_FUNC_NAME, queryArgs);
  let funcSpec: FunctionSpec | undefined = undefined;
  if (isFunctionSpec(props.display)) {
    funcSpec = props.display;
  } else if (isFunctionSpecSkeleton(props.display)) {
    funcSpec = {
      funcName: funcNameRes!.parseFunctionQualifiedName,
      snapshotSpec: undefined,
      functionStartEvent: undefined,
    };
  }

  async function onDeleteClick() {
    const spec = props.display;
    let ok = true;
    if (isFunctionSpec(spec)) {
      ok = await deleteFunctionSpec(spec, client, showConfirmationDialog);
    } else if (isTypeSpec(spec)) {
      await client.mutate({
        mutation: DELETE_TYPE_SPEC,
        variables: {
          typeQualifiedName: spec.typeQualifiedName,
        },
      });
    }
    if (ok) {
      props.onClose();
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={!!props.display}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          width: varsDrawerWidth,
          paddingLeft: 1,
        },
      }}
      onClick={(event) => event.stopPropagation()}
    >
      {props.display && (
        <>
          <div
            style={{
              position: "absolute",
              width: "4px",
              top: "0",
              left: "0",
              bottom: "0",
              cursor: "col-resize",
            }}
            onMouseDown={enableResize}
          />

          {funcSpec && (
            <span>
              <h4>
                <Typography variant={"mutedNormalSize"} sx={{mr: 1}}>
                  Function:
                </Typography>
                <span
                  style={{fontFamily: "monospace", overflowWrap: "break-word"}}
                >
                  {funcSpec.funcName.QualifiedName}
                </span>
              </h4>
            </span>
          )}
          {isTypeSpec(props.display) && (
            <span>
              <h4>
                Type:&nbsp;
                <span
                  style={{fontFamily: "monospace", overflowWrap: "break-word"}}
                >
                  {props.display.typeQualifiedName}
                </span>
              </h4>
            </span>
          )}

          <ErrorBoundary
            fallbackRender={({error}) => <div>Failed: {error.message}</div>}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {funcSpec ? (
                <>
                  {props.showDeleteButton && (
                    <Tooltip
                      title={
                        <>
                          <Typography>Delete function spec</Typography>
                          Remove this function from the set of functions for
                          which data is collected in a snapshot when the
                          function is encountered on a goroutine's stack trace.
                        </>
                      }
                    >
                      <Button
                        sx={{width: "fit-content"}}
                        onClick={onDeleteClick}
                        className={"dense"}
                      >
                        Delete function spec
                      </Button>
                    </Tooltip>
                  )}
                  <FunctionSpecCard
                    functionSpec={funcSpec}
                    showHeader={false}
                    binaryID={props.binaryID}
                    pc={props.pc}
                    // We must be in the context of a snapshot, so let's start
                    // with the function's snapshot spec expanded.
                    defaultExpanded={"snapshot"}
                  />
                </>
              ) : (
                isTypeSpec(props.display) && (
                  <>
                    {props.showDeleteButton && (
                      <Tooltip
                        title={
                          <>
                            <Typography>Delete type spec</Typography>
                            Deleting this type spec will cause fields of the
                            respective type to be collected according to the
                            default rules. In particular, if this type is
                            wrapped in an interface, it will not be collected at
                            all.
                          </>
                        }
                      >
                        <Button
                          sx={{width: "fit-content"}}
                          onClick={onDeleteClick}
                          className={"dense"}
                        >
                          Delete type spec
                        </Button>
                      </Tooltip>
                    )}
                    <TypeSpecCard
                      spec={props.display}
                      binaryID={props.binaryID!}
                    />
                  </>
                )
              )}
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </Drawer>
  );
}

type TypeSpecCardProps = {
  // spec is the current type spec.
  spec: TypeSpec;
  // binaryID is the binary to be used for resolving the type.
  binaryID: string;
};

// TypeSpecCard renders a type editor. For structs, an actual editor showing the
// struct's fields and sub-fields is rendered. For any other type, we simply
// render a message.
function TypeSpecCard(props: TypeSpecCardProps): React.JSX.Element {
  const {data: types} = useSuspenseQuery(TYPE_INFO, {
    variables: {
      binaryID: props.binaryID,
      typeName: props.spec.typeQualifiedName,
    },
  });

  const typ = types.typeInfo[0];

  return (
    <Card>
      {typ.Kind == Type_Kind.Struct ? (
        <StructSpecEditor
          spec={props.spec}
          binaryID={props.binaryID}
          types={types.typeInfo}
        />
      ) : (
        <ScalarTypeSpecInfo kind={typ.Kind} />
      )}
    </Card>
  );
}

type StructSpecEditorProps = {
  spec: TypeSpec;
  binaryID: string;
  // types is a preloaded list of types. It must include the struct type we're
  // editing.
  types: TypeInfo[];
};

// StructSpecEditor renders the type spec editor for a struct type. It displays
// a "collect all" checkbox and otherwise delegates to StructSpecEditorInner for
// selecting individual fields.
function StructSpecEditor(props: StructSpecEditorProps): React.JSX.Element {
  const client = useApolloClient();

  async function toggleCollectAll(collectAll: boolean) {
    const {errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_TYPE_SPEC,
      variables: {
        input: {
          ...props.spec,
          collectAll: collectAll,
          collectExprs: props.spec.collectExprs,
        },
      },
    });
    if (errors) {
      console.error("failed to update type spec", errors);
    }
  }

  const typ = props.types.find((t) => t.Name == props.spec.typeQualifiedName);
  if (!typ) {
    throw new Error("type not found: " + props.spec.typeQualifiedName);
  }
  if (typ.Kind != Type_Kind.Struct) {
    throw new Error("expected struct type, got " + typ.Kind);
  }
  // We show a warning if no fields are being collected.
  const showWarning =
    typ.Fields!.length > 0 && // If the struct has no type, there's no warning.
    !props.spec.collectAll &&
    (!props.spec.collectExprs || props.spec.collectExprs.length == 0);

  return (
    <>
      {showWarning && (
        <Box
          sx={{
            mb: 1,
            mr: 2,
            p: 1,
            boxShadow: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant="warning">
            No fields are being collected. This might be want you want, but it's
            not common. If you want any data to be collected, either check
            "Collect all" or select specific fields. Alternatively, you can
            delete this type spec if you want the default behavior — everything
            to be collected when an object of this type is reached through a
            pointer, and nothing to be collected when an object of this type is
            wrapped in an interface.
          </Typography>
        </Box>
      )}
      <Checkbox
        checked={props.spec.collectAll}
        onChange={(e) => {
          void toggleCollectAll(e.target.checked);
        }}
      />
      Collect all
      <br />
      Or collect specific fields:
      <br />
      <StructSpecEditorInner
        spec={props.spec}
        binaryID={props.binaryID}
        types={props.types}
        // If "collect all" is checked, the checkboxes on individual fields are
        // disabled.
        disabled={props.spec.collectAll}
      />
    </>
  );
}

function ScalarTypeSpecInfo(props: {kind: Type_Kind}): React.JSX.Element {
  return (
    <Typography>
      Type of kind: {props.kind} does not have sub-fields; it will be collected
      as per the default policy.
    </Typography>
  );
}

function isFunctionSpec(
  spec: FunctionSpec | FunctionSpecSkeleton | TypeSpec,
): spec is FunctionSpec {
  return (spec as FunctionSpec).funcName !== undefined;
}

function isFunctionSpecSkeleton(
  spec: FunctionSpec | FunctionSpecSkeleton | TypeSpec,
): spec is FunctionSpecSkeleton {
  return (spec as FunctionSpecSkeleton).funcQualifiedName !== undefined;
}

function isTypeSpec(
  spec: FunctionSpec | FunctionSpecSkeleton | TypeSpec,
): spec is TypeSpec {
  return !isFunctionSpec(spec) && !isFunctionSpecSkeleton(spec);
}
