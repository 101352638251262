import {Box, IconButton, Stack, TableCell, TableRow} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import {GET_BINARIES} from "./gqlHelper.ts";
import {UnknownBinary} from "./Binaries.tsx";
import {Binary} from "../../__generated__/graphql.ts";
import {gql} from "../../__generated__";
import {useMutation} from "@apollo/client";
import {DeepPartial} from "@apollo/client/utilities";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import {IconEdit} from "../../components/icons/IconEdit.tsx";

type BinaryInfo = {
  type: "known-binary";
} & DeepPartial<Binary>;

const CREATE_BINARY = gql(/* GraphQL */ `
  mutation createBinary($input: CreateBinaryInput!) {
    createBinary(input: $input) {
      id
      userName
    }
  }
`);

export const UPDATE_BINARY = gql(/* GraphQL */ `
  mutation updateBinary($input: UpdateBinaryInput!) {
    updateBinary(input: $input) {
      id
      userName
    }
  }
`);

type BinaryRowArgs = {
  binary: BinaryInfo | UnknownBinary;
  onBinaryCreated: (id: string) => void;
};

export default function BinaryRow(props: BinaryRowArgs) {
  const binary = props.binary;

  // All the mutations will cause the parent to re-render.
  const [addBinary] = useMutation(CREATE_BINARY, {
    refetchQueries: [GET_BINARIES],
  });
  const [updateBinary] = useMutation(UPDATE_BINARY, {
    refetchQueries: [GET_BINARIES],
  });

  const name =
    binary.type == "known-binary" ? binary.userName! : binary.suggestedName;

  // Unknown binaries start in editing mode.
  const [editingName, setEditingName] = useState(
    binary.type == "unknown-binary",
  );
  const [newName, setNewName] = useState(name);

  const onNameEdit = () => {
    setEditingName(true);
    setNewName(name);
  };

  const onNameSave = async () => {
    setEditingName(false);
    if (binary.type == "unknown-binary") {
      const res = await addBinary({
        variables: {
          input: {
            Hash: binary.id,
            Name: newName,
          },
        },
      });
      props.onBinaryCreated(res.data!.createBinary.id);
    } else {
      await updateBinary({
        variables: {
          input: {
            ID: binary.id!,
            Name: newName,
          },
        },
      });
    }
  };

  const valid = newName != "";

  return (
    <TableRow key={binary.id}>
      <TableCell sx={{width: "100%"}}>
        {editingName ? (
          <TextField
            size="small"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            InputProps={{
              endAdornment: newName && (
                <IconButton
                  color="primary"
                  sx={{p: 0}}
                  component="span"
                  onClick={() => setEditingName(false)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            fullWidth
          />
        ) : (
          name
        )}
      </TableCell>

      <TableCell sx={{px: 0, width: "0px", position: "relative"}}>
        <Box sx={{position: "absolute", left: "20px", top: "6px"}}>
          {editingName ? (
            <IconButton
              color="primary"
              size="small"
              onClick={() => void onNameSave()}
              disabled={!valid}
            >
              <SaveIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => onNameEdit()}>
              <IconEdit />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
