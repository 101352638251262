import React from "react";
import {Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

export function HelpCircle(props: {
  tip: React.ReactNode;
  small?: boolean;
}): React.JSX.Element {
  return (
    <Tooltip title={props.tip}>
      <HelpIcon
        color="info"
        sx={{cursor: "pointer"}}
        fontSize={props.small ? "small" : "medium"}
      />
    </Tooltip>
  );
}
