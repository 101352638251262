import React, {ReactNode} from "react";
import {Box, Stack, styled, Typography} from "@mui/material";
import {groupBy, orderBy} from "lodash";
import dayjs, {Dayjs} from "dayjs";
import isToday from "dayjs/plugin/isToday";
import {DATE_FORMAT_MMM_D_YYYY} from "../constants/date-formats.ts";

dayjs.extend(isToday);

// ItemInterface<K> describes types with an id of type number and another key K
// corresponding to a value of type Dayjs.
type ItemInterface<K extends string | number | symbol> = {id: number} & Record<
  K,
  Dayjs
>;

export type Props<Item extends ItemInterface<K>, K extends keyof Item> = {
  data: Item[];
  // The field sort to sort by.
  sortDate: K;
  renderItem: (item: Item, index: number) => ReactNode;
};

export function Timeline<Item extends ItemInterface<K>, K extends keyof Item>(
  props: Props<Item, K>,
) {
  const {sortDate, data, renderItem} = props;

  const sortedAndGrouped = groupBy(
    orderBy(data, sortDate, ["desc"]),
    (item) => {
      const date = item[sortDate];

      if (date.isToday()) {
        return "Today";
      }

      if (date.add(1, "day").isToday()) {
        return "Yesterday";
      }

      return date.format(DATE_FORMAT_MMM_D_YYYY);
    },
  );

  return (
    <Stack sx={{height: "100%"}}>
      {Object.entries(sortedAndGrouped).map(([date, items]) => (
        <Stack direction="row" key={date}>
          <TimeLineBox>
            <Typography
              variant="caption"
              sx={{display: "block", whiteSpace: "break-spaces", width: 50}}
            >
              {date}
            </Typography>
          </TimeLineBox>

          <Stack sx={{flexGrow: 1, mb: 4}} spacing={1}>
            {items.map((item, index) => {
              return <Box key={item.id}>{renderItem(item, index)}</Box>;
            })}
          </Stack>
        </Stack>
      ))}

      <Stack sx={{flexGrow: 1}}>
        <TimeLineBox />
      </Stack>
    </Stack>
  );
}

const TimeLineBox = styled(Box)(({theme}) => {
  const width = 90;
  const borderWidth = 1;
  const color = theme.palette.info.main;
  const squareSize = 16;

  return {
    borderRight: `${borderWidth}px solid`,
    width: width,
    minWidth: width,
    marginRight: "30px",
    borderColor: color,
    height: "100%",
    position: "relative",

    "&:not(:last-child):after": {
      content: "''",
      position: "absolute",
      width: squareSize,
      height: squareSize,
      top: 0,
      right: `-${squareSize / 2 + borderWidth}px`,
      borderRadius: 4,
      border: `${borderWidth}px solid`,
      borderColor: color,
      backgroundColor: theme.palette.background.default,
    },

    "&:not(:last-child):before": {
      content: "''",
      position: "absolute",
      width: squareSize - 10,
      height: squareSize - 10,
      top: squareSize / 2,
      transform: `translate(-50%, -50%)`,
      right: `-${squareSize / 2 - borderWidth}px`,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
    },
  };
});
