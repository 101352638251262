import React, {useState} from "react";
import {useApolloClient} from "@apollo/client";
import {ADD_OR_UPDATE_TYPE_SPEC} from "@components/available-vars.tsx";
import {Button, Stack} from "@mui/material";
import TypesAutocomplete from "./TypesAutocomplete.tsx";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

type Props = {
  binaryID: string;
};

export function AddTypeSpecCard(props: Props): React.JSX.Element {
  const [newTypeQualifiedName, setNewTypeQualifiedName] = useState(
    null as string | null,
  );
  const client = useApolloClient();
  const addType = async () => {
    const {errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_TYPE_SPEC,
      variables: {
        input: {
          typeQualifiedName: newTypeQualifiedName!,
          collectExprs: [],
          collectAll: true,
        },
      },
    });
    if (errors) {
      console.error("failed to add type spec", errors);
    } else {
      setNewTypeQualifiedName(null); // clear the autocomplete
    }
  };
  return (
    <Stack direction="row" gap={2}>
      <TypesAutocomplete
        binaryID={props.binaryID}
        onValueChange={(val: string) => setNewTypeQualifiedName(val)}
        value={newTypeQualifiedName}
      />
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddOutlinedIcon />}
        onClick={() => void addType()}
        disabled={!newTypeQualifiedName}
      >
        Add
      </Button>
    </Stack>
  );
}
