import {FlamegraphCell} from "./FlamegraphCell.tsx";
import {
  FlamegraphWidthStyle,
  GetNodeIds,
  NodeOrRangeWithWeight,
  TreeNode,
} from "./FlamegraphData.ts";
import {BuildFlamegraphRows} from "./FlamegraphProcessing.ts";
import {FlamegraphMenu} from "./FlamegraphMenu.tsx";
import "./flamegraph.css";
import {
  FlamegraphState,
  NodeSelector,
} from "@components/Flamegraph/FlamegraphState.ts";
import {useSnapshotState} from "@providers/snapshot-state.tsx";
import {useState} from "react";
import {FlamegraphCellCompressed} from "@components/Flamegraph/FlamegraphCellCompressed.tsx";

interface FlamegraphRowProps {
  row: Array<NodeOrRangeWithWeight>;
  state: FlamegraphState;
}

function FlamegraphRow({row, state}: FlamegraphRowProps) {
  const children = row.map(({node, weight}, index) => {
    const content =
      node &&
      (Array.isArray(node) ? (
        <FlamegraphCellCompressed
          nodes={node}
          state={state}
          key={"nodes-" + GetNodeIds(node).join("-")}
        />
      ) : (
        <FlamegraphCell node={node} state={state} key={"node" + node.uid} />
      ));

    return (
      <div
        className="flame-column"
        style={{width: 100 * weight + "%"}}
        key={"node-" + index}
      >
        {content}
      </div>
    );
  });

  return <div className="flame-row">{children}</div>;
}

export interface FlamegraphProps {
  root: TreeNode;
  // If isReversed is true, the rows are reversed, with the root at the bottom.
  // This can be used to create "butterfly" views from two separate graphs.
  isReversed?: boolean;
  highlighterFilter: string;
  setSelectedFrame: NodeSelector;
}

export function Flamegraph(props: FlamegraphProps) {
  const {root, setSelectedFrame, highlighterFilter} = props;

  const [widthStyle, setWidthStyle] = useState<FlamegraphWidthStyle>(
    FlamegraphWidthStyle.Linear,
  );

  const snapshotState = useSnapshotState();
  // TODO This should probably be useFlamegraphState(),
  //  but I think this change would be better done in the next iteration.
  const state = new FlamegraphState(
    root,
    snapshotState,
    setSelectedFrame,
    highlighterFilter,
    widthStyle,
    setWidthStyle,
  );

  const rows = BuildFlamegraphRows(root, state);

  if (props.isReversed) {
    rows.reverse();
  }

  return (
    <div className="flame-graph">
      <FlamegraphMenu state={state} />
      {rows.map((row, index) => (
        <FlamegraphRow row={row} state={state} key={"row-" + index} />
      ))}
    </div>
  );
}
