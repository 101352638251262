import {gql} from "@graphql/gql.ts";

export const GET_TABLES = gql(/* GraphQL */ `
  query GetTables($snapshotID: ID!) {
    getTables(snapshotID: $snapshotID) {
      ...FullTableSchema
    }
  }
`);

export const DELETE_DERIVED_TABLE_SPEC = gql(/* GraphQL */ `
  mutation DeleteDerivedTableSpec($tableID: String!) {
    deleteDerivedTableSpec(id: $tableID) {
      ...FullSnapshotSpec
    }
  }
`);
