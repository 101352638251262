import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiList = (): Pick<Components<typeof theme>, "MuiList"> => ({
  MuiList: {
    styleOverrides: {
      root: ({theme}) => ({
        "&.show-list-style .MuiListItem-root:before": {
          content: "' '",
          position: "absolute",
          top: "calc(50% - 2px)",
          transform: "translateY(-50%)",
          left: 0,
          width: 7,
          height: "40%",
          borderWidth: "1px",
          borderColor: theme.palette.info.main,
          borderStyle: "none none solid solid",
        },
      }),
    },
    variants: [
      {
        props: {dense: true},
        style: {
          paddingBlock: 0,

          ".MuiListItemIcon-root": {
            minWidth: 0,
          },

          ".MuiButtonBase-root": {
            padding: 5,
          },
        },
      },
    ],
  },
});
