import React, {useContext} from "react";
import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ProcessFriendlyNameComposer from "./ProcessFriendlyNameComposer.tsx";
import {SpecContext} from "@providers/spec-provider.tsx";
import {NewProgramCard} from "./NewProgramCard.tsx";

export function ProgramsEditor(): React.JSX.Element {
  const spec = useContext(SpecContext);

  return (
    <Stack>
      <NewProgramCard />

      <Divider sx={{my: 3, borderWidth: 2}} />

      <TableContainer>
        <Table sx={{minWidth: 650}}>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: "150px"}}>Program</TableCell>
              <TableCell sx={{width: "300px"}}>Format string</TableCell>
              <TableCell sx={{minWidth: "200px"}}>Value</TableCell>
              <TableCell sx={{width: "170px"}} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spec.programs.map((program) => (
              <ProcessFriendlyNameComposer
                key={program.programName}
                asTableRow={true}
                program={program.programName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
