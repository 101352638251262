import React, {MouseEventHandler} from "react";
import {Avatar as MUIAvatar, Box} from "@mui/material";

type Props = {
  // If the photo is not set, a generic gray one is rendered.
  photoURL?: string;
  onClick?: MouseEventHandler;
};

export const Avatar: React.FC<Props> = ({photoURL, onClick}) => {
  return (
    <Box sx={onClick != undefined ? {cursor: "pointer"} : {}} onClick={onClick}>
      <MUIAvatar src={photoURL} alt={"avatar"} variant="circular" />
    </Box>
  );
};
