import {
  LabelRule,
  LabelRuleInput,
  Predicate,
} from "../../../../__generated__/graphql.ts";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ProcessPredicatesConjunctionEditor from "./PredicatesConjunctionEditor.tsx";
import SaveIcon from "@mui/icons-material/Save";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {IconEdit} from "../../../../components/icons/IconEdit.tsx";

type Props = {
  // The rule to edit. If not set, we're in "add" mode.
  rule?: LabelRule;
  // If rule is not set, applyLabel must be set and controls the label that the
  // new rule will apply.
  applyLabel?: string;
  // valueSuggestions, is specified, are used as autocomplete suggestions for
  // the label's value textbox.
  valueSuggestions?: string[];
  // The list of labels that predicates can reference.
  labels: {label: string; note?: string}[];
  onSave: (lr: LabelRuleInput) => Promise<void>;
  // onDelete does not have to be specified when adding a new predicate.
  onDelete?: () => Promise<void>;
  // onCancel does not have to be specified when editing an existing predicate
  // (as opposed to adding a new one).
  onCancel?: () => void;
};

// LabelRuleEditor renders a label rule in either "view mode" or "edit mode". It
// delegates to LabelRuleViewer when not in edit mode.
export function LabelRuleEditor(props: Props): React.JSX.Element {
  // If the rule is not specified, we are in "add" mode. This means we're in
  // edit mode and there's no way to exit edit mode.
  const adding = props.rule == undefined;
  const [editing, setEditing] = useState(adding);
  const [programValue, setProgramValue] = useState(props.rule?.value ?? "");
  const [newPredicates, setNewPredicates] = useState<Predicate[]>(
    props.rule?.predicatesConjunction ?? [
      {label: "", valueRegex: "", id: uuidv4()},
    ],
  );
  const applyLabel = props.applyLabel ?? props.rule!.label;

  // errors
  const programIsMissed = programValue == "" && `Program is required.`;
  const noPredicates =
    newPredicates.length == 0 && "At least one predicate is required.";
  const predicatesInvalid =
    newPredicates.some((p) => p.label == "" || p.valueRegex == "") &&
    "All predicates must have both a label and a value.";

  // invalid state based om errors above
  const invalid = !!programIsMissed || !!noPredicates || !!predicatesInvalid;

  function onEdit() {
    setEditing(true);
    setProgramValue(props.rule!.value ?? "");
    setNewPredicates(props.rule!.predicatesConjunction);
  }

  async function onDelete() {
    await props.onDelete!();
  }

  function onCancel() {
    if (props.onCancel) {
      props.onCancel();
    } else {
      setEditing(false);
    }
  }

  async function onSave() {
    await props.onSave({
      label: applyLabel,
      value: programValue,
      predicates: newPredicates,
    });
    setEditing(false);
  }

  function onPredicatesUpdated(predicates: Predicate[]) {
    setNewPredicates(predicates);
  }

  if (!editing) {
    return (
      <Card color="default">
        <CardHeader
          title={
            <Stack direction="row" gap={1}>
              <Typography variant="body4">Program:</Typography>
              <Typography variant="body4" color="primary.light">
                {props.rule!.value}
              </Typography>

              <Tooltip title={"Edit program rule"} sx={{ml: "auto"}}>
                <Button color="info" variant="outlined" onClick={onEdit}>
                  <IconEdit />
                </Button>
              </Tooltip>
              <Tooltip title={"Delete program rule"}>
                <Button color="info" variant="outlined" onClick={onDelete}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Stack>
          }
        ></CardHeader>

        <CardContent>
          <Typography variant="subtitle1" sx={{mb: 2}}>
            When a process matches all of the following predicates
          </Typography>

          <ProcessPredicatesConjunctionEditor
            conjunction={props.rule!.predicatesConjunction}
            labels={props.labels}
            edit={false}
          />
        </CardContent>
      </Card>
    );
  }

  // We're in editing mode.
  return (
    <Card color="default">
      <CardHeader
        title={
          <Autocomplete
            // We suggest some values, but also allow entering new ones with freeSolo.
            options={props.valueSuggestions ?? []}
            freeSolo={true}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={programIsMissed || "Program name"}
                color="secondary"
                error={!!programIsMissed}
              />
            )}
            inputValue={programValue}
            onInputChange={(e, newValue) => setProgramValue(newValue)}
          />
        }
      />
      <CardContent>
        <Typography variant="subtitle1" sx={{mb: 2}}>
          When a process matches all of the following predicates
        </Typography>

        <Box overflow="auto">
          <ProcessPredicatesConjunctionEditor
            conjunction={newPredicates}
            labels={props.labels}
            edit={editing}
            onChange={onPredicatesUpdated}
          />
        </Box>

        {noPredicates ||
          (predicatesInvalid && (
            <Typography variant="error">
              <WarningAmberIcon color="error" />
              {noPredicates || predicatesInvalid}
            </Typography>
          ))}

        <Divider sx={{my: 3}} />

        <Stack direction="row" gap={1} justifyContent="flex-end">
          <Tooltip title={"Cancel"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onCancel}
              size="large"
            >
              Cancel
            </Button>
          </Tooltip>

          <Tooltip title={"Save program rule"}>
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={invalid}
                onClick={onSave}
                startIcon={<SaveIcon />}
                size="large"
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </CardContent>
    </Card>
  );
}
