import {Button, Tooltip} from "@mui/material";
import {AuthenticatedDownloadLink} from "./util.tsx";
import {useContext} from "react";
import {IconDownload} from "./icons/IconDownload.tsx";
import {
  AppConfigContext,
  getApiUrlForPath,
} from "@providers/app-config-provider.tsx";

type Props = {
  // The snapshot whose database will be downloaded.
  snapshotID: number;
  // The name of the file to use for the downloaded database.
  fileName: string;
};

export function DownloadDatabaseButton({snapshotID, fileName}: Props) {
  const appConfig = useContext(AppConfigContext);
  const url = getApiUrlForPath(appConfig.APIBaseURL, `/db/${snapshotID}`);
  return (
    <Tooltip title={"Download a SQLite database with this snapshot's data"}>
      <Button variant="outlined" color="info">
        <AuthenticatedDownloadLink url={url} filename={fileName}>
          <IconDownload />
        </AuthenticatedDownloadLink>
      </Button>
    </Tooltip>
  );
}
