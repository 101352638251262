import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext, useState} from "react";
import {SpecContext} from "@providers/spec-provider.tsx";
import {useApolloClient} from "@apollo/client";
import {
  tableReferenceToInputID,
  tableReferenceToTableInfo,
} from "@util/spec.ts";
import {LinkSpec, LinkSpecInput} from "@graphql/graphql.ts";
import {ADD_OR_UPDATE_LINK, DELETE_LINK} from "../gqlHelpers.ts";
import {NewLinkCard} from "./NewLinkCard.tsx";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import {IconEdit} from "@components/icons/IconEdit.tsx";
import SaveIcon from "@mui/icons-material/Save";
import {TabContext, TabList} from "@mui/lab";

export default function LinksEditor() {
  const spec = useContext(SpecContext);

  const tabs = [...new Set(spec.links.flatMap((link) => link.modules))];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  return (
    <Stack sx={{pb: 4}}>
      <NewLinkCard />

      <Divider sx={{borderWidth: 2}} />

      <Stack sx={{my: 3}}>
        <Typography variant="h2">Links</Typography>
      </Stack>

      <TabContext value={selectedTab}>
        <TabList
          variant="scrollable"
          onChange={(_, value) => setSelectedTab(value as string)}
        >
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} value={tab} />
          ))}
        </TabList>
      </TabContext>

      <Divider color="gradient"></Divider>

      <TableContainer sx={{minHeight: 200, mt: 3}}>
        <Table sx={{minWidth: 600}}>
          <TableHead>
            <TableRow>
              <TableCell sx={{minWidth: "200px"}}>Source</TableCell>
              <TableCell sx={{minWidth: "200px"}}>Target</TableCell>
              <TableCell sx={{minWidth: "400px"}}>Note</TableCell>
              <TableCell sx={{width: "100px"}} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spec.links
              .filter((link) => link.modules.includes(selectedTab))
              .map((link: LinkSpec) => (
                <LinkRow key={link.id} link={link} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function LinkRow({link}: {link: LinkSpec}): React.JSX.Element {
  const spec = useContext(SpecContext);
  const client = useApolloClient();

  const [editing, setEditing] = useState<boolean>(false);
  const [note, setNote] = useState<string>(link.note ?? "");

  function deleteLink() {
    void client.mutate({
      mutation: DELETE_LINK,
      variables: {id: link.id},
    });
  }

  async function onUpdateNote() {
    const newLinkInput: LinkSpecInput = {
      id: link.id,
      srcTableID: tableReferenceToInputID(link.srcTable),
      srcCols: link.srcCols,
      destTableID: tableReferenceToInputID(link.destTable),
      destCols: link.destCols,
      note,
    };

    await client.mutate({
      mutation: ADD_OR_UPDATE_LINK,
      variables: {
        input: newLinkInput,
      },
    });

    setEditing(false);

    return true;
  }

  const src = tableReferenceToTableInfo(link.srcTable, spec);
  if (!src) {
    throw new Error(`source table not found: ${JSON.stringify(link.srcTable)}`);
  }
  const target = tableReferenceToTableInfo(link.destTable, spec);
  if (!target) {
    throw new Error(
      `target table not found: ${JSON.stringify(link.destTable)}`,
    );
  }

  return (
    <TableRow>
      <TableCell>
        {src.displayName} - {link.srcCols.join(", ")}
      </TableCell>
      <TableCell>
        {target.displayName} - {link.destCols.join(", ")}
      </TableCell>
      <TableCell>
        {editing ? (
          <TextField
            size="small"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            InputProps={{
              endAdornment: note && (
                <IconButton
                  color="primary"
                  sx={{p: 0}}
                  component="span"
                  onClick={() => setNote("")}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            fullWidth
          />
        ) : (
          <Typography variant="body4" color="primary.light">
            {link.note}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Stack sx={{display: "grid", gridTemplateColumns: "40px 40px"}}>
          {editing && (
            <>
              <Tooltip title={"Save link"}>
                <IconButton onClick={onUpdateNote}>
                  <SaveIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Cancel"}>
                <IconButton onClick={() => setEditing(false)}>
                  <ClearIcon color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}

          {!editing && (
            <>
              <Tooltip title={"Edit link"}>
                <IconButton onClick={() => setEditing(true)}>
                  <IconEdit />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete link"}>
                <IconButton onClick={deleteLink}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
