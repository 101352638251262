import React, {PropsWithChildren, useContext} from "react";
import {AppConfigContext} from "../../providers/app-config-provider.tsx";
import NoAuthTemplate from "../NoAuthTemplate";
import LoginPage from "../../pages/LoginPage";
import {SpecProvider} from "../../providers/spec-provider.tsx";

// RequireAuth checks whether authentication is required. If it is, it checks
// whether the user is logged in. If they are, the children are rendered.
// Otherwise, the login dialog is rendered.
export const RequireAuth: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const appCfg = useContext(AppConfigContext);

  if (appCfg.AuthEnabled && !appCfg.User) {
    return (
      <NoAuthTemplate>
        <LoginPage />
      </NoAuthTemplate>
    );
  }

  return <SpecProvider>{children}</SpecProvider>;
};
