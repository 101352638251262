import {
  Auth,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "@firebase/auth";
import {FirebaseError} from "@firebase/util";
import {ApolloClient} from "@apollo/client";

function isFirebaseAuthError(error: unknown): boolean {
  return error instanceof FirebaseError;
}

export async function signInWithGooglePopup(auth: Auth) {
  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    // Ask the user to select the account to sign in with every time they log in.
    // https://groups.google.com/g/firebase-talk/c/gxBm0WKCuIY
    prompt: "select_account",
  });

  try {
    await signInWithPopup(auth, googleProvider);
  } catch (error) {
    if (
      isFirebaseAuthError(error) &&
      (<FirebaseError>error).code ===
        "auth/account-exists-with-different-credential"
      // TODO: I should link the accounts. See
      // https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
    ) {
      console.log("Account exists with different sign-in method");
      // TODO: Handle linking of accounts as per Firebase documentation
    }
    throw error;
  }
}

export async function signInWithGithubPopup(auth: Auth) {
  const githubProvider = new GithubAuthProvider();
  githubProvider.addScope("user:email"); // Add scope for email access

  try {
    await signInWithPopup(auth, githubProvider);
  } catch (error) {
    if (
      isFirebaseAuthError(error) &&
      (<FirebaseError>error).code ===
        "auth/account-exists-with-different-credential"
      // TODO: I should link the accounts. See
      // https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
    ) {
      console.log("Account exists with different sign-in method");
      // TODO: Handle linking of accounts as per Firebase documentation
    }
    throw error;
  }
}

export async function signOutFromAuth(
  auth: Auth,
  client: ApolloClient<unknown>,
) {
  try {
    await signOut(auth); // Sign out from Firebase Auth

    // Reset the Apollo cache.
    //
    // Note that we use resetStore(), which causes active queries to be refetched.
    // These queries will fail (since there is no longer an auth token). This is
    // unfortunate, although benign (since we don't actually need the results of
    // the active queries any more after a sign-out). There is also
    // client.clearStore(), which does not have this refetch behavior, but that
    // method is dangerous: if any query remains active from before the sign-out
    // to after a new sign-in (e.g. if the query's component is not unmounted on
    // sign-out), then the query will return stale data after the new sign-in.
    await client.resetStore();
  } catch (error) {
    console.error("Sign-out error", error);
  }
}
