import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {Typography} from "@mui/material";
import {Label} from "../../../../util/labels.ts";
import {LabelRule} from "../../../../__generated__/graphql.ts";

type Props = {
  applyLabel: string;
  labelRules: LabelRule[];
};

export const ProgramLabelDefault: React.FC<Props> = ({
  applyLabel,
  labelRules,
}) => {
  // If we're asked to apply the "program" label, we check whether the
  // auto-created rule for the "program" label is still around and, if it is, we
  // display a warning.
  const programLabelIsDefault =
    applyLabel == Label.Program &&
    labelRules.find(
      (rule) =>
        rule.label === Label.Program &&
        rule.value === "my_program" &&
        rule.predicatesConjunction.length == 1 &&
        rule.predicatesConjunction[0].label == "BinaryName" &&
        rule.predicatesConjunction[0].valueRegex == "my_binary",
    ) !== undefined;

  if (!programLabelIsDefault) {
    return null;
  }

  return (
    <Typography variant="warning">
      <WarningAmberIcon color="warning" />
      The rule for the "program" label is the default one. You probably want to
      edit this rule so that it matches the programs you're interested in
      monitoring.
    </Typography>
  );
};
