import {Stack, Typography} from "@mui/material";
import React, {useContext} from "react";
import TextField from "@mui/material/TextField";
import {AgentReportsContext} from "src/providers/agent-reports-provider.tsx";
import {ProgramRulesCard} from "./components/ProgramRules/ProgramRules.tsx";
import {AllProcessesWrapper} from "./components/AllProcesses/AllProcessesWrapper.tsx";
import {DeployAgentsInstructions} from "./components/DeployAgentsInstructions.tsx";
import {TabsEnvironments} from "./components/TabsEnvironments.tsx";

export default function Agents() {
  const agentsReport = useContext(AgentReportsContext);
  const reports = agentsReport?.Report?.Reports;
  const emptyReport = !reports || reports.length == 0;
  const numAgents = reports?.length ?? 0;

  return (
    <>
      <Stack sx={{my: 3}}>
        <Typography variant="h1">Agents</Typography>
        <Typography color="primary.light" variant="body3" sx={{my: 1}}>
          {numAgents} agent{numAgents == 1 ? "" : "s"} connected
        </Typography>
      </Stack>

      <Stack gap={3} pb={4}>
        <Stack direction="row" gap={2} flexWrap="wrap">
          <TextField
            value={"Data version: " + agentsReport?.Report?.DataVersion}
            color="secondary"
            disabled
            sx={{width: 400}}
          />
          <TextField
            value={"Ex version: " + agentsReport?.Report?.ExVersion}
            color="secondary"
            disabled
            sx={{width: 400}}
          />
        </Stack>

        {emptyReport ? <DeployAgentsInstructions /> : <TabsEnvironments />}

        <ProgramRulesCard />

        <AllProcessesWrapper />
      </Stack>
    </>
  );
}
