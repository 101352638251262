import React, {useState} from "react";
import {useApolloClient} from "@apollo/client";
import {addOrUpdateFunctionSpec} from "@util/queries.tsx";
import {Button, Stack} from "@mui/material";
import FunctionsAutocomplete from "./FunctionsAutocomplete.tsx";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// AddFunctionSpecCard renders an autocomplete box that lets the user select a
// function to add to the spec.
export function AddFunctionSpecCard({binaryID}: {binaryID: string}) {
  const [selectedFuncQualifiedName, setSelectedFuncQualifiedName] = useState(
    null as string | null,
  );
  // We control the Autocomplete's input value in order to be able to clear the
  // autocomplete.
  const [inputValue, setInputValue] = useState("");
  const client = useApolloClient();

  async function addFunctionToSpec() {
    const ok = await addOrUpdateFunctionSpec(
      client,
      {
        funcQualifiedName: selectedFuncQualifiedName!,
      },
      undefined /* showConfirmationDialog - no validation needed for empty specs */,
    );
    if (ok) {
      setSelectedFuncQualifiedName(null);
      setInputValue("");
    }
  }

  return (
    <Stack direction="row" gap={2}>
      <FunctionsAutocomplete
        binaryID={binaryID}
        inputValue={inputValue}
        value={selectedFuncQualifiedName}
        onValueChange={(val) => setSelectedFuncQualifiedName(val)}
        onInputChange={(val) => setInputValue(val)}
      />
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddOutlinedIcon />}
        onClick={() => void addFunctionToSpec()}
        disabled={!selectedFuncQualifiedName}
      >
        Add
      </Button>
    </Stack>
  );
}
