import {useApolloClient, useSuspenseQuery} from "@apollo/client";
import {LabelRule, LabelRuleInput} from "../../../../__generated__/graphql.ts";
import {GET_LABEL_RULES} from "../../gqlHelper.ts";
import React, {useContext, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
} from "@mui/material";
import {Label, LabelExplanations} from "../../../../util/labels.ts";
import {addLabelRule} from "../../label-helpers/add-label-rule.ts";
import {updateLabelRule} from "../../label-helpers/update-label-rule.ts";
import {deleteLabelRule} from "../../label-helpers/delete-label-rule.ts";
import {SpecContext} from "../../../../providers/spec-provider.tsx";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {LabelRuleEditor} from "./LabelRuleEditor.tsx";
import {Subheader} from "./Subheader.tsx";
import {ProgramLabelDefault} from "./ProgramLabelDefault.tsx";
import {ProgramLabelMissing} from "./ProgramLabelMissing.tsx";

// ProgramRulesCard is the viewer/editor for the set of program labeling rules.
export function ProgramRulesCard(): React.JSX.Element {
  // Collect the names of all programs that have specs.
  const snapshotSpec = useContext(SpecContext);
  const programNames = snapshotSpec.programs.map(
    (program) => program.programName,
  );

  const {data: labelRulesResult} = useSuspenseQuery(GET_LABEL_RULES, {});
  const client = useApolloClient();
  const applyLabel = Label.Program;

  // Filter the label rules to only those that apply the label we're interested in.
  const labelRules = labelRulesResult.getLabelRules.filter(
    (rule: LabelRule) => rule.label == applyLabel,
  );

  const [addingLabelRule, setAddingLabelRule] = useState(false);

  // Build the set of labels available for predicates. Start with the built-in
  // labels and add all the labels produced by existing rules.
  const labels: {label: string; note?: string}[] = Object.values(Label).map(
    (label) => ({label, note: LabelExplanations.get(label)}),
  );
  for (const rule of labelRules) {
    labels.push({label: rule.label});
  }

  async function onDeleteLabelRule(id: number) {
    await deleteLabelRule(client, id);
  }

  async function onUpdateLabelRule(lr: LabelRuleInput, id: number) {
    await updateLabelRule(client, id, lr);
  }

  async function onAddLabelRule(lr: LabelRuleInput) {
    await addLabelRule(client, lr);
    setAddingLabelRule(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" gap={2} justifyContent="space-between">
            <span>Program rules</span>
            <Button
              onClick={() => setAddingLabelRule(true)}
              disabled={addingLabelRule}
              variant="contained"
              startIcon={<AddOutlinedIcon />}
            >
              Add new rules
            </Button>
          </Stack>
        }
        subheader={<Subheader />}
      />

      <CardContent component={Stack} gap={3}>
        {labelRules.map((rule, i) => (
          <React.Fragment key={rule.ID}>
            {!!i && <Divider />}
            <LabelRuleEditor
              rule={rule}
              valueSuggestions={programNames}
              labels={labels}
              onDelete={() => onDeleteLabelRule(rule.ID)}
              onSave={async (newRule) =>
                await onUpdateLabelRule(newRule, rule.ID)
              }
            />
          </React.Fragment>
        ))}

        {addingLabelRule && (
          <LabelRuleEditor
            applyLabel={applyLabel}
            valueSuggestions={programNames}
            labels={labels}
            onSave={onAddLabelRule}
            onCancel={() => setAddingLabelRule(false)}
          />
        )}

        <ProgramLabelMissing applyLabel={applyLabel} labelRules={labelRules} />

        <ProgramLabelDefault applyLabel={applyLabel} labelRules={labelRules} />
      </CardContent>
    </Card>
  );
}
