import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import IconSnapshots from "@components/icons/IconSnapshots.tsx";
import IconSpec from "@components/icons/IconSpec.tsx";
import IconAgents from "@components/icons/IconAgents.tsx";
import IconBinaries from "@components/icons/IconBinaries.tsx";
import IconSchedule from "@components/icons/IconSchedule.tsx";
import {pathComponent, ROUTER_PATHS} from "../../router/router-paths.ts";
import IconSnapshotsActive from "@components/icons/IconSnaphotsActive.tsx";
import {MenuItem} from "./components/MenuItem.tsx";

export const menuItems: MenuItem[] = [
  {
    matcher: (location: string) =>
      location == "/" || location.includes(ROUTER_PATHS.snapshots),
    link: ROUTER_PATHS.snapshots,
    name: "Snapshots",
    icon: <IconSnapshots />,
    iconActive: <IconSnapshotsActive />,
  },
  {
    matcher: (location: string) =>
      location.includes(pathComponent(ROUTER_PATHS.enableEvents)) ||
      location.includes(pathComponent(ROUTER_PATHS.log)) ||
      location.includes(pathComponent(ROUTER_PATHS.liveLog)),
    link: ROUTER_PATHS.enableEvents,
    name: "Events",
    icon: <ManageSearchIcon sx={{color: (theme) => theme.palette.info.main}} />,
    iconActive: <ManageSearchIcon sx={{color: "#c63434"}} />,
  },
  {
    matcher: (location: string) =>
      location.includes(ROUTER_PATHS.snapshotSpecEditor),
    link: ROUTER_PATHS.snapshotSpecEditor,
    name: "Spec",
    icon: <IconSpec />,
    iconActive: <IconSpec color="#FAFF00" />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.agents),
    link: ROUTER_PATHS.agents,
    name: "Agents",
    icon: <IconAgents />,
    iconActive: <IconAgents color="#F78D1E" />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.binaries),
    link: ROUTER_PATHS.binaries,
    name: "Binaries",
    icon: <IconBinaries />,
    iconActive: <IconBinaries color="#38FFB7" />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.schedule),
    link: ROUTER_PATHS.schedule,
    name: "Schedule",
    icon: <IconSchedule />,
    iconActive: <IconSchedule color="#EB00FF" />,
  },
];
