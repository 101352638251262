import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {debounce} from "@mui/material";
import {useApolloClient} from "@apollo/client";
import {gql} from "@graphql/gql.ts";
import {useCallback, useEffect, useState} from "react";
import {FunctionName} from "@graphql/graphql.ts";
import toast from "react-hot-toast";

type FunctionsAutocompleteProps = {
  binaryID: string;
  // value is the qualified name of the selected function, or null if no function is selected.
  value: string | null;
  // inputValue is the text in the input box. In order for the parent to be able
  // to clear the Autocomplete, it seems that it needs to control both value and
  // inputValue.
  inputValue: string;
  onValueChange: (_: string | null) => void;
  onInputChange: (val: string) => void;
};

const LIST_FUNCTIONS_FROM_BINARY = gql(/* GraphQL */ `
  query ListFunctionsFromBinary(
    $binaryID: String!
    $filter: String
    $limit: Int
  ) {
    listFunctionsFromBinary(
      binaryID: $binaryID
      filter: $filter
      limit: $limit
    ) {
      Package
      Type
      Name
      QualifiedName
    }
  }
`);

export type functionSuggestion = {
  friendlyName: string;
  qualifiedName: string;
};

export default function FunctionsAutocomplete(
  props: FunctionsAutocompleteProps,
) {
  const [options, setOptions] = useState<functionSuggestion[]>([]);
  const client = useApolloClient();

  const fetchOptions = useCallback(
    debounce(
      (
        request: {input: string},
        callback: (results?: FunctionName[]) => void,
      ) => {
        client
          .query({
            query: LIST_FUNCTIONS_FROM_BINARY,
            variables: {
              binaryID: props.binaryID,
              filter: request.input,
              limit: 50,
            },
          })
          .then(
            (res) => {
              callback(res.data.listFunctionsFromBinary);
            },
            (err) => {
              console.error("failed to get function suggestions:", err);
              toast.error(`Failed to get function suggestions: ${err}`);
            },
          );
      },
      400,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (props.inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetchOptions({input: props.inputValue}, (results?: FunctionName[]) => {
      // If this request was cancelled, ignore the result.
      if (!active) {
        return;
      }
      let newOptions = [] as functionSuggestion[];
      if (results) {
        newOptions = results.map((fn) => {
          let friendlyName: string;
          if (fn.Type) {
            friendlyName = `${fn.Package}.${fn.Type}.${fn.Name}`;
          } else {
            friendlyName = `${fn.Package}.${fn.Name}`;
          }
          return {
            qualifiedName: fn.QualifiedName,
            friendlyName: friendlyName,
          } as functionSuggestion;
        });
      }
      setOptions(newOptions);
    });
    return () => {
      active = false;
    };
  }, [props.inputValue]);

  return (
    <Autocomplete
      disablePortal
      filterOptions={(o) => o}
      clearOnBlur={false}
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          placeholder={`Add new function`}
        />
      )}
      inputValue={props.inputValue}
      getOptionLabel={(option: functionSuggestion) => option.qualifiedName}
      value={
        props.value == null
          ? null
          : {friendlyName: "", qualifiedName: props.value}
      }
      isOptionEqualToValue={(option, value) =>
        option.qualifiedName === value.qualifiedName
      }
      noOptionsText={props.inputValue ? "No functions found" : "Type to search"}
      onInputChange={(event, newInputValue, reason) => {
        props.onInputChange(newInputValue);
      }}
      onChange={(event, newValue: functionSuggestion | null, reason) => {
        props.onValueChange(newValue ? newValue?.qualifiedName : null);
      }}
      // Make the home/end keys move the cursor in the text box as opposed to
      // hanging the selection in the dropdown.
      handleHomeEndKeys={false}
    />
  );
}
