import {ApolloClient} from "@apollo/client";
import {LabelRuleInput} from "../../../__generated__/graphql.ts";
import {
  GET_ALL_PROCESSES,
  GET_LABEL_RULES,
  UPDATE_LABEL_RULE,
} from "../gqlHelper.ts";

export async function updateLabelRule(
  client: ApolloClient<any>,
  id: number,
  input: LabelRuleInput,
) {
  const {errors} = await client.mutate({
    mutation: UPDATE_LABEL_RULE,
    variables: {
      id: id,
      input: input,
    },
    refetchQueries: [GET_LABEL_RULES, GET_ALL_PROCESSES],
  });
  return errors;
}
