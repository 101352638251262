import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiCssBaseline = (): Pick<
  Components<typeof theme>,
  "MuiCssBaseline"
> => ({
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      "::-webkit-scrollbar": {
        backgroundColor: theme.palette.info.main,
        width: "8px",
        height: "8px",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: theme.palette.primary.main,
      },
    }),
  },
});
