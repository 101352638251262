import React from "react";

export const IconCheck: React.FC<{color?: string}> = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6.75049L9.75 17.25L4.5 12.0005"
        stroke={color || "#47454A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
