import React from "react";
import MuiLink from "@mui/material/Link";

export const Subheader: React.FC = () => {
  return (
    <span>
      Program rules are used to automatically assign "program names" to
      processes based on the process attributes. Processes that are assigned a
      program name are the ones that can be monitored. The program name is also
      used to match processes with the "friendly name" specifications that
      assign a user-friendly identifier to a process.
      <br />
      See{" "}
      <MuiLink
        href={
          "https://docs.side-eye.io/installing/#configuring-side-eye-to-monitor-processes/"
        }
        target={"_blank"}
      >
        Configuring Side-Eye to monitor processes
      </MuiLink>{" "}
      for more info.
    </span>
  );
};
