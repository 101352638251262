import React from "react";
import PageLayout from "./../templates/PageLayout";
import Snapshots from "../pages/Snaphots";
import AuthTemplate from "../templates/AuthTemplate";
import {ROUTER_PATHS} from "./router-paths.ts";
import LoginPage from "../pages/LoginPage";
import NoAuthTemplate from "../templates/NoAuthTemplate";
import {SnapshotStateProvider} from "../providers/snapshot-state.tsx";
import SingleSnapshot from "../pages/SingleSnapshot";
import Program from "../pages/Program";
import Agents from "../pages/Agents";
import Binaries from "../pages/Binaries";
import Schedule from "../pages/Schedule";
import SnapshotSpecEditor from "../pages/Spec";
import EnableEvents from "../pages/EnableEvents";
import {createHashRouter} from "react-router-dom";
import {MyErrorBoundary} from "../templates/PageLayout/components/ErrorBoundary.tsx";
import EventLogViewer from "src/pages/EventsTrace";
import Logout from "src/pages/Logout";
import GraphiQL from "src/pages/GraphiQL";
import LiveEventLog from "src/pages/LiveEventLog";

export const router = createHashRouter([
  {
    element: <PageLayout />,
    errorElement: <MyErrorBoundary />,
    children: [
      {
        path: ROUTER_PATHS.login,
        element: (
          <NoAuthTemplate>
            <LoginPage />
          </NoAuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.logout,
        element: (
          <NoAuthTemplate>
            <Logout />
          </NoAuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.homePage,
        element: (
          <AuthTemplate>
            <Snapshots />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.snapshots,
        element: (
          <AuthTemplate>
            <Snapshots />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.snapshot,
        element: (
          <AuthTemplate>
            <SnapshotStateProvider>
              <SingleSnapshot />
            </SnapshotStateProvider>
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.processFriendlyNameComposer,
        element: (
          <AuthTemplate>
            <Program />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.agents,
        element: (
          <AuthTemplate>
            <Agents />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.binaries,
        element: (
          <AuthTemplate>
            <Binaries />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.schedule,
        element: (
          <AuthTemplate>
            <Schedule />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.snapshotSpecEditor,
        element: (
          <AuthTemplate>
            <SnapshotSpecEditor />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.enableEvents,
        element: (
          <AuthTemplate>
            <EnableEvents />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.log,
        element: (
          <AuthTemplate>
            <EventLogViewer />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.liveLog,
        element: (
          <AuthTemplate>
            <LiveEventLog />
          </AuthTemplate>
        ),
      },
      {
        path: ROUTER_PATHS.graphiql,
        element: (
          <AuthTemplate>
            <GraphiQL />
          </AuthTemplate>
        ),
      },
    ],
  },
]);
