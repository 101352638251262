import Box from "@mui/material/Box";
import {
  CSSObject,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import IconSideEye from "../../../components/icons/IconSideEye.tsx";
import IconSide from "../../../components/icons/IconSide.tsx";
import IconHideLeft from "../../../components/icons/IconHideLeft.tsx";
import {menuItems} from "../navigation.tsx";
import {AgentsSummary} from "./AgentsSummary.tsx";
import React from "react";
import {styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {MenuItem} from "./MenuItem.tsx";
import {useLocation} from "react-router-dom";

export const drawerWidth = 200;
const closedDrawerWidth = 88;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedDrawerWidth,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export type SidebarProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const Sidebar: React.FC<SidebarProps> = ({open, setOpen}) => {
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open} style={{zIndex: 3}}>
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ListItemIcon onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {open ? (
            <div style={{display: "flex", alignItems: "center", gap: 2}}>
              <IconSideEye /> <IconSide />
            </div>
          ) : (
            <IconSideEye />
          )}
        </ListItemIcon>
      </Box>

      <Box>
        <ListItem
          sx={{cursor: "pointer", alignItems: "center"}}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          <Stack
            direction="row"
            sx={{transform: open ? "none" : "scaleX(-1)"}}
            alignItems="center"
          >
            <IconHideLeft />
            {open && (
              <Typography variant="body2" color="#666468">
                Hide
              </Typography>
            )}
          </Stack>
        </ListItem>
      </Box>

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
        key={location.pathname}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.name} item={item} open={open} />
        ))}
      </List>

      <AgentsSummary isOpen={open} />
    </Drawer>
  );
};
