import {Button, styled, Tooltip, tooltipClasses} from "@mui/material";
import {ReactElement} from "react";
import {TreeNode} from "./FlamegraphData.ts";
import {FlamegraphState} from "@components/Flamegraph/FlamegraphState.ts";

export function FlamegraphTooltipContent({
  node,
  state,
}: {
  node: TreeNode;
  state: FlamegraphState;
}) {
  const {root} = state;
  return (
    <div>
      <p>
        {node.value} goroutines (
        {(100 * (node.value / root.value)).toFixed(2) + " %"})
      </p>
      <p>Full&nbsp;name:&nbsp;{node.complete}</p>
      {node.file && (
        <p>
          File:&nbsp;{node.file}:{node.line}
        </p>
      )}
      {state.hiddenNodes.has(node) ? (
        <Button onClick={() => state.unhideNode(node)}>Unhide</Button>
      ) : (
        <Button onClick={() => state.hideNode(node)}>Hide</Button>
      )}
      <Button onClick={() => state.setSelectedFrame(node)}>Variables</Button>
    </div>
  );
}

export function FlamegraphTooltipMultiContent({
  nodes,
  state,
}: {
  nodes: Array<TreeNode>;
  state: FlamegraphState;
}) {
  const isHidden = (node: TreeNode) => state.hiddenNodes.has(node);
  const numHiddenNodes = nodes.filter(isHidden).length;
  const hiddenNodesFirst = nodes.sort(
    (a, b) => Number(isHidden(b)) - Number(isHidden(a)),
  );

  return (
    <div>
      <div>
        {nodes.length} subentries
        {numHiddenNodes > 0 && <>, {numHiddenNodes} hidden</>}
      </div>
      {hiddenNodesFirst.map((node) => (
        <div
          style={{
            border: "1px solid gray",
            cursor: "pointer",
            pointerEvents: "all",
          }}
          key={"node-" + node.hash}
        >
          <FlamegraphTooltipContent node={node} state={state} />
        </div>
      ))}
    </div>
  );
}

interface FlamegraphTooltipProps {
  className?: string;
  entry: TreeNode | Array<TreeNode>;
  children: ReactElement;
  state: FlamegraphState;
}

// The purpose of this class is only to have a way to style the tooltip
// component.
export const FlamegraphTooltip = styled(
  ({className, entry, children, state, ...props}: FlamegraphTooltipProps) => {
    const content = Array.isArray(entry) ? (
      <FlamegraphTooltipMultiContent nodes={entry} state={state} />
    ) : (
      <FlamegraphTooltipContent node={entry} state={state} />
    );

    return (
      <Tooltip
        disableTouchListener
        title={content}
        classes={{popper: className}}
        {...props}
      >
        {children}
      </Tooltip>
    );
  },
)({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#1F1D24",
    color: "#F3F3F3",
    border: "1px solid black",
  },
});
