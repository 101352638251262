import React from "react";
import {Stack} from "@mui/material";
import {SelectorBinary} from "@components/SelectorBinary.tsx";
import {AddFunctionSpecCard} from "./AddFunctionSpecCard.tsx";
import {AddTypeSpecCard} from "./AddTypeSpecCard.tsx";

type Props = {
  binaryID?: string;
  setBinaryID: (id: string) => void;
};

// need to be unified filter for funcs and types both
export const AddFunctionOrType: React.FC<Props> = ({binaryID, setBinaryID}) => {
  return (
    <Stack direction="row" gap={2} my={3} alignItems="start" flexWrap="wrap">
      <SelectorBinary binaryID={binaryID} setBinaryID={setBinaryID} />
      {binaryID && (
        <Stack gap={1} flexGrow={1}>
          <AddFunctionSpecCard binaryID={binaryID} />
          <AddTypeSpecCard binaryID={binaryID} />
        </Stack>
      )}
    </Stack>
  );
};
