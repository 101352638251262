import React, {memo} from "react";
import {
  Box,
  Card,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {GoogleButton} from "./GoogleButton.tsx";
import {IconCheck} from "@components/icons/IconCheck.tsx";
import {IconWelcomeScreen} from "@components/icons/IconWelcomeScreen.tsx";
import {TypographyProps} from "@mui/material/Typography/Typography";

const listText = [
  {
    text: "A toy gRPC {link0}.",
    links: [
      {
        text: "client/server",
        url: "https://demo.app.side-eye.io/",
      },
    ],
  },
  {
    text: "A {link0} running the TPCC workload",
    links: [
      {
        text: "CockroachDB database cluster",
        url: "https://cockroach-demo.app.side-eye.io/",
      },
    ],
  },
  {
    text: "The same toy gRPC {link0}, starting with no instrumentation. This is meant to be used while following our {link1}.",
    links: [
      {
        text: "client/server",
        url: "https://demo-raw.app.side-eye.io/",
      },
      {
        text: "guided walk-through",
        url: "https://docs.side-eye.io/walkthrough/",
      },
    ],
  },
];

const ListText = ({item}) => {
  return (
    <>
      {item.text.split(/(\{link\d+})/).map((part, i) => {
        const match = part.match(/\{link(\d+)}/);

        if (match) {
          const linkIndex = parseInt(match[1], 10);
          const link = item.links[linkIndex];
          return (
            <Link href={link.url} target="_blank" key={i}>
              {link.text}
            </Link>
          );
        }

        return part;
      })}
    </>
  );
};

const StyledCard = styled(Card)(() => ({
  padding: "80px 40px",
  maxWidth: "688px",
  textAlign: "center",
}));

export const Welcome: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1268));

  const Title = memo(() => (
    <Typography variant="h1" fontSize={48} fontWeight={500}>
      Welcome to Side-eye!
    </Typography>
  ));

  const Subtitle = memo((props: TypographyProps) => (
    <Typography variant="subtitle2" color="textSecondary" {...props}>
      Please sign in to your Side-eye account
    </Typography>
  ));

  const Info = memo(() => (
    <Stack alignItems="stretch">
      <Divider sx={{my: 5}}>
        Or, try one of our demo sandboxes anonymously
      </Divider>
      <List>
        {listText.map((item, index) => (
          <ListItem key={index} sx={{px: 0}}>
            <ListItemIcon
              sx={{minWidth: 0, mr: 2, alignSelf: "start", mt: 0.5}}
            >
              <IconCheck color={theme.palette.primary.main} />
            </ListItemIcon>
            <ListItemText>
              <ListText item={item} />
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Stack>
  ));

  if (!matches) {
    return (
      <Stack alignItems="center">
        <StyledCard>
          <Title />
          <Subtitle my={3} />
          <Box>
            <GoogleButton />
          </Box>
          <Info />
          <IconWelcomeScreen />
        </StyledCard>
      </Stack>
    );
  }

  return (
    <Stack flexDirection="row" justifyContent="center" rowGap={4}>
      <StyledCard
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.dark,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Title />
        <Subtitle mt={2.6} mb={12} />
        <IconWelcomeScreen />
      </StyledCard>

      <StyledCard
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Box>
          <GoogleButton />
        </Box>
        <Info />
      </StyledCard>
    </Stack>
  );
};
