import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiPaper = (): Pick<Components<typeof theme>, "MuiPaper"> => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        backgroundImage: "none",
      },
    },
  },
});
