import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {createFilterOptions, debounce} from "@mui/material";
import {useApolloClient} from "@apollo/client";
import {gql} from "@graphql/gql.ts";
import {useCallback, useEffect, useState} from "react";

const LIST_TYPES_FROM_BINARY = gql(`
    query ListTypesFromBinary($binaryID: String!, $filter: String, $limit: Int) {
      listTypesFromBinary(binaryID: $binaryID, filter: $filter, limit: $limit)
    }
`);

type TypesAutocompleteProps = {
  binaryID: string;
  value: string | null;
  onValueChange: (string) => void;
};

const filterOptions = createFilterOptions({
  limit: 50,
});

export default function TypesAutocomplete(props: TypesAutocompleteProps) {
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const client = useApolloClient();

  const fetchOptions = useCallback(
    debounce(
      (request: {input: string}, callback: (results?: string[]) => void) => {
        console.log("fetching types with limit and filter", request.input);
        client
          .query({
            query: LIST_TYPES_FROM_BINARY,
            variables: {
              binaryID: props.binaryID,
              filter: request.input,
              limit: 50,
            },
          })
          .then((res) => {
            callback(res.data.listTypesFromBinary);
          });
      },
      400,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetchOptions({input: inputValue}, (results?: string[]) => {
      if (!active) {
        return;
      }
      let newOptions = [] as string[];
      if (results) {
        newOptions = [...results];
      }
      setOptions(newOptions);
    });
    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          placeholder={`Add new type spec`}
        />
      )}
      inputValue={inputValue}
      value={props.value}
      noOptionsText={inputValue ? "No types found" : "Type to search"}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue, reason) => {
        props.onValueChange(newValue);
      }}
    />
  );
}
