import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";
import ProcessFriendlyNameComposer from "../Spec/components/ProcessFriendlyNameComposer.tsx";
import {useParams, useSearchParams} from "react-router-dom";

export default function Program() {
  const [queryParams, setQueryParams] = useSearchParams();
  const {program} = useParams();

  return (
    <Card>
      <CardHeader title={<>Friendly name for {program} processes</>} />
      <CardContent>
        <ProcessFriendlyNameComposer program={program!} />
      </CardContent>
    </Card>
  );
}
