import {MenuItem, Stack, TextField} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle.tsx";
import React from "react";
import {ProcessHierarchy} from "../helpers/processHierarchy.class.ts";
import {NO_ENV_NAME} from "../../../constants/no-env-name.ts";

type Props = {
  selectedEnv: string;
  onEnvironmentSelectionChange: (newEnv: string | undefined) => void;
  report: ProcessHierarchy;
};

export const ProcessSelectorEnvironment: React.FC<Props> = ({
  selectedEnv,
  onEnvironmentSelectionChange,
  report,
}) => {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <TextField
        select
        label={"Environment"}
        sx={{minWidth: "10em"}}
        fullWidth
        value={selectedEnv == "none" ? "" : selectedEnv}
        onChange={(e) => {
          const newValue =
            e.target.value == NO_ENV_NAME ? undefined : e.target.value;
          onEnvironmentSelectionChange(newValue);
        }}
      >
        {Array.from(report.environments.keys()).map((env) => (
          <MenuItem key={env ?? NO_ENV_NAME} value={env ?? NO_ENV_NAME}>
            {env ?? NO_ENV_NAME}
          </MenuItem>
        ))}
      </TextField>
      <HelpCircle
        tip={
          `Select the environment for which to capture the snapshot.\n` +
          `Environments are independent sets of machines/agents as configured ` +
          `through the --environment flag passed to the agent.`
        }
      />
    </Stack>
  );
};
