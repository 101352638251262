import React from "react";

const IconBinaries: React.FC<{color?: string}> = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.48607 14.8401C3.48607 15.2203 3.79426 15.5284 4.1744 15.5284C4.55454 15.5284 4.86273 15.2203 4.86273 14.8401V9.45945C4.86273 9.181 4.69506 8.93008 4.4378 8.82353C4.18073 8.71706 3.88457 8.77589 3.6877 8.97276L1.94356 10.7169C1.67474 10.9857 1.67474 11.4216 1.94356 11.6903C2.21237 11.9591 2.64823 11.9591 2.91695 11.6903L3.48607 11.1212V14.8401Z"
        fill={color || "#47454A"}
      />
      <path
        d="M7.55147 2.91918L8.12059 2.35007V6.069C8.12059 6.44914 8.42878 6.75733 8.80892 6.75733C9.18907 6.75733 9.49726 6.44914 9.49726 6.069V0.688336C9.49726 0.409882 9.32958 0.158962 9.07232 0.0524075C8.81525 -0.0540548 8.51909 0.00477474 8.32222 0.201638L6.57808 1.94578C6.30926 2.2146 6.30926 2.65045 6.57808 2.91918C6.8469 3.188 7.28275 3.188 7.55147 2.91918Z"
        fill={color || "#47454A"}
      />
      <path
        d="M15.4017 8.82353C15.1446 8.71706 14.8484 8.77589 14.6516 8.97276L12.9074 10.7169C12.6386 10.9857 12.6386 11.4216 12.9074 11.6903C13.1762 11.9591 13.6121 11.9591 13.8808 11.6903L14.4499 11.1212V14.8401C14.4499 15.2203 14.7581 15.5284 15.1383 15.5284C15.5184 15.5284 15.8266 15.2203 15.8266 14.8401V9.45945C15.8266 9.18109 15.6589 8.93008 15.4017 8.82353Z"
        fill={color || "#47454A"}
      />
      <path
        d="M12.41 17.2951C12.1529 17.1886 11.8567 17.2474 11.6599 17.4443L9.91572 19.1885C9.64691 19.4573 9.64691 19.8931 9.91572 20.1618C10.1845 20.4307 10.6204 20.4307 10.8891 20.1618L11.4582 19.5927V21.6299C11.4582 22.0101 11.7664 22.3183 12.1466 22.3183C12.5267 22.3183 12.8349 22.0101 12.8349 21.6299V17.931C12.8349 17.6525 12.6672 17.4016 12.41 17.2951Z"
        fill={color || "#47454A"}
      />
      <path
        d="M11.2432 13.1628V11.1369C11.2432 9.83244 10.182 8.77121 8.87756 8.77121H8.84369C7.53925 8.77121 6.47803 9.83244 6.47803 11.1369V13.1628C6.47803 14.4672 7.53925 15.5284 8.84369 15.5284H8.87756C10.182 15.5284 11.2432 14.4672 11.2432 13.1628ZM9.86656 13.1628C9.86656 13.7081 9.4229 14.1518 8.87756 14.1518H8.84369C8.29835 14.1518 7.8547 13.7081 7.8547 13.1628V11.1369C7.8547 10.5915 8.29835 10.1479 8.84369 10.1479H8.87756C9.4229 10.1479 9.86656 10.5915 9.86656 11.1369V13.1628Z"
        fill={color || "#47454A"}
      />
      <path
        d="M19.8925 8.77112H19.8586C18.5541 8.77112 17.4929 9.83235 17.4929 11.1368V13.1627C17.4929 14.4671 18.5541 15.5284 19.8586 15.5284H19.8925C21.1969 15.5284 22.2581 14.4671 22.2581 13.1627V11.1368C22.2581 9.83244 21.1969 8.77112 19.8925 8.77112ZM20.8814 13.1628C20.8814 13.7081 20.4378 14.1518 19.8925 14.1518H19.8586C19.3132 14.1518 18.8696 13.7081 18.8696 13.1628V11.1369C18.8696 10.5915 19.3132 10.1479 19.8586 10.1479H19.8925C20.4378 10.1479 20.8814 10.5915 20.8814 11.1369V13.1628Z"
        fill={color || "#47454A"}
      />
      <path
        d="M13.5118 0H13.478C12.1735 0 11.1123 1.06123 11.1123 2.36567V4.39157C11.1123 5.69601 12.1735 6.75724 13.478 6.75724H13.5118C13.892 6.75724 14.2002 6.44905 14.2002 6.0689C14.2002 5.68876 13.892 5.38057 13.5118 5.38057H13.478C12.9326 5.38057 12.489 4.93691 12.489 4.39157V2.36567C12.489 1.82032 12.9326 1.37667 13.478 1.37667H13.5118C14.0572 1.37667 14.5008 1.82032 14.5008 2.36567C14.5008 2.74581 14.809 3.054 15.1892 3.054C15.5693 3.054 15.8775 2.74581 15.8775 2.36567C15.8776 1.06123 14.8164 0 13.5118 0Z"
        fill={color || "#47454A"}
      />
      <path
        d="M19.8925 0H19.8586C18.5541 0 17.4929 1.06123 17.4929 2.36567V4.39157C17.4929 5.69601 18.5541 6.75724 19.8586 6.75724H19.8925C21.1969 6.75724 22.2581 5.69601 22.2581 4.39157V2.36567C22.2581 1.06123 21.1969 0 19.8925 0ZM20.8814 4.39166C20.8814 4.93701 20.4378 5.38066 19.8925 5.38066H19.8586C19.3132 5.38066 18.8696 4.93701 18.8696 4.39166V2.36567C18.8696 1.82032 19.3132 1.37667 19.8586 1.37667H19.8925C20.4378 1.37667 20.8814 1.82032 20.8814 2.36567V4.39166Z"
        fill={color || "#47454A"}
      />
      <path
        d="M5.66101 17.2427H5.62714C4.3227 17.2427 3.26147 18.3039 3.26147 19.6083V21.6342C3.26147 22.9387 4.3227 23.9999 5.62714 23.9999H5.66101C6.96545 23.9999 8.02667 22.9387 8.02667 21.6342V19.6083C8.02676 18.304 6.96554 17.2427 5.66101 17.2427ZM6.6501 21.6343C6.6501 22.1797 6.20644 22.6233 5.6611 22.6233H5.62723C5.08189 22.6233 4.63823 22.1797 4.63823 21.6343V19.6083C4.63823 19.063 5.08189 18.6193 5.62723 18.6193H5.6611C6.20644 18.6193 6.6501 19.063 6.6501 19.6083V21.6343Z"
        fill={color || "#47454A"}
      />
    </svg>
  );
};

export default IconBinaries;
