import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiTooltip = (): Pick<Components<typeof theme>, "MuiTooltip"> => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        whiteSpace: "pre-wrap",
      },
    },
  },
});
