import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiInputBase = (): Pick<
  Components<typeof theme>,
  "MuiInputBase"
> => ({
  MuiInputBase: {
    styleOverrides: {
      root: ({theme}) => ({
        "&.MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
        boxShadow: "none",
        ":not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiInputBase-input": {
          fontSize: 14,
          lineHeight: "20px",
          padding: "14px 16px",
          fontWeight: 500,
        },
        ".MuiInputBase-input::placeholder": {
          fontSize: 14,
          fontWeight: 500,
        },
        "&.Mui-error .MuiInputBase-input::placeholder": {
          opacity: 1,
          color: theme.palette.error.main,
        },
      }),
    },
    variants: [
      {
        props: {color: "primary"},
        style: ({theme}) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
      {
        props: {color: "secondary"},
        style: ({theme}) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      },
      {
        props: {size: "small"},
        style: {
          height: 40,
        },
      },
      {
        props: {size: "medium"},
        style: {
          height: 48,
        },
      },
    ],
  },
});
