import React, {createContext, PropsWithChildren, useContext} from "react";
import {ProcessResolver} from "@util/process-resolver.ts";

const ProcessesResolverContext = createContext(new ProcessResolver([], []));

export const ProcessResolverProvider: React.FC<
  PropsWithChildren & {value: ProcessResolver}
> = ({children, value}) => {
  return (
    <ProcessesResolverContext.Provider value={value}>
      {children}
    </ProcessesResolverContext.Provider>
  );
};

export const useProcessResolver = () => {
  const processResolver = useContext(ProcessesResolverContext);
  return processResolver;
};
