import {Auth, signInWithCustomToken, User} from "firebase/auth";
import {OrgInfo} from "../__generated__/graphql.ts";
import React, {useEffect, useState} from "react";
import {useApolloClient, useQuery} from "@apollo/client";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {gql} from "../__generated__";
import EditableLabel from "src/util/editable-label.tsx";
import {clearOrgDependentSessionStorage} from "src/util/local-storage.ts";
import {Clear, Save} from "@mui/icons-material";
import TextField from "@mui/material/TextField";

const GENERATE_TOKEN_WITH_EFFECTIVE_ORG = gql(/* GraphQL */ `
  mutation generateTokenWithEffectiveOrg($effectiveOrg: String!) {
    generateTokenWithEffectiveOrg(effectiveOrg: $effectiveOrg)
  }
`);

gql(/* GraphQL */ `
  fragment FullEphemeralOrgTemplates on EphemeralEnvironments {
    Environments {
      TemplateOrg
      Domain
    }
  }
`);

const LIST_EPHEMERAL_ENVIRONMENTS = gql(/* GraphQL */ `
  query listEphemeralEnvironments {
    listEphemeralEnvironments {
      ...FullEphemeralOrgTemplates
    }
  }
`);

const ADD_EPHEMERAL_ENVIRONMENT_TEMPLATE = gql(/* GraphQL */ `
  mutation addEphemeralEnvironment($input: EphemeralEnvironmentInput!) {
    addEphemeralEnvironment(input: $input) {
      ...FullEphemeralOrgTemplates
    }
  }
`);

const DELETE_EPHEMERAL_ENVIRONMENT_TEMPLATE = gql(/* GraphQL */ `
  mutation deleteEphemeralEnvironment($subdomain: String!) {
    deleteEphemeralEnvironment(subdomain: $subdomain) {
      ...FullEphemeralOrgTemplates
    }
  }
`);

const UPDATE_ORG_INFO = gql(/* GraphQL */ `
  mutation updateOrgInfo($input: OrgInfoInput!) {
    updateOrgInfo(input: $input) {
      orgName
      apiToken
      agentsOrgRedirect
      notes
    }
  }
`);

function EphemeralEnvironmentsTable({}) {
  const [newDomain, setNewDomain] = useState("");
  const [newTemplateOrg, setNewTemplateOrg] = useState("");
  const [runningAdd, setRunningAdd] = useState(false);

  const client = useApolloClient();
  const {
    data: ephemeralEnvironments,
    loading,
    error,
  } = useQuery(LIST_EPHEMERAL_ENVIRONMENTS);

  const addEnabled =
    !runningAdd && newDomain.length > 0 && newTemplateOrg.length > 0;

  async function addEphemeralEnvironment() {
    setRunningAdd(true);
    await client
      .mutate({
        mutation: ADD_EPHEMERAL_ENVIRONMENT_TEMPLATE,
        variables: {input: {Domain: newDomain, TemplateOrg: newTemplateOrg}},
        refetchQueries: [LIST_EPHEMERAL_ENVIRONMENTS],
      })
      .then(() => {
        setNewDomain("");
        setNewTemplateOrg("");
      })
      .finally(() => {
        setRunningAdd(false);
      });
  }

  async function removeEphemeralEnvironment(subdomain: string) {
    await client.mutate({
      mutation: DELETE_EPHEMERAL_ENVIRONMENT_TEMPLATE,
      variables: {subdomain},
      refetchQueries: [LIST_EPHEMERAL_ENVIRONMENTS],
    });
  }

  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} color="secondary">
        <TableHead>
          <TableRow>
            <TableCell>Subdomain</TableCell>
            <TableCell>Template Org</TableCell>
            <TableCell sx={{width: "40px"}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ephemeralEnvironments &&
            ephemeralEnvironments.listEphemeralEnvironments.Environments.map(
              (row) => (
                <TableRow key={row.Domain}>
                  <TableCell>{row.Domain}</TableCell>
                  <TableCell>{row.TemplateOrg}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => removeEphemeralEnvironment(row.Domain)}
                      color="info"
                    >
                      <Clear />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ),
            )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <TextField
                value={newDomain}
                disabled={runningAdd}
                color="secondary"
                onChange={(ev) => setNewDomain(ev.target.value)}
                fullWidth
                size="small"
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField
                value={newTemplateOrg}
                disabled={runningAdd}
                color="secondary"
                onChange={(ev) => setNewTemplateOrg(ev.target.value)}
                fullWidth
                size="small"
              ></TextField>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={addEphemeralEnvironment}
                disabled={!addEnabled}
                color="primary"
              >
                <Save />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export interface SuperUserProps {
  user: User;
  orgInfo: OrgInfo;
  refetchOrgInfo: () => void;
  auth: Auth;
}

export default function SuperUser({
  user,
  orgInfo,
  refetchOrgInfo,
  auth,
}: SuperUserProps) {
  const [impersonateOrg, setImpersonateOrg] = React.useState("");
  const client = useApolloClient();

  // Reading the user's token is an async operation, so we need to use an effect
  // to synchronize it the component state.
  useEffect(() => {
    user.getIdTokenResult().then((token) => {
      const effectiveOrg = token.claims.effective_org as string;
      setImpersonateOrg(effectiveOrg ?? "");
    });
  }, [user]);

  async function saveImpersonateOrg(
    orgToImpersonate: string,
  ): Promise<boolean> {
    // When the impersonated org changes, we need to generate a new auth token,
    // then sign in the user with the new token.
    const {data} = await client.mutate({
      mutation: GENERATE_TOKEN_WITH_EFFECTIVE_ORG,
      variables: {effectiveOrg: orgToImpersonate},
    });
    const newToken = data?.generateTokenWithEffectiveOrg;
    await signInWithCustomToken(auth, newToken!);

    // Reset the Apollo cache.
    await client.clearStore();
    clearOrgDependentSessionStorage();

    // Refetch the org query, which will now return info for the effective org.
    refetchOrgInfo();

    return true;
  }

  async function saveAgentsOrg(newOrg: string): Promise<boolean> {
    await client.mutate({
      mutation: UPDATE_ORG_INFO,
      variables: {
        input: {agentsOrgRedirect: newOrg || null, orgName: orgInfo.orgName},
      },
    });
    return true;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" sx={{mb: 2}}>
          Superuser
        </Typography>
        <EditableLabel
          label={"Impersonate org"}
          text={impersonateOrg}
          allowEmpty={true}
          emptyText={"none"}
          onSave={saveImpersonateOrg}
          size={"small"}
        />
        <EditableLabel
          label={"Agents org"}
          text={orgInfo.agentsOrgRedirect ?? ""}
          allowEmpty={true}
          emptyText={"none"}
          onSave={saveAgentsOrg}
          size={"small"}
        />

        <Divider sx={{my: 3}} />

        <EphemeralEnvironmentsTable />
      </CardContent>
    </Card>
  );
}
