import {BarChart} from "@mui/x-charts";
import React from "react";
import {formatDurationNanos} from "@util/util.ts";
import {Histogram} from "@graphql/graphql.ts";

// DurationHistogramChart generates a bar chart representing a latency
// histogram.
export default function DurationHistogramChart({
  histogram,
}: {
  histogram: Histogram;
}): React.JSX.Element {
  const numBuckets = 30;
  const bucketDurationNanos =
    (histogram.maxNanos - histogram.minNanos) / numBuckets;

  const bucketNames: string[] = [];
  const bucketValues: number[] = [];
  let bucketStartNanos = histogram.minNanos;
  for (const count of histogram.counts) {
    bucketNames.push(formatDurationNanos(bucketStartNanos));
    bucketValues.push(count);
    bucketStartNanos += bucketDurationNanos;
  }
  //!!! bucketNames.push(formatDurationNanos(bucketStartNanos));

  // The histogram labels seem to need to be unique, otherwise the bar chart
  // doesn't render correctly (see
  // https://discord.com/channels/1131323012554174485/1131329761952682114/1285170460887224351).
  // We'll strip the parens in valueFormatter.
  const namesCount = new Map<string, number>();
  const bucketNamesDeduped: string[] = [];
  for (const bucket of bucketNames) {
    const count = namesCount.get(bucket) || 0;
    let name = bucket;
    if (count > 0) {
      name = bucket + `(${count})`;
    }
    namesCount.set(bucket, count + 1);
    bucketNamesDeduped.push(name);
  }

  return (
    <BarChart
      xAxis={[
        {
          scaleType: "band",
          data: bucketNamesDeduped,
          // Strip the suffix of the bucket name that was added to make bucket names unique.
          valueFormatter: (value: string) => value.split("(")[0],
          tickPlacement: "start",
          tickLabelPlacement: "tick",
        },
      ]}
      series={[{data: bucketValues}]}
    />
  );
}
