import {useEffect} from "react";
import useSignOut from "src/util/useSignOut";

export default function Logout() {
  const signOut = useSignOut();
  useEffect(() => {
    signOut();
  }, [signOut]);
  return <>Signing out</>;
}
