import {gql} from "../../__generated__";

export const DELETE_MODULE = gql(/* GraphQL */ `
  mutation DeleteModule($pkgPath: String!) {
    deleteModule(pkgPath: $pkgPath) {
      ...FullSnapshotSpec
    }
  }
`);

export const VALIDATE_MODULE_DELETION = gql(/* GraphQL */ `
  query ValidateModuleDeletion($pkgPath: String!) {
    validateModuleDeletion(pkgPath: $pkgPath) {
      newlyFailingTables
      newlyFailingLinks {
        srcTableName
        destTableName
      }
    }
  }
`);

export const MOVE_MODULE_TO_INDEX = gql(/* GraphQL */ `
  mutation MoveModuleToIndex($pkgPath: String!, $newIdx: Int!) {
    moveModuleToIndex(pkgPath: $pkgPath, newIdx: $newIdx) {
      ...FullSnapshotSpec
    }
  }
`);

export const MOVE_FUNCTION_TO_INDEX = gql(/* GraphQL */ `
  mutation MoveFunctionToIndex($funcQualifiedName: String!, $newIdx: Int!) {
    moveFunctionToIndex(
      funcQualifiedName: $funcQualifiedName
      newIdx: $newIdx
    ) {
      ...FullSnapshotSpec
    }
  }
`);

export const DOWNLOAD_SPEC = gql(/* GraphQL */ `
  query DownloadSpec {
    downloadSpec
  }
`);

export const OVERWRITE_SPEC = gql(/* GraphQL */ `
  mutation OverwriteSpec($spec: String!) {
    overwriteSpec(input: $spec) {
      ...FullSnapshotSpec
    }
  }
`);

export const ADD_OR_UPDATE_LINK = gql(/* GraphQL */ `
  mutation addOrUpdateLink($input: LinkSpecInput!) {
    addOrUpdateLink(input: $input) {
      ...FullSnapshotSpec
    }
  }
`);

export const DELETE_LINK = gql(/* GraphQL */ `
  mutation deleteLink($id: String!) {
    deleteLink(id: $id) {
      ...FullSnapshotSpec
    }
  }
`);

export const ADD_OR_UPDATE_PROGRAM_SPEC = gql(/* GraphQL */ `
  mutation addOrUpdateProgramSpec($input: ProgramSpecInput!) {
    addOrUpdateProgramSpec(input: $input) {
      ...FullSnapshotSpec
    }
  }
`);

export const DELETE_PROGRAM_SPEC = gql(/* GraphQL */ `
  mutation deleteProgramSpec($program: String!) {
    deleteProgramSpec(program: $program) {
      ...FullSnapshotSpec
    }
  }
`);
