export const IconExit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59682 7.48659C6.30515 7.48659 6.07453 7.71325 6.07453 7.99992C6.07453 8.27992 6.30515 8.51325 6.59682 8.51325H10.6666V11.6999C10.6666 13.3333 9.31678 14.6666 7.64817 14.6666H4.34488C2.68306 14.6666 1.33325 13.3399 1.33325 11.7066V4.29992C1.33325 2.65992 2.68984 1.33325 4.35166 1.33325H7.66174C9.31678 1.33325 10.6666 2.65992 10.6666 4.29325V7.48659H6.59682ZM13.0867 5.69339L15.0334 7.63339C15.1334 7.73339 15.1867 7.86005 15.1867 8.00005C15.1867 8.13339 15.1334 8.26672 15.0334 8.36005L13.0867 10.3001C12.9867 10.4001 12.8534 10.4534 12.7267 10.4534C12.5934 10.4534 12.4601 10.4001 12.3601 10.3001C12.1601 10.1001 12.1601 9.77339 12.3601 9.57339L13.4267 8.51339H10.6667V7.48672H13.4267L12.3601 6.42672C12.1601 6.22672 12.1601 5.90005 12.3601 5.70005C12.5601 5.49339 12.8867 5.49339 13.0867 5.69339Z"
        fill="white"
      />
    </svg>
  );
};
