import React, {useContext, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {getTypeSpec} from "@util/spec.ts";
import {useBinarySelectionDialog} from "@providers/binary-selection-dialog.tsx";
import {ADD_OR_UPDATE_TYPE_SPEC} from "@components/available-vars.tsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import FunctionOrTypeSpecDrawer from "@components/function-or-type-spec-drawer.tsx";
import MissingTypes from "./MissingTypes.tsx";
import ModuleName from "./ModuleName.tsx";
import {AddFunctionOrType} from "./AddFunctionOrType.tsx";
import {SpecContext} from "@providers/spec-provider.tsx";
import {DeleteModuleButton} from "./DeleteModuleButton.tsx";
import {ModuleTypesSpec} from "./ModuleTypesSpec.tsx";
import {ModuleFunctionsList} from "./ModuleFunctionsList.tsx";
import DraggableList from "@components/DraggableList.tsx";
import {MOVE_MODULE_TO_INDEX} from "../gqlHelpers.ts";
import {DropResult} from "@hello-pangea/dnd";
import IconCode from "../../../assets/icons/IconCode.svg";

// ModulesEditor is the editor for the modules of the spec. It is rendered in
// the "Data collection" tab of the SnapshotSpecEditor.
export function ModulesEditor(): React.JSX.Element {
  const spec = useContext(SpecContext);
  const client = useApolloClient();

  const [binaryID, setBinaryID] = useState<string | undefined>(undefined);

  // The name of the type whose spec is currently being edited in the drawer. If
  // undefined, the drawer is closed. Note that, if typeSpecDrawerState /
  // editingTypeSpec are set, then binaryID is also set.
  const [typeSpecDrawerState, setTypeSpecDrawerState] = useState<
    string | undefined
  >(undefined);
  const editingTypeSpec =
    typeSpecDrawerState == undefined
      ? undefined
      : getTypeSpec(spec, typeSpecDrawerState);

  const showBinarySelectionDialog = useBinarySelectionDialog();

  async function promptForBinarySelection(): Promise<string | undefined> {
    const binaryID = await showBinarySelectionDialog();
    if (binaryID == undefined) {
      return undefined;
    }
    setBinaryID(binaryID);
    return binaryID;
  }

  async function onEditTypeClick(typeName: string) {
    let binID = binaryID;
    if (!binID) {
      binID = await promptForBinarySelection();
      if (binID == undefined) {
        return;
      }
    }
    setTypeSpecDrawerState(typeName);
  }

  // onAddAndEditType is called when the user wants to add a type spec and
  // immediately edit in the drawer. A type spec is created that does not
  // collect anything. This is different from adding a type spec without
  // expressing the intent to edit it immediately; in these cases the type spec
  // is set to collect everything.
  async function onAddAndEditType(typeName: string) {
    let binID = binaryID;
    if (!binID) {
      binID = await promptForBinarySelection();
      if (binID == undefined) {
        return;
      }
    }

    const {errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_TYPE_SPEC,
      variables: {
        input: {
          typeQualifiedName: typeName,
          collectExprs: [],
          // Note that we do not collect anything.
          collectAll: false,
        },
      },
    });
    if (errors) {
      console.error("failed to add type spec", errors);
    } else {
      setTypeSpecDrawerState(typeName);
    }
  }

  const onDragEnd = ({destination, source}: DropResult) => {
    // Dropped outside the list.
    if (!destination) return;
    if (source.index == destination.index) {
      // Nothing to do.
      return;
    }

    const m = spec.modules[source.index];

    void client.mutate({
      mutation: MOVE_MODULE_TO_INDEX,
      variables: {pkgPath: m.pkgPath, newIdx: destination.index},
      optimisticResponse: () => {
        const reordered = Array.from(spec.modules);
        reordered.splice(source.index, 1);
        reordered.splice(destination.index, 0, m);
        return {
          moveModuleToIndex: {
            ...spec,
            modules: [...reordered],
          },
        };
      },
    });
  };

  return (
    <Stack sx={{pb: 4}}>
      <AddFunctionOrType binaryID={binaryID} setBinaryID={setBinaryID} />

      <Stack
        display="grid"
        gridTemplateColumns="minmax(1000px, 1fr)"
        gridAutoColumns="minmax(400px, 600px)"
        gridAutoFlow="column"
        gap={2}
      >
        <DraggableList
          draggableIdKey="pkgPath"
          items={spec.modules}
          onDragEnd={onDragEnd}
          renderItem={(module, index) => (
            <Accordion
              key={module.pkgPath + `_` + index}
              sx={{flexGrow: 1}}
              slotProps={{transition: {unmountOnExit: true}}}
            >
              <AccordionSummary>
                <Stack
                  display="grid"
                  gridTemplateColumns="30px minmax(200px, 1fr) 40px"
                  gap={2}
                  alignItems="center"
                  flexGrow={1}
                >
                  <img src={IconCode} alt="module" />

                  <ModuleName module={module} />

                  <DeleteModuleButton module={module} />
                </Stack>
              </AccordionSummary>

              <AccordionDetails sx={{display: "grid", gap: 3}}>
                <ModuleFunctionsList
                  binaryID={binaryID}
                  module={module}
                  promptForBinarySelection={() => {
                    void promptForBinarySelection();
                  }}
                />

                <ModuleTypesSpec
                  module={module}
                  onEditTypeClick={(typeName: string) => {
                    void onEditTypeClick(typeName);
                  }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        />

        <MissingTypes
          spec={spec}
          onEditTypeClick={(arg) => void onAddAndEditType(arg)}
        />
      </Stack>

      {editingTypeSpec && (
        <FunctionOrTypeSpecDrawer
          binaryID={binaryID!}
          showDeleteButton={true}
          display={editingTypeSpec}
          onClose={() => setTypeSpecDrawerState(undefined)}
        />
      )}
    </Stack>
  );
}
