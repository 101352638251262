import {
  FilterNodesByIds,
  FlamegraphWidthStyle,
  GetNodeIds,
  TreeNode,
} from "@components/Flamegraph/FlamegraphData.ts";
import {SnapshotState} from "@providers/snapshot-state.tsx";
import {FilteringOptionType} from "@graphql/graphql.ts";

// All the nodes in a focused set have to be at the same depth.
export type FocusedNodeType = TreeNode | Array<TreeNode>;
export type NodeSelector = (node: TreeNode) => void;

// Right now the focused entry info is in two place, since the autocomplete
// input also needs it. It should probably be merged when it's more clear
// how to display multiple focused nodes in the input.
export function GetFocusedNodesFromState(
  root: TreeNode,
  snapshotState: SnapshotState,
): FocusedNodeType {
  const {filters} = snapshotState.state;
  const stackPrefixFilter = filters.find(
    (f) => f.Type == FilteringOptionType.StackPrefix,
  );
  const binaryFilter = filters.find(
    (f) => f.Type == FilteringOptionType.BinaryId,
  );

  if (stackPrefixFilter) {
    const node = root.findNode(
      stackPrefixFilter.BinaryID!,
      stackPrefixFilter.StackPrefix!.split(",").map(Number),
    );
    if (!node) {
      // It's possible that the stack prefix filter is incompatible with the
      // current nodes. For example, the stack prefix could have come from
      // clicking on a flamegraph node when visualizing a different process'
      // snapshot, and that stack doesn't exist in snapshot of the process that
      // we're currently visualizing. In such a case, don't display any nodes in
      // the flamegraph.
      // TODO(mihai): this should be an explicit error probably
      console.warn("The focused node is probably from another process");
      return root;
    }
    return node;
  }

  if (binaryFilter) {
    return root.findNode(binaryFilter.BinaryID!) || root;
  }
  const nodes = FilterNodesByIds(root, snapshotState.state.focusedNodes ?? []);
  if (nodes.length) {
    if (nodes.length === 1) {
      return nodes[0];
    }
    return nodes;
  }
  return root;
}

// Used to group various class to change the state that need to be passed to the
// Flamegraph.
export class FlamegraphState {
  root: TreeNode;
  snapshotState: SnapshotState;
  focusedEntry: FocusedNodeType; // If undefined, we'll focus on the root.
  setSelectedFrame: NodeSelector;
  hiddenNodes: Set<TreeNode>;
  focusedNodes: Set<TreeNode>;
  highlighterFilter: string;
  widthStyle: FlamegraphWidthStyle;
  setWidthStyle: (value: FlamegraphWidthStyle) => void;

  constructor(
    root: TreeNode,
    snapshotState: SnapshotState,
    setSelectedFrame: NodeSelector,
    highlighterFilter: string,
    widthStyle: FlamegraphWidthStyle,
    setWidthStyle: (value: FlamegraphWidthStyle) => void,
  ) {
    this.root = root;
    this.snapshotState = snapshotState;
    this.focusedEntry = GetFocusedNodesFromState(root, snapshotState);
    this.hiddenNodes = new Set(
      FilterNodesByIds(root, snapshotState.state.hiddenNodes ?? []),
    );

    this.focusedNodes = new Set(
      Array.isArray(this.focusedEntry)
        ? this.focusedEntry
        : [this.focusedEntry],
    );
    this.setSelectedFrame = setSelectedFrame;
    this.highlighterFilter = highlighterFilter;
    this.widthStyle = widthStyle;
    this.setWidthStyle = setWidthStyle;
  }

  setFocusedEntry(node: FocusedNodeType | undefined) {
    const {snapshotState} = this;
    const NODE_FOCUS_TYPES = [
      FilteringOptionType.BinaryId,
      FilteringOptionType.StackPrefix,
    ];

    if (Array.isArray(node) && node.length === 1) {
      // Single focus is saved differently
      node = node[0];
    }

    if (Array.isArray(node)) {
      snapshotState.overwriteFilters([], NODE_FOCUS_TYPES, {
        focusedNodes: GetNodeIds(node),
      });
      return;
    }

    if (!node?.binaryID || node === this.root) {
      // Either we're in the root or no node is selected
      snapshotState.overwriteFilters([], NODE_FOCUS_TYPES, {focusedNodes: []});
      return;
    }

    if (node.pc !== undefined) {
      // User clicked on a regular node representing a stack frame.
      snapshotState.overwriteFilters(
        [
          {
            Type: FilteringOptionType.StackPrefix,
            BinaryID: node.binaryID,
            StackPrefix: node.path().join(","),
            StackPrefixLastFunc:
              (node.type != "" ? node.type + "." : "") + node.funcName,
          },
        ],
        NODE_FOCUS_TYPES,
        {focusedNodes: []},
      );
    } else {
      // User clicked on a "binary root".
      snapshotState.overwriteFilters(
        [
          {
            Type: FilteringOptionType.BinaryId,
            BinaryID: node.binaryID,
          },
        ],
        NODE_FOCUS_TYPES,
        {focusedNodes: []},
      );
    }
  }

  setHiddenNodes(nodes: Set<TreeNode>) {
    const nodeIDs = GetNodeIds([...nodes]);
    this.snapshotState.updateSearchParam({hiddenNodes: nodeIDs});
  }

  hideNode(node: TreeNode) {
    this.setHiddenNodes(new Set<TreeNode>([...this.hiddenNodes, node]));
  }

  unhideNode(node: TreeNode) {
    this.hiddenNodes.delete(node);
    this.setHiddenNodes(new Set<TreeNode>([...this.hiddenNodes]));
  }

  isSingleNodeHighlighted(
    node: TreeNode,
    includeChildren: boolean = false,
  ): boolean {
    if (!this.highlighterFilter) {
      return false;
    }
    const selfHighlighted = node.complete.includes(this.highlighterFilter);
    return (
      selfHighlighted ||
      (includeChildren && this.isAnyNodeHighlighted(node.children || []))
    );
  }

  isAnyNodeHighlighted(nodes: Array<TreeNode>): boolean {
    return (
      nodes.find((node) => this.isSingleNodeHighlighted(node, true)) != null
    );
  }

  // Ensures that even if we have a single node focused, this returns an array.
  getFocusedNodes(): Array<TreeNode> {
    const {root, focusedEntry} = this;
    return Array.isArray(focusedEntry) ? focusedEntry : [focusedEntry || root];
  }
}
