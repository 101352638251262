import {gql} from "src/__generated__";
import {ApolloClient} from "@apollo/client";
import toast from "react-hot-toast";
import {
  FunctionSpec,
  FunctionSpecInput,
  ValidateFunctionSpecUpdateQuery,
} from "src/__generated__/graphql.ts";
import {
  confirmationDialogInfo,
  toastError,
  warningMessageForTableValidationFail,
} from "src/components/tables/util.tsx";
import React from "react";
import {Typography} from "@mui/material";
import {GET_TABLES} from "@components/tables/gql.ts";

export const GET_PROGRAMS = gql(/* GraphQL */ `
  query GetPrograms {
    getPrograms
  }
`);

const DELETE_FUNCTION_SPEC = gql(/* GraphQL */ `
  mutation DeleteFunctionSpec($funcQualifiedName: String!) {
    deleteFunctionSpec(funcQualifiedName: $funcQualifiedName) {
      ...FullSnapshotSpec
    }
  }
`);

export const VALIDATE_FUNCTION_SPEC_DELETION = gql(/* GraphQL */ `
  query ValidateFunctionSpecDeletion($funcQualifiedName: String!) {
    validateFunctionSpecDeletion(funcQualifiedName: $funcQualifiedName) {
      newlyFailingTables
      newlyFailingLinks {
        srcTableName
        destTableName
      }
    }
  }
`);

// deleteFunctionSpec deletes a function spec. The user's confirmation might be
// required. On error, the error is displayed in a toast and false is returned.
// On success, returns true.
export async function deleteFunctionSpec(
  funcSpec: FunctionSpec,
  client: ApolloClient<unknown>,
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
): Promise<boolean> {
  // If the function spec contains a non-empty snapshot spec and/or a non-empty
  // event spec, ask for confirmation.
  let confirmationMsg: string | undefined = undefined;
  const hasSnapshotSpec: boolean =
    funcSpec.snapshotSpec != undefined &&
    (funcSpec.snapshotSpec.collectExprs.length > 0 ||
      funcSpec.snapshotSpec.extraColumns.length > 0);
  const hasEventSpec: boolean =
    funcSpec.functionStartEvent != undefined &&
    (funcSpec.functionStartEvent.message != "" ||
      funcSpec.functionStartEvent.collectExprs.length > 0 ||
      funcSpec.functionStartEvent.extraColumns.length > 0);
  if (hasSnapshotSpec && hasEventSpec) {
    confirmationMsg = `The function's data is included in snapshots, and events are generated when the function executes.
      Deleting it would remove both of these sources of data. Are you sure you want to delete it?`;
  } else if (hasSnapshotSpec) {
    confirmationMsg = `The function's data is included in snapshots. Deleting it
      would remove its frames table. Are you sure you want to delete it?`;
  } else if (hasEventSpec) {
    confirmationMsg = `Events are generated when the function executes. Deleting it
      would remove them. Are you sure you want to delete it?`;
  }
  if (confirmationMsg) {
    const dialogContent = (
      <>
        Are you sure you want to delete function{" "}
        {funcSpec.funcName.QualifiedName}?
        <br />
        <Typography>{confirmationMsg}</Typography>
      </>
    );
    const confirmed: boolean = await showConfirmationDialog({
      title: "Confirm function deletion",
      content: dialogContent,
    });
    if (!confirmed) {
      // User changed their mind.
      return false;
    }
  }

  // Validate the deletion. If validation fails, ask for confirmation.
  const {data: validationQueryRes} = await client.query({
    query: VALIDATE_FUNCTION_SPEC_DELETION,
    variables: {funcQualifiedName: funcSpec.funcName.QualifiedName},
    fetchPolicy: "no-cache",
  });
  const validationResult = validationQueryRes.validateFunctionSpecDeletion;
  const validationFailed =
    validationResult.newlyFailingTables.length > 0 ||
    validationResult.newlyFailingLinks.length > 0;
  if (validationFailed) {
    const dialogContent = (
      <>
        Are you sure you want to delete function{" "}
        {funcSpec.funcName.QualifiedName}?
        <br />
        <Typography>
          {warningMessageForTableValidationFail(
            "", // tableError
            validationResult.newlyFailingTables,
            validationResult.newlyFailingLinks,
          )}
        </Typography>
      </>
    );
    // If this change failed validation, show a confirmation dialog asking the
    // user to confirm that they indeed want to break this table or some other
    // tables that depend on it.
    const confirmed: boolean = await showConfirmationDialog({
      title: "Confirm function deletion",
      content: dialogContent,
    });
    if (!confirmed) {
      // User changed their mind.
      return false;
    }
  }

  const {errors} = await client.mutate({
    mutation: DELETE_FUNCTION_SPEC,
    variables: {
      funcQualifiedName: funcSpec.funcName.QualifiedName,
    },
    errorPolicy: "all",
  });
  if (errors) {
    toast.error(errors[0].message);
    return false;
  }
  return true;
}

export const DELETE_TYPE_SPEC = gql(/* GraphQL */ `
  mutation DeleteTypeSpec($typeQualifiedName: String!) {
    deleteTypeSpec(typeQualifiedName: $typeQualifiedName) {
      ...FullSnapshotSpec
      missingTypeQualifiedNames
    }
  }
`);

// DO NOT USE THIS DIRECTLY. Use addOrUpdateFunctionSpec instead.
const ADD_OR_UPDATE_FUNCTION_SPEC = gql(/* GraphQL */ `
  mutation AddOrUpdateFunctionSpec($input: FunctionSpecInput!) {
    addOrUpdateFunctionSpec(input: $input) {
      ...FullSnapshotSpec
    }
  }
`);

const VALIDATE_FUNCTION_SPEC_UPDATE = gql(/* GraphQL */ `
  query ValidateFunctionSpecUpdate($funcSpecUpdate: FunctionSpecInput!) {
    validateFunctionSpecUpdate(funcSpecUpdate: $funcSpecUpdate) {
      tableValidationError
      newlyFailingTables
      newlyFailingLinks {
        srcTableName
        destTableName
      }
    }
  }
`);

// addOrUpdateFunctionSpec adds or updates a function spec. It deals with the
// different categories of validation errors:
// - on success, it returns true.
// - on fatal errors, it displays a toast and returns false.
// - on non-fatal errors, it displays a confirmation dialog and returns true or
// false depending on whether the user confirms that change.
//
// Errors encountered performing the mutation are displayed in a toast and
// swallowed; in such cases the future resolves to false.
export async function addOrUpdateFunctionSpec(
  client: ApolloClient<unknown>,
  input: FunctionSpecInput,
  // showConfirmationDialog, if set, will cause the function spec changes to
  // first be validated. If the validation fails, the user will be presented
  // with a confirmation dialog asking them to confirm that they want to proceed
  // anyway.
  //
  // If undefined, the function spec changes will be applied without validation.
  // This is appropriate, for example, when adding an empty spec for a new
  // function.
  //
  // When showConfirmationDialog is set and the user rejects the confirmation
  // dialog, the Promise returned by addOrUpdateFunctionSpec resolves to
  // undefined.
  showConfirmationDialog:
    | ((_: confirmationDialogInfo) => Promise<boolean>)
    | undefined,
): Promise<boolean> {
  if (showConfirmationDialog) {
    let validationQueryRes: ValidateFunctionSpecUpdateQuery;
    try {
      ({data: validationQueryRes} = await client.query({
        query: VALIDATE_FUNCTION_SPEC_UPDATE,
        variables: {
          funcSpecUpdate: input,
        },
        fetchPolicy: "no-cache",
      }));
    } catch (err) {
      toastError(err, "failed to validate function spec update");
      return false;
    }
    const validationResult = validationQueryRes.validateFunctionSpecUpdate;
    const validationFailed =
      validationResult.tableValidationError ||
      validationResult.newlyFailingTables.length > 0 ||
      validationResult.newlyFailingLinks.length > 0;
    if (validationFailed) {
      const dialogContent = (
        <>
          Are you sure you want to save changes to function{" "}
          {input.funcQualifiedName}?<br />
          {warningMessageForTableValidationFail(
            validationResult.tableValidationError,
            validationResult.newlyFailingTables,
            validationResult.newlyFailingLinks,
          )}
        </>
      );
      // If this change failed validation, show a confirmation dialog asking the
      // user to confirm that they indeed want to break this table or some other
      // tables that depend on it.
      const confirmed: boolean = await showConfirmationDialog({
        title: "Confirm suspicious function edit",
        content: dialogContent,
      });
      if (!confirmed) {
        // User changed their mind.
        return false;
      }
    }
  }

  return client
    .mutate({
      mutation: ADD_OR_UPDATE_FUNCTION_SPEC,
      variables: {input},
      refetchQueries: [GET_TABLES, GET_SCHEMA],
    })
    .then(() => true)
    .catch((err) => {
      console.error("failed to update function spec", err);
      toast.error(`Failed to update function spec: ${err}`);
      return false;
    });
}

export const GET_SCHEMA = gql(/* GraphQL */ `
  query getSchema {
    getSchema {
      ...FullTableSchema
    }
  }
`);

gql(/* GraphQL */ `
  fragment FullTableSchema on TableSchema {
    Name
    Error
    Details {
      ... on DerivedTableDetails {
        Spec {
          id
          name
          query
          columns {
            name
            expr
            hidden
            type
          }
        }
      }
      ... on FunctionTableDetails {
        FuncName {
          QualifiedName
          Package
          Type
          Name
        }
        ModuleName
        ModulePackage
        CustomTableName
      }
      ... on EventsTableDetails {
        FuncName {
          QualifiedName
          Package
          Type
          Name
        }
        ModuleName
        ModulePackage
        CustomTableName
      }
      ... on BuiltinTableDetails {
        Name
        Note
      }
    }
    Columns {
      Name
      Type
      Hidden
    }
    # NumRows will be zero for the getSchema query, but it's convenient to read it anyway
    # so that the query returns a full TableSchema.
    NumRows
    Links {
      LinkSpec {
        ...FullLinkSpec
      }
      Side
    }
  }
`);

export const GET_BINARIES_MATCHING_FUNCTION = gql(/* GraphQL */ `
  query GetBinariesMatchingFunction(
    $snapshotID: ID!
    $funcQualifiedName: String!
  ) {
    getBinariesMatchingFunction(
      snapshotID: $snapshotID
      funcQualifiedName: $funcQualifiedName
    ) {
      id
      userName
      programs
    }
  }
`);

export const RUN_SQL_QUERY = gql(/* GraphQL */ `
  query runSQLQuery($snapshotID: ID, $query: String!) {
    runSQLQuery(snapshotID: $snapshotID, query: $query) {
      Columns {
        Name
        Type
        Hidden
      }
      Links {
        Side
        LinkSpec {
          ...FullLinkSpec
        }
      }
      Rows {
        ColumnValues
        Links {
          LinkID
          Side
          NumLinks
          SampleGoroutineID {
            ID
            ProcessSnapshotID
          }
        }
      }
    }
  }
`);

export const GET_TABLE_NAMES = gql(/* GraphQL */ `
  query GetTableNames {
    getSchema {
      Name
    }
  }
`);
