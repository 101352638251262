import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {Box, Button} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import {StreamLanguage} from "@codemirror/language";
import {json} from "@codemirror/legacy-modes/mode/javascript";
import {DOWNLOAD_SPEC, OVERWRITE_SPEC} from "../gqlHelpers.ts";
import {SQLShellTheme} from "src/theme/SQLShellTheme.ts";

function RawSpecEditor(): React.JSX.Element {
  const [spec, setSpec] = useState("");

  const {data, loading} = useQuery(DOWNLOAD_SPEC, {});
  const [mutate] = useMutation(OVERWRITE_SPEC, {
    refetchQueries: [DOWNLOAD_SPEC],
  });
  const save = () => {
    setSpec("");
    void mutate({variables: {spec}});
  };
  return (
    <>
      <Box>
        {spec == "" && loading ? (
          "Loading..."
        ) : (
          <CodeMirror
            value={spec != "" ? spec : data?.downloadSpec}
            theme={SQLShellTheme}
            height="800px"
            extensions={[StreamLanguage.define(json)]}
            basicSetup={true}
            onChange={setSpec}
          />
        )}
      </Box>
      <Button onClick={save} variant={"contained"} sx={{width: "10em", mt: 1}}>
        Save
      </Button>
    </>
  );
}

export default RawSpecEditor;
