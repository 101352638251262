import React, {useContext, useState} from "react";
import {AppConfigContext} from "../../../providers/app-config-provider.tsx";
import {useSuspenseQuery} from "@apollo/client";
import {User} from "firebase/auth";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import SuperUser from "../../../components/Superuser.tsx";
import {GET_ORG_INFO, GET_USER_INFO} from "../gqlHelpers.ts";
import {Avatar} from "../../../components/Avatar.tsx";
import {LogoutButton} from "./LogoutButton.tsx";
import TextField from "@mui/material/TextField";
import {ClipboardCopy} from "../../../components/clipboard-copy.tsx";

const UserInfo = () => {
  const appCfg = useContext(AppConfigContext);
  const [env, setEnv] = useState("");
  const theme = useTheme();

  if (!appCfg.AuthEnabled) {
    throw new Error("Authentication disabled");
  }

  const user: User | undefined = appCfg.User;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const {data: orgInfoData, refetch: refetchOrgInfo} =
    useSuspenseQuery(GET_ORG_INFO);

  const {data: userData} = useSuspenseQuery(GET_USER_INFO);
  const orgInfo = orgInfoData.getOrgInfo;

  const agentInstallCommandNoEnv = orgInfo.apiToken
    ? `curl https://sh.side-eye.io/ | SIDE_EYE_API_TOKEN=${orgInfo.apiToken} sh`
    : undefined;
  const agentInstallCommandEnv = orgInfo.apiToken
    ? env
      ? `curl https://sh.side-eye.io/ | SIDE_EYE_API_TOKEN=${orgInfo.apiToken} SIDE_EYE_ENVIRONMENT=${env} sh`
      : agentInstallCommandNoEnv
    : undefined;

  return (
    <>
      <Stack direction="row" gap={3} alignItems="center" my={4} flexWrap="wrap">
        <Typography variant="h1">Organization:</Typography>
        <Avatar photoURL={user.photoURL ?? undefined} />
        <Typography
          variant="h1"
          color={"primary.light"}
          sx={{marginRight: "auto"}}
        >
          {orgInfo.orgName}
        </Typography>
        <LogoutButton />
      </Stack>

      <Grid container gap={3} justifyContent="center">
        <Grid item component={Card} md={12} lg>
          <CardContent>
            <Typography variant="body2" color="secondary">
              {orgInfo &&
                `You are logged in as a member of organization ${orgInfo.orgName}. `}
              Side-Eye is designed for teams of people. As such, individual
              users belong to organizations, and collected data is accessible to
              the whole organization. Agents report processes on behalf of an
              organization (agents are configured with an organization-wide API
              token), and the spec controlling what data to collect pertains to
              the whole organization.
            </Typography>

            <Divider sx={{my: 4}} />

            <Typography variant="body2" color="secondary">
              In order to collect data for your services, Side-Eye needs to be
              configured to monitor processes. There are two ways to monitor a
              process:
            </Typography>

            <List sx={{mt: 3}}>
              {[
                [
                  "Install the Side-Eye agent on each machine (Linux)",
                  "Agents can be configured with program rules specifying which processes to monitor.",
                ],
                [
                  "Include the Side-Eye client library in your binaries (Linux and macOS)",
                ],
              ].map(([title, subtitle], i) => (
                <ListItem key={i} disablePadding sx={{mb: 3}}>
                  <ListItemIcon sx={{mr: 1, alignSelf: "start", mt: 1}}>
                    <Stack
                      width={40}
                      height={40}
                      borderRadius="50%"
                      border={() => `1px solid ${theme.palette.primary.main}`}
                      color="primary.main"
                      justifyContent="center"
                      alignItems="center"
                      fontSize={16}
                    >
                      {i + 1}
                    </Stack>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle1">{title}</Typography>
                    <Typography variant="body2" color="secondary" mt={1}>
                      {subtitle}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>

            <Typography variant="body2" color="secondary">
              Both the agent and the library need to be configured with the
              organization's API token
            </Typography>
          </CardContent>
        </Grid>

        {orgInfo && (
          <Grid item component={Card} md={12} lg>
            <CardContent>
              <Typography variant="body2" color="secondary">
                Agent token for organization {orgInfo.orgName}.{" "}
                <Link href="https://docs.side-eye.io/users-and-orgs/">
                  Read more
                </Link>{" "}
                about users and organizations
              </Typography>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
              >
                {orgInfo.agentsOrgRedirect ? (
                  <Typography variant="subtitle1">
                    this organization is using the agents of another org:{" "}
                    {orgInfo.agentsOrgRedirect}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="subtitle1">
                      {orgInfo.apiToken!}
                    </Typography>
                    <ClipboardCopy copyText={orgInfo.apiToken!} />
                  </>
                )}
              </Stack>

              <Divider sx={{my: 4}} />

              <Typography variant="body2" color="secondary">
                {orgInfo.notes}
              </Typography>

              <Typography variant="body2" color="secondary">
                The Side-Eye agent needs to be installed and running on all the
                machines hosting processes that you want to monitor. To install,
                run on each machine:
              </Typography>

              <Stack direction="row" justifyContent="space-between" mt={4}>
                <Typography variant="subtitle1">
                  {agentInstallCommandNoEnv!}
                </Typography>
                <ClipboardCopy copyText={agentInstallCommandNoEnv!} />
              </Stack>

              <Divider sx={{my: 4}} />

              <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                rowGap={2}
              >
                <Typography variant="body2" color="secondary" mr={2}>
                  Or, to also include an environment name
                </Typography>

                <TextField
                  size={"medium"}
                  color="primary"
                  placeholder="Environment"
                  value={env}
                  onChange={(e) => setEnv(e.target.value)}
                  sx={{flex: 1, minWidth: "300px"}}
                />
              </Stack>

              <Typography variant="body2" color="secondary" mt={2}>
                the command is:
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
              >
                <Typography
                  variant="subtitle1"
                  style={{wordBreak: "break-word"}}
                >
                  {agentInstallCommandEnv!}
                </Typography>

                <ClipboardCopy copyText={agentInstallCommandEnv!} />
              </Stack>

              <Typography variant="body2" color="secondary" mt={4}>
                The script will install the agent through a snap package.{" "}
                <Link href="https://docs.side-eye.io/installing/">
                  Read more
                </Link>{" "}
                about installing the Side-Eye agent.
              </Typography>
            </CardContent>
          </Grid>
        )}
      </Grid>

      {userData.getUserInfo.superuser && appCfg.Auth && user && (
        <Stack mt={3}>
          <SuperUser
            user={user}
            orgInfo={orgInfo}
            refetchOrgInfo={() => {
              void refetchOrgInfo();
            }}
            auth={appCfg.Auth}
          />
        </Stack>
      )}
    </>
  );
};

export default UserInfo;
