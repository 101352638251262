import React, {useCallback, useState} from "react";
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Process, ProcessLabel} from "src/__generated__/graphql.ts";
import {Label} from "src/util/labels.ts";
import {useQuery} from "@apollo/client";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {GET_ALL_PROCESSES} from "../../gqlHelper.ts";
import {TableTooltip} from "./TableTooltip.tsx";
import Autocomplete from "@mui/material/Autocomplete";

export type ProcessInfo = {
  hostname: string;
  processName: string;
  // programName is set if the process has been matched to a program.
  programName: string | undefined;
  labels: ProcessLabel[];
};

type Props = {
  onMonitorProcess: (p: ProcessInfo) => void;
};

export const noEnvSymbol: unique symbol = Symbol("<no environment>");

export default function AllProcesses(props: Props) {
  const {data: allProcesses, loading} = useQuery(GET_ALL_PROCESSES, {
    fetchPolicy: "no-cache",
  });

  const [filter, setFilter] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState(
    undefined as string | undefined | typeof noEnvSymbol,
  );

  // We'll debounce filter changes because the table with all processes takes a
  // bit of time to render.
  const debounceFilterChange = useCallback(
    debounce(setFilter, 500 /* timeoutMillis */),
    [],
  );

  if (loading) {
    return <>Loading...</>;
  }

  const environmentList = [
    // De-dupe environments.
    ...new Set(
      allProcesses!.getAgentReports.Reports.map((r) =>
        r.Environment == undefined ? noEnvSymbol : r.Environment,
      ),
    ),
  ];

  const procsByAgent = new Map<
    {hostname: string; environment: string},
    ProcessInfo[]
  >();

  for (const report of allProcesses!.getAgentReports.Reports) {
    const hostname = report.Hostname;
    const agent = {
      hostname: hostname,
      environment: report.Environment!,
    };

    for (const p of report.Processes) {
      const proc = parseProcess(p);
      const passesFilter = !filter
        ? true
        : proc.labels.some(
            (l) => l.Label.includes(filter) || l.Value.includes(filter),
          );
      if (!passesFilter) {
        continue;
      }

      if (selectedEnvironment) {
        if (selectedEnvironment == noEnvSymbol) {
          if (agent.environment) {
            continue;
          }
        } else {
          if (selectedEnvironment != agent.environment) {
            continue;
          }
        }
      }

      if (!procsByAgent.has(agent)) {
        procsByAgent.set(agent, []);
      }
      procsByAgent.get(agent)!.push(proc);
    }
  }

  return (
    <>
      <TableTooltip />

      <Stack direction="row" gap={2} flexWrap="wrap">
        <Autocomplete
          options={environmentList}
          sx={{minWidth: "400px", flexGrow: 1}}
          autoHighlight={true}
          renderInput={(params) => (
            <TextField {...params} placeholder="Environment" />
          )}
          value={
            // Turn undefined into null, otherwise the Autocomplete thinks it's
            // switching from controlled to uncontrolled.
            selectedEnvironment === undefined ? null : selectedEnvironment
          }
          onChange={(_event, newValue) => {
            if (newValue === null) {
              // Switch null to undefined.
              setSelectedEnvironment(undefined);
              return;
            }
            setSelectedEnvironment(newValue);
          }}
          getOptionLabel={(opt: string | typeof noEnvSymbol) => {
            if (typeof opt == "string") {
              return opt;
            }
            return opt == noEnvSymbol ? "<no environment>" : opt;
          }}
        />

        <TextField
          placeholder="Filter processes"
          sx={{minWidth: "400px", flexGrow: 1}}
          onChange={(e) => debounceFilterChange(e.target.value)}
        />
      </Stack>

      <Stack gap={2}>
        {Array.from(procsByAgent.entries()).map(([agent, procs]) => (
          <React.Fragment key={`${agent.hostname}-header`}>
            <Divider sx={{my: 2, borderWidth: 2}} color="secondary" />

            <Stack direction="row" rowGap={1} columnGap={5} flexWrap="wrap">
              <Stack direction="row" gap={2}>
                <Typography variant="body4">Environment</Typography>
                <Typography variant="body4" color="primary.light">
                  {agent.environment || "N/A"}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <Typography variant="body4">Host</Typography>
                <Typography variant="body4" color="primary.light">
                  {agent.hostname}
                </Typography>
              </Stack>
            </Stack>

            <TableContainer>
              <Table
                sx={{minWidth: "600px", tableLayout: "fixed"}}
                color="secondary"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: "200px"}}>Process</TableCell>
                    <TableCell style={{width: "100%"}}>Labels</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {procs.map((p, idx) => (
                    <TableRow key={`${agent.hostname}-${idx}`}>
                      <TableCell>
                        <div style={{marginBottom: 10}}>{p.processName}</div>

                        {!p.programName ? (
                          <Button
                            variant="outlined"
                            onClick={() => props.onMonitorProcess(p)}
                            // Control the tooltip content.
                            data-tooltip-id="chip-tooltip"
                            data-tooltip-content={
                              "Start monitoring this process by adding a program rule " +
                              "for this executable name."
                            }
                          >
                            Monitor process
                          </Button>
                        ) : (
                          <Typography variant="warning">
                            Monitored as program: {p.programName}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell
                        sx={{display: "flex", flexWrap: "wrap", gap: 1}}
                      >
                        {p.labels.map((l) => (
                          <Chip
                            key={l.Label}
                            label={`${l.Label}:${l.Value}`}
                            // Control the tooltip content.
                            data-tooltip-id="chip-tooltip"
                            data-tooltip-content={l.Value}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ))}
      </Stack>
    </>
  );
}

function parseProcess(p: Partial<Process>): ProcessInfo {
  function findLabel(label: Label): ProcessLabel | undefined {
    return p.Labels?.find((l) => l.Label === label);
  }

  return {
    hostname: findLabel(Label.Hostname)?.Value ?? "",
    processName: findLabel(Label.ExecutableName)?.Value ?? "",
    programName: findLabel(Label.Program)?.Value,
    labels: p.Labels!,
  };
}

function debounce<T>(func: (...param: T[]) => void, timeoutMillis: number) {
  let timer: number;
  return (...args: T[]) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(func, timeoutMillis, ...args);
  };
}
