import React, {useContext, useEffect} from "react";
import {signInAnonymously} from "firebase/auth";
import {AppConfigContext} from "src/providers/app-config-provider";
import UserInfo from "./components/UserInfo.tsx";
import {Welcome} from "./components/Welcome.tsx";

export default function LoginPage() {
  const appCfg = useContext(AppConfigContext);

  useEffect(() => {
    const signIn = async () => {
      if (appCfg.EphemeralEnvironment && appCfg.Auth) {
        try {
          await signInAnonymously(appCfg.Auth);
        } catch (error) {
          console.error("Error signing in anonymously:", error);
        }
      }
    };

    void signIn();
  }, [appCfg.Auth, appCfg.EphemeralEnvironment]);

  if (appCfg.EphemeralEnvironment && appCfg.Auth) {
    return <>Signing in anonymously...</>;
  }

  if (!appCfg.AuthEnabled) {
    return <>Authentication disabled</>;
  }

  return appCfg.User ? <UserInfo /> : <Welcome />;
}
