import React, {memo, ReactNode} from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableProvidedDraggableProps,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import {Button, TableBody, Tooltip} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {getValueByKeyChain, PathKeys} from "@util/get-value-by-key-chain.ts";
import {TableNoDataMessage} from "@components/TableNoDataMessage.tsx";

// The type restrictions say that Item is a type that has a property K whose
// value is a string.
type Props<Item> = {
  items: Item[];
  renderRow: (rowData: {
    row: Item;
    index: number;
    dragHandle: ReactNode;
    rowRef: DraggableProvided["innerRef"];
    draggableProps: DraggableProvidedDraggableProps;
  }) => ReactNode;
  onDragEnd: OnDragEndResponder;
  draggableIdPath: PathKeys<Item>;
};

const GenericDraggableTable = <Item,>(props: Props<Item>) => {
  const {items, renderRow, onDragEnd, draggableIdPath} = props;

  if (!items.length) {
    return (
      <TableBody>
        <TableNoDataMessage />
      </TableBody>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-table">
        {(provided) => (
          <TableBody ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item: Item, index: number) => {
              const draggableId = getValueByKeyChain(
                item,
                draggableIdPath,
              ) as string;

              return (
                <Draggable
                  key={draggableId}
                  draggableId={draggableId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                      {renderRow({
                        row: item,
                        index,
                        dragHandle: (
                          <Tooltip title="Reorder">
                            <Button
                              variant="outlined"
                              color="info"
                              {...provided.dragHandleProps}
                              sx={{
                                minWidth: 0,
                                width: "auto",
                                px: 0,
                                ...(!snapshot.isDragging && {
                                  background: "transparent",
                                }),
                              }}
                            >
                              <DragIndicatorIcon
                                color={snapshot.isDragging ? "action" : "info"}
                              />
                            </Button>
                          </Tooltip>
                        ),
                        rowRef: provided.innerRef,
                        draggableProps: provided.draggableProps,
                      })}
                    </>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const genericMemo: <T>(component: T) => T = memo;

const DraggableTable = genericMemo(GenericDraggableTable);
export default DraggableTable;
