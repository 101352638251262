import {ProcessLabel} from "@graphql/graphql.ts";
import {getLabelValue, Label} from "@util/labels.ts";
import {EnvironmentProcesses} from "./environmentProcesses.class.ts";
import {AgentReportsResult} from "@providers/agent-reports-provider.tsx";
import {ProcessInfo} from "../@types.ts";

// processHierarchy contains data about processes reported by agents. The
// processes are grouped by environment and program.
export class ProcessHierarchy {
  // Processes without an environment label are grouped under the key undefined
  // key.
  environments: Map<string | undefined, EnvironmentProcesses>;

  constructor(reports: AgentReportsResult["Reports"]) {
    this.environments = new Map<string, EnvironmentProcesses>();
    reports.forEach((report) => {
      report.Processes.forEach((process) => {
        let binaryID: string;
        let binaryName: string;
        let unknown: boolean;
        switch (process.Binary.__typename) {
          case "Binary":
            binaryID = process.Binary.id;
            binaryName = process.Binary.userName;
            unknown = false;
            break;
          case "UnknownBinaryInfo":
            binaryID = process.Binary.Hash;
            binaryName = process.Binary.SuggestedName;
            unknown = true;
            break;
        }

        const rawEnv = getLabelValue(Label.Environment, process.Labels);
        const env = rawEnv === "" ? undefined : rawEnv;

        const pInfo: ProcessInfo = {
          host: {
            ip:
              report.IPAddresses.length > 0 ? report.IPAddresses[0] : undefined,
            hostname: report.Hostname,
          },
          processToken: process.ProcessToken,
          pid: process.PID,
          agentID: report.AgentID,
          processID: processName(process.Labels ?? [], process.PID),
          programName: getLabelValue(Label.Program, process.Labels)!,
          binary: {
            hash: binaryID,
            unknown: unknown,
            binaryName: binaryName,
          },
          environment: env,
        };

        this.addProcess(pInfo);
      });
    });
  }

  empty(): boolean {
    return this.environments.size == 0;
  }

  multipleEnvironments(): boolean {
    return this.environments.size > 1;
  }

  // singleEnvironment returns the single environment in the processHierarchy.
  // If the processHierarchy is empty, or if it has more than one environment,
  // it throws. A return value of undefined means that there are some processes,
  // but they all report no environment configured.
  singleEnvironment(): string | undefined {
    if (this.multipleEnvironments()) {
      throw new Error(
        "singleEnvironment() called on processHierarchy with multiple environments",
      );
    }
    if (this.empty()) {
      throw new Error("singleEnvironment() called on empty processHierarchy");
    }

    const cur = this.environments.keys().next();
    if (cur.done) {
      // We already verified above that the map is not empty. This check is here
      // to keep the type checker/linter happy; without it `cur.value` has type
      // `any`.
      throw new Error("unreachable");
    }
    return cur.value;
  }

  addProcess(process: ProcessInfo) {
    const env = process.environment;
    if (!this.environments.has(env)) {
      this.environments.set(env, new EnvironmentProcesses());
    }
    this.environments.get(env)!.addProcess(process);
  }

  processesForEnvAndProgram(
    env: string | undefined,
    program: string,
  ): ProcessInfo[] {
    const envProcs = this.environments.get(env);
    if (envProcs === undefined) {
      throw new Error(`environment missing: ${env}`);
    }
    const procs = envProcs.programs.get(program);
    if (procs === undefined) {
      throw new Error(`program missing: ${program}`);
    }
    return procs;
  }
}

function processName(labels: ProcessLabel[], pid: number) {
  const id = labels.find((label) => label.Label == Label.PID.toString())?.Value;
  if (id !== undefined) {
    return id;
  }
  return pid.toString();
}
