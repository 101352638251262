type RGBArray = [number, number, number];
type ColorPair = [RGBArray, RGBArray]; // A pair of start and end color.

// Using the same color options as before, change if required
const ColorRanges: {[key: string]: ColorPair} = {
  red: [
    [200, 50, 50],
    [255, 130, 130],
  ],
  orange: [
    [190, 90, 0],
    [190 + 95, 90 + 95, 0],
  ],
  warm: [
    [200, 230, 55],
    [255, 0, 0],
  ],
  pastelgreen: [
    [163, 195, 72],
    [238, 244, 221],
  ],
  blue: [
    [91, 156, 221],
    [217, 232, 247],
  ],
};

// Given two colors and a number between 0 and 1, this
// generates something in the middle
function InterpolateColor(start: RGBArray, end: RGBArray, t: number): RGBArray {
  return start.map(
    (value, index) => value + (end[index] - value) * t,
  ) as RGBArray;
}

function generateColorVector(name: string, maxChar: number = 100): number {
  // Return a vector (0.0->1.0) that is a hash of the input string.
  // The hash is computed to favor early characters over later ones, so
  // that strings with similar starts have similar vectors. Only the first
  // maxChar characters are considered.

  let hash = 0;
  let maxHash = 0;
  let weight = 1;
  const mod = 10;

  if (name) {
    for (let i = 0; i < name.length && i < maxChar; i++) {
      hash += weight * (name.charCodeAt(i) % mod);
      maxHash += weight * (mod - 1);
      weight *= 0.7;
    }
    if (maxHash > 0) {
      hash = hash / maxHash;
    }
  }
  return hash;
}

export function calculateColor(name: string): string {
  const hashVector = generateColorVector(name);
  const [r, g, b] = InterpolateColor(...ColorRanges.warm, hashVector);
  return `rgba(${r},${g},${b},0.85)`;
}
