import {ProcessHierarchy} from "./processHierarchy.class.ts";
import {EnvironmentSelectionState} from "./environmentSelectionState.class.ts";
import {defaultSelectionForEnvironment} from "./defaultSelectionForEnvironment.function.ts";

// defaultSelection takes a report and returns the default selection for it, if
// any. A default selection is produced if the report has exactly one
// environment, or if environments are not used. In that case, the selection is
// the respective environment with all programs selected.
export function defaultSelection(
  report: ProcessHierarchy,
): EnvironmentSelectionState | undefined {
  // We can initialize the selection only if there is a single environment (or
  // if environments are not used). If there are environments, the user first
  // needs to select one.
  if (report.multipleEnvironments()) {
    return undefined;
  }

  // If the report has no programs/processes, there can be no selection.
  if (report.empty()) {
    return undefined;
  }

  // If we got here, then there must be a single environment, so we can create a
  // default selection.

  // All programs in the report start off as selected.
  const singleEnvName = report.singleEnvironment();
  return defaultSelectionForEnvironment(report, singleEnvName);
}
