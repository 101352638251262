import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import {Link as ReactLink} from "react-router-dom";
import {SnapshotInfoMinimal} from "@util/util.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "@apollo/client";
import {DELETE_SNAPSHOT, GET_SNAPSHOTS} from "./gqlHelper.ts";
import dayjs from "dayjs";
import {Avatar} from "@components/Avatar.tsx";

type Props = {
  snapshot: SnapshotInfoMinimal;
};

export const SnapshotItem: React.FC<Props> = ({snapshot}) => {
  const [deleteSnapshot] = useMutation(DELETE_SNAPSHOT, {
    refetchQueries: [GET_SNAPSHOTS],
  });

  return (
    <Card>
      <CardContent
        component={Stack}
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          <Typography variant="body2" color="primary.light" sx={{minWidth: 50}}>
            {snapshot.id}
          </Typography>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar photoURL={snapshot.user?.photoURL ?? undefined} />
            <Typography variant="body2" color="textSecondary" width={200}>
              {snapshot.user?.name ?? "<user unknown>"}
            </Typography>
            <Link component={ReactLink} to={`/snapshots/` + snapshot.id}>
              {snapshot.environment || ""} {snapshot.programs.join(", ")}
            </Link>
          </Stack>

          <Typography variant="body2" color="textSecondary" width={300}>
            {dayjs(snapshot.captureTime).format("dddd, h:mm:ss A z")}
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            void deleteSnapshot({variables: {id: snapshot.id}});
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};
