import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiSwitch = (): Pick<Components<typeof theme>, "MuiSwitch"> => ({
  MuiSwitch: {
    styleOverrides: {
      root: ({theme}) => ({
        width: 32,
        height: 19,
        padding: 0,
        "& .MuiSwitch-switchBase": {
          margin: 0,
          padding: 1,
          transform: "translateX( 1px)",
          "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(12.5px)",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.primary
                  : theme.palette.secondary,
            },
          },
        },
        "& .MuiSwitch-thumb": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.secondary
              : theme.palette.primary,
          width: 17,
          height: 17,
          "&::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          },
        },
        "& .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.background.paper
              : theme.palette.secondary,
          borderRadius: 20 / 2,
        },

        "& + .MuiTypography-root": {
          marginLeft: 10,
          fontSize: 14,
        },
      }),
    },
  },
});
