import React from "react";

const IconSide = () => {
  return (
    <svg
      width="99"
      height="15"
      viewBox="0 0 99 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.6489 0.434692H71.4196V2.8503H64.0433V6.09218H70.7016V8.50778H64.0433V12.1415H71.6376V14.5571H60.6489V0.434692Z"
        fill="#B6E4FA"
      />
      <path
        d="M77.6857 8.89877L72.5725 0.434662H76.4242L79.4054 5.96181L82.3645 0.434662H86.2375L81.0809 8.85532V14.5563H77.6865V8.89877H77.6857Z"
        fill="#B6E4FA"
      />
      <path
        d="M87.9348 0.434662H98.7055V2.85027H91.3291V6.09215H97.9875V8.50775H91.3291V12.1414H98.9235V14.557H87.9348V0.434662Z"
        fill="#B6E4FA"
      />
      <path
        d="M1.07137 14.1645V11.5751C3.53753 11.9527 5.22009 12.1407 6.11982 12.1407C8.00538 12.1407 8.94856 11.6549 8.94856 10.6825V9.76852C8.94856 9.30404 8.81427 8.94936 8.54569 8.70211C8.27712 8.45566 7.79526 8.33243 7.09854 8.33243H5.44521C2.34079 8.33243 0.788574 7.09934 0.788574 4.63318V3.58889C0.788574 2.35581 1.30361 1.44897 2.33368 0.869161C3.36375 0.289352 4.99575 -0.00134277 7.22967 -0.00134277C8.39008 -0.00134277 9.86251 0.093449 11.647 0.281453V2.80528C9.45648 2.54381 7.94772 2.41348 7.12066 2.41348C5.97447 2.41348 5.20587 2.50827 4.81406 2.69627C4.39303 2.89928 4.18291 3.21841 4.18291 3.65367V4.65451C4.18291 5.39467 4.80616 5.76436 6.05425 5.76436H7.75181C9.36169 5.76436 10.5292 6.06216 11.2552 6.65619C11.9803 7.25101 12.3429 8.15785 12.3429 9.37593V10.3112C12.3429 11.9938 11.741 13.1328 10.5371 13.7277C9.95651 14.0326 9.31509 14.2466 8.61126 14.3699C7.90743 14.4931 7.0622 14.5547 6.07637 14.5547C4.72717 14.5547 3.05883 14.4244 1.07137 14.1629V14.1645Z"
        fill="#B6E4FA"
      />
      <path
        d="M14.6929 0.216675H18.0872V14.3383H14.6929V0.216675Z"
        fill="#B6E4FA"
      />
      <path
        d="M21.1333 0.216675H27.1391C28.7925 0.216675 30.0982 0.343854 31.0556 0.597421C32.013 0.850989 32.7168 1.29019 33.1663 1.91424C33.6158 2.53828 33.8409 3.40168 33.8409 4.50363V10.248C33.8409 11.3215 33.6308 12.1517 33.2098 12.7394C32.7887 13.3272 32.0999 13.7403 31.1425 13.9796C30.1851 14.219 28.858 14.3383 27.1605 14.3383H21.1333V0.216675ZM27.008 11.9234C27.9362 11.9234 28.6361 11.884 29.1076 11.8042C29.5792 11.7244 29.9197 11.5688 30.1306 11.3365C30.3407 11.1043 30.4458 10.7417 30.4458 10.2488V4.43885C30.4458 3.77215 30.1812 3.30372 29.6519 3.03515C29.1219 2.76657 28.2774 2.63228 27.117 2.63228H24.5276V11.9234H27.008Z"
        fill="#B6E4FA"
      />
      <path
        d="M36.582 0.216675H47.3527V2.63228H39.9764V5.87416H46.6347V8.28977H39.9764V11.9234H47.5708V14.3391H36.582V0.216675Z"
        fill="#B6E4FA"
      />
      <path
        d="M56.9953 5.66879H50.9199V8.87749H56.9953V5.66879Z"
        fill="#B6E4FA"
      />
    </svg>
  );
};

export default IconSide;
