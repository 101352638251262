import {
  GoroutineId,
  LinkEndpointSpec,
  LinkSide,
  RowLinksInfo,
  TableReference,
} from "src/__generated__/graphql.ts";
import {schemaInfo, tableInfo} from "src/util/spec.ts";

export type resolvedLinkSpec = {
  LinkID: string;
  Side: LinkSide;
  LinkNote: string;
  SourceCols: string[];
  TargetCols: string[];
  TargetTableRef: TableReference;
  TargetTableName: string;
  TargetDisplayName: string;
};

// lower the links to a more convenient form. In particular, the name of the
// table that the rows are linked to is resolved and the source/target are
// normalized such that this table is always the source.
export function resolveLinkSpecs(
  links: LinkEndpointSpec[],
  schema: schemaInfo,
): resolvedLinkSpec[] {
  return links.map((li): resolvedLinkSpec => {
    const linkSpec = li.LinkSpec;
    let srcCols: string[];
    let targetCols: string[];
    let ti: tableInfo;
    if (li.Side == LinkSide.Source) {
      // We are `srcTable`, so the other table is `destTable`.
      const tabInfo = schema.resolveTableReference(linkSpec.destTable);
      if (tabInfo == undefined) {
        throw new Error(
          "table not found: " + JSON.stringify(linkSpec.destTable),
        );
      }
      ti = tabInfo;
      srcCols = linkSpec.srcCols;
      targetCols = linkSpec.destCols;
    } else {
      // We are `destTable`, so the other table is `srcTable`.
      const tabInfo = schema.resolveTableReference(linkSpec.srcTable);
      if (tabInfo == undefined) {
        throw new Error(
          "table not found: " + JSON.stringify(linkSpec.destTable),
        );
      }
      ti = tabInfo;
      srcCols = linkSpec.destCols;
      targetCols = linkSpec.srcCols;
    }
    return {
      LinkID: linkSpec.id,
      Side: li.Side,
      LinkNote: linkSpec.note ?? "",
      TargetTableRef: ti.tableReference,
      TargetDisplayName: ti.displayName,
      TargetTableName: ti.tableName,
      SourceCols: srcCols,
      TargetCols: targetCols,
    };
  });
}

// resolvedLinksInfo is a distilled version of graph.RowLinksInfo. In
// particular, the values of the columns participating in the links are
// collected.
export type resolvedLinksInfo = {
  LinkID: string;
  LinkNote: string;
  TargetCols: string[];
  // The values of the columns defining this link. One entry for each TargetCols
  // element.
  TargetColValues: (string | null)[];
  TargetTableRef: TableReference;
  TargetTableDisplayName: string;
  TargetTableName: string;
  NumLinks: number;
  SampleGoroutineID: GoroutineId | undefined;
};

// linksByColumn takes in a list of RowLinksInfo (a summary of a row's links),
// and the respective row (as parallel lists of column names and values) and
// expands the links into a list of links for each individual column.
export function linksByColumn(
  links: RowLinksInfo[],
  columnNames: string[],
  columnValues: (string | null)[],
  linkSpecs: LinkEndpointSpec[],
  schema: schemaInfo,
): resolvedLinksInfo[][] {
  const resolvedLinkSpecs = resolveLinkSpecs(linkSpecs, schema);
  const res: resolvedLinksInfo[][] = [];
  for (const colName of columnNames) {
    // Select the links that contain this column.
    const colLinksSpecs = resolvedLinkSpecs.filter((li) =>
      li.SourceCols.includes(colName),
    );

    // build a map from column name to index.
    const colNameToIdx = new Map<string, number>();
    for (const [i, colName] of columnNames.entries()) {
      colNameToIdx.set(colName, i);
    }

    // Go through the row's links, select the ones corresponding to this column,
    // and collect the values for all other columns.
    const colLinks = links.reduce((res, li): resolvedLinksInfo[] => {
      // Find the spec of this row link.
      const colLinkSpec = colLinksSpecs.find(
        (cli) => li.LinkID == cli.LinkID && li.Side == cli.Side,
      );
      if (!colLinkSpec) {
        // This column does not participate in this link.
        return res;
      }

      // Collect the values of the columns participating in the link.
      const values: (string | null)[] = colLinkSpec.SourceCols.map(
        (colName) => {
          const idx = colNameToIdx.get(colName);
          if (idx == undefined) {
            throw new Error("column not found: " + colName);
          }
          return columnValues[idx];
        },
      );

      return [
        ...res,
        {
          LinkID: colLinkSpec.LinkID,
          LinkNote: colLinkSpec.LinkNote,
          TargetCols: colLinkSpec.TargetCols,
          TargetColValues: values,
          TargetTableRef: colLinkSpec.TargetTableRef,
          TargetTableDisplayName: colLinkSpec.TargetDisplayName,
          TargetTableName: colLinkSpec.TargetTableName,
          NumLinks: li.NumLinks,
          SampleGoroutineID: li.SampleGoroutineID ?? undefined,
        },
      ];
    }, [] as resolvedLinksInfo[]);
    res.push(colLinks);
  }

  return res;
}
