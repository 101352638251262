import {FunctionSpecCard} from "src/pages/Spec/components/FunctionSpecCard.tsx";
import React, {useContext} from "react";
import {ModuleSpec, SnapshotSpec} from "@graphql/graphql.ts";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DraggableTable from "@components/DraggableTable.tsx";
import {DropResult} from "@hello-pangea/dnd";
import {MOVE_FUNCTION_TO_INDEX} from "../gqlHelpers.ts";
import {useApolloClient} from "@apollo/client";
import {SpecContext} from "@providers/spec-provider.tsx";

type Props = {
  module: ModuleSpec;
  binaryID?: string;
  promptForBinarySelection: () => void;
};

// ModuleFunctionsList lists the function in a module spec as table rows.
export const ModuleFunctionsList: React.FC<Props> = ({
  module,
  binaryID,
  promptForBinarySelection,
}) => {
  const spec = useContext(SpecContext);
  const client = useApolloClient();

  const onDragEnd = ({destination, source}: DropResult) => {
    // Dropped outside the list.
    if (!destination) return;
    if (source.index == destination.index) {
      // Nothing to do.
      return;
    }

    const functionSpec = module.functionSpecs[source.index];

    void client.mutate({
      mutation: MOVE_FUNCTION_TO_INDEX,
      variables: {
        funcQualifiedName: functionSpec.funcName.QualifiedName,
        newIdx: destination.index,
      },
      optimisticResponse: () => {
        const reordered = Array.from(module.functionSpecs);
        reordered.splice(source.index, 1);
        reordered.splice(destination.index, 0, functionSpec);

        const changedSpec = JSON.parse(JSON.stringify(spec)) as SnapshotSpec;
        const changedModule = changedSpec.modules.find(
          (m) => m.name === module.name,
        );

        if (changedModule) {
          changedModule.functionSpecs = [...reordered];
        }

        return {
          moveFunctionToIndex: {
            ...changedSpec,
          },
        };
      },
    });
  };

  return (
    <TableContainer>
      <Table color="secondary">
        <TableHead>
          <TableRow>
            <TableCell sx={{width: "33%"}}>Function</TableCell>
            <TableCell sx={{width: "33%"}}>Snapshot Data</TableCell>
            <TableCell sx={{width: "33%"}}>Events</TableCell>
          </TableRow>
        </TableHead>

        <DraggableTable
          onDragEnd={onDragEnd}
          items={module.functionSpecs ?? []}
          draggableIdPath="funcName.QualifiedName"
          renderRow={({row, dragHandle, rowRef, draggableProps}) => (
            <FunctionSpecCard
              asTableRow={{
                dragHandle,
                rowRef,
                draggableProps,
              }}
              key={row.funcName.QualifiedName}
              functionSpec={row}
              showHeader={true}
              binaryID={binaryID ?? (() => void promptForBinarySelection())}
            />
          )}
        />
      </Table>
    </TableContainer>
  );
};
