import React from "react";
import {
  FilteringOptionType,
  FilteringSuggestion,
  FunctionName,
} from "@graphql/graphql.ts";

export const suggestionCategoryStrings = new Map([
  [FilteringOptionType.Package, "pkg"],
  [FilteringOptionType.PackagePrefix, "pkg"],
  [FilteringOptionType.Type, "type"],
  [FilteringOptionType.Function, "func"],
  [FilteringOptionType.Variable, "var"],
  [FilteringOptionType.BinaryId, "bin"],
  [FilteringOptionType.ProgramName, "prog"],
  [FilteringOptionType.GoroutineStatus, "status"],
]);
export const categoryPrefixes = listCategoryPrefixes();
export const queryPrefixToSuggestionType = listQueryPrefixes();

function listCategoryPrefixes(): Map<string, FilteringOptionType[]> {
  const res = new Map<string, FilteringOptionType[]>();
  for (const [category, query] of suggestionCategoryStrings) {
    for (let i = 0; i <= query.length; i++) {
      const prefix = query.substring(0, i);
      const list = res.get(prefix) ?? ([] as FilteringOptionType[]);
      list.push(category);
      res.set(prefix, list);
    }
  }
  return res;
}

function listQueryPrefixes(): Map<string, FilteringOptionType[]> {
  const res = new Map<string, FilteringOptionType[]>();
  for (const [category, s] of suggestionCategoryStrings) {
    const l = res.get(s) ?? ([] as FilteringOptionType[]);
    l.push(category);
    res.set(s, l);
  }
  return res;
}

export function suggestionCategoryToString(type: FilteringOptionType): string {
  const res = suggestionCategoryStrings.get(type);
  if (res == undefined) {
    throw new Error("unexpected suggestion type " + type);
  }
  return res;
}

function includes(a: string, b: string, caseSensitive: boolean): boolean {
  if (caseSensitive) {
    return a.includes(b);
  } else {
    return a.toLowerCase().includes(b.toLowerCase());
  }
}

export class highlightedString {
  parts: string[];
  highlight: boolean[];

  constructor() {
    this.parts = [];
    this.highlight = [];
  }

  static fromString(s: string): highlightedString {
    const r = new highlightedString();
    if (s != "") {
      r.parts.push(s);
      r.highlight.push(false);
    }
    return r;
  }

  static allHighlighted(s: string): highlightedString {
    const r = new highlightedString();
    r.parts.push(s);
    r.highlight.push(true);
    return r;
  }

  hasHighlight(): boolean {
    return this.highlight.includes(true);
  }

  endHighlighted(): boolean {
    return this.highlight[this.highlight.length - 1];
  }

  raw(): string {
    return this.parts.join("");
  }

  append(s: string | highlightedString): highlightedString {
    if (typeof s == "string") {
      this.parts.push(s);
      this.highlight.push(false);
    } else {
      this.parts = this.parts.concat(s.parts);
      this.highlight = this.highlight.concat(s.highlight);
    }
    return this;
  }

  html() {
    const res = [] as React.JSX.Element[];
    for (let i = 0; i < this.parts.length; i++) {
      const part = this.parts[i];
      const highlighted = this.highlight[i];
      if (highlighted) {
        res.push(
          <span key={i} className="text-highlight">
            {part}
          </span>,
        );
      } else {
        res.push(<span key={i}>{part}</span>);
      }
    }
    return <>{...res}</>;
  }
}

// highlightPart splits string `a` into between 1 and 3 parts:
// - the part before `b`, if any (not highlighted)
// - `b`, if present (highlighted)
// - the part after `b`, if any (not highlighted)
//
// If `a` does not contain `b`, a single non-highlighted part is returned.
export function highlightPart(
  a: string,
  b: string,
  caseSensitive: boolean,
): highlightedString {
  if (b == "") {
    return highlightedString.fromString(a);
  }

  const origA = a;
  if (!caseSensitive) {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }
  const idx = a.indexOf(b);
  if (idx == -1) {
    return highlightedString.fromString(origA);
  }
  const res = new highlightedString();
  if (idx > 0) {
    res.parts.push(origA.substring(0, idx));
    res.highlight.push(false);
  }
  res.parts.push(origA.substring(idx, idx + b.length));
  res.highlight.push(true);
  if (idx + b.length < a.length) {
    res.parts.push(origA.substring(idx + b.length));
    res.highlight.push(false);
  }
  return res;
}

function highlightEnd(a: string, suffixLength: number): highlightedString {
  const res = new highlightedString();
  res.parts.push(a.substring(0, a.length - suffixLength));
  res.highlight.push(false);
  res.parts.push(a.substring(a.length - suffixLength));
  res.highlight.push(true);
  return res;
}

export class typeMatch {
  pkg: highlightedString;
  type: highlightedString;

  constructor() {
    this.pkg = new highlightedString();
    this.type = new highlightedString();
  }

  html(): React.JSX.Element {
    return (
      <>
        <span className="text-muted">pkg:</span>
        {this.pkg.html()}

        <span className="text-muted" style={{marginLeft: 5}}>
          type:
        </span>
        {this.type.html()}
      </>
    );
  }
}

export class funcMatch {
  pkg: highlightedString;
  type: highlightedString;
  func: highlightedString;

  constructor(pkg?: string, type?: string, funcName?: string) {
    this.pkg = pkg
      ? highlightedString.fromString(pkg)
      : new highlightedString();
    this.type = type
      ? highlightedString.fromString(type)
      : new highlightedString();
    this.func = funcName
      ? highlightedString.fromString(funcName)
      : new highlightedString();
  }

  html(): React.JSX.Element {
    return (
      <>
        <span className="text-muted">pkg:</span>
        {this.pkg.html()}

        {this.type.raw() != "" && (
          <>
            <span className="text-muted" style={{marginLeft: 5}}>
              type:
            </span>
            {this.type.html()}
          </>
        )}

        <span className="text-muted" style={{marginLeft: 5}}>
          func:
        </span>
        {this.func.html()}
      </>
    );
  }
}

// packagePrefixes returns all the prefixes of a package name, as defined by the path elements.
// E.g. "a/b/c" -> ["a", "a/b", "a/b/c"].
function packagePrefixes(pkg: string): string[] {
  const res = [] as string[];
  const pkgParts = pkg.split("/");
  for (let i = 1; i < pkgParts.length + 1; i++) {
    const prefix = pkgParts.slice(0, i).join("/");
    res.push(prefix);
  }
  return res;
}

// matchPackagePrefixes returns suggestions for package prefixes matching
// pkgName and query. A package prefix matches the query if the prefix ends with
// the query. For example, for pkgName="foo/bar/baz/car" and query "ar" or
// "ar/...", the returned suggestions correspond to "foo/bar/..." and
// "foo/bar/baz/car/...". On the other hand, the query "ba" would not have any
// matches.
function matchPackagePrefixes(
  pkgName: string,
  query: string,
  categoriesInfo: suggestionCategoriesInfo,
  // pkgPrefixSuggestions is used to avoid suggesting the same package prefix
  // multiple times -- prefixes that are already in the set are not returned,
  // and results are added to the set.
  pkgPrefixSuggestions: Set<string>,
): ScoredSuggestion[] {
  const matchingPrefixes = suggestPackagePrefixes(pkgName, query);
  const res: ScoredSuggestion[] = [];
  for (const [prefix, highlighted] of matchingPrefixes) {
    if (pkgPrefixSuggestions.has(prefix)) {
      continue;
    }
    pkgPrefixSuggestions.add(prefix);
    const s = new ScoredSuggestion({
      Category: FilteringOptionType.PackagePrefix,
      Package: prefix,
    });
    s.suggestionCategory = categoriesInfo.categoryLabels.get(
      FilteringOptionType.PackagePrefix,
    )!;
    s.match = highlighted;
    s.score = 20;
    res.push(s);
  }
  return res;
}

// suggestPackagePrefixes returns suggestions for package prefixes matching
// pkgName and query. A package prefix matches the query if the prefix ends with
// the query. For example, for pkgName="foo/bar/baz/car" and query "ar" or
// "ar/...", the returned suggestions correspond to "foo/bar/..." and
// "foo/bar/baz/car/...". On the other hand, the query "ba" would not have any
// matches.
//
// The return values consist of the package prefix, plus a highlightedString
// string made of the prefix + "/...". For example, for the "ar" query, it'd
// return [["foo/bar", "foo/bar/..."], ["foo/bar/baz/car",
// "foo/bar/baz/car/..."]] with the corresponding highlighting on the second
// components.
export function suggestPackagePrefixes(
  pkgName: string,
  query: string,
): [string, highlightedString][] {
  const suffixes = ["/", "/.", "/..", "/..."];
  const slashIdx = query.lastIndexOf("/");
  let pkgQuery: string, suffix: string;
  if (slashIdx != -1) {
    pkgQuery = query.substring(0, slashIdx);
    suffix = query.substring(slashIdx);
    if (!suffixes.includes(suffix)) {
      return [];
    }
  } else {
    pkgQuery = query;
    suffix = "";
  }

  const prefixes = packagePrefixes(pkgName);
  const matchingPrefixes = prefixes.filter((p) => {
    if (!p.endsWith(pkgQuery)) {
      return false;
    }
    // Don't return matches that are too short unless the last path element of
    // the package name is matched completely.
    return query.length > 2 || splitOnLast(p, "/")[1] == pkgQuery;
  });
  return matchingPrefixes.map((pkgPrefix) => [
    pkgPrefix,
    highlightEnd(pkgPrefix, pkgQuery.length).append(
      highlightPart("/...", suffix, false),
    ),
  ]);
}

// matchPackage matches a package name against a query, returning zero, one, or
// more scored suggestions if there is a match. One suggestion could have
// category PACKAGE and others might be PACKAGE_PREFIX (there can be multiple
// prefixes generated).
export function matchPackage(
  pkgName: string,
  query: string,
  baseScore: number,
  categoriesInfo: suggestionCategoriesInfo,
  pkgPrefixSuggestions: Set<string>,
): ScoredSuggestion[] {
  const caseSensitive = query.toLowerCase() != query;
  const res = matchPackagePrefixes(
    pkgName,
    query,
    categoriesInfo,
    pkgPrefixSuggestions,
  );

  if (!includes(pkgName, query, caseSensitive)) {
    return res;
  }

  const s = new ScoredSuggestion({
    Category: FilteringOptionType.Package,
    Package: pkgName,
  });
  s.match = highlightPart(pkgName, query, caseSensitive);
  s.score = baseScore;
  s.suggestionCategory = categoriesInfo.categoryLabels.get(
    FilteringOptionType.Package,
  )!;
  if (s.match.hasHighlight()) {
    s.score += 10;
    if (s.match.endHighlighted()) {
      s.score += 10;
    }
  }
  if (s.score > 0) {
    res.push(s);
  }
  return res;
}

// matchFunctionName matches a function name (i.e. pkg/type/function) against a
// query, returning the score of the match and information about which parts of
// the name matched which parts of the query. If there is no match, the score is
// 0 and a funcMatch with no highlighted parts is returned.
export function matchFunctionName(
  func: FunctionName,
  query: string,
  caseSensitive?: boolean,
): [funcMatch, number] {
  const funcParts = [func.Package, func.Type, func.Name];

  // Parse the query into <pkg>.<type>.<func>. The <pkg> part is greedy,
  // consuming all remaining dots.
  const [prefixQuery, funcNameQuery] = splitOnLast(query, ".");
  const [pkgQuery, typeQuery] = splitOnLast(prefixQuery, ".");

  const queryParts = [] as string[];
  if (pkgQuery != "") {
    queryParts.push(pkgQuery);
  }
  if (typeQuery != "") {
    queryParts.push(typeQuery);
  }
  if (funcNameQuery != "") {
    queryParts.push(funcNameQuery);
  }

  if (caseSensitive == undefined) {
    caseSensitive = query.toLowerCase() != query;
  }
  const [_ok, highlightedParts] = partsMatch(
    funcParts,
    queryParts,
    caseSensitive,
  );
  const match = new funcMatch();
  [match.pkg, match.type, match.func] = highlightedParts;
  let score = 0;
  if (match.pkg.hasHighlight()) {
    score += 5;
    if (match.type.endHighlighted()) {
      score += 5;
    }
  }
  if (match.type.hasHighlight()) {
    score += 7;
    if (match.type.endHighlighted()) {
      score += 7;
    }
  }
  if (match.func.hasHighlight()) {
    score += 10;
    if (match.func.endHighlighted()) {
      score += 10;
    }
  }
  return [match, score];
}

// matchType matches a type name (i.e. pkg/type) against a query, returning the
// score of the match and information about which parts of the name matched
// which parts of the query. If there is no match, the score is 0 and a
// typeMatch with no highlighted parts is returned.
export function matchTypeName(
  pkgName: string,
  typeName: string,
  query: string,
  caseSensitive?: boolean,
): [typeMatch, number] {
  const typeParts = [pkgName, typeName];

  // Parse the query into <pkg>.<type>. The <pkg> part is greedy, consuming all
  // remaining dots.
  const [pkgQuery, typeQuery] = splitOnLast(query, ".");

  const queryParts = [] as string[];
  if (pkgQuery != "") {
    queryParts.push(pkgQuery);
  }
  if (typeQuery != "") {
    queryParts.push(typeQuery);
  }

  if (caseSensitive == undefined) {
    caseSensitive = query.toLowerCase() != query;
  }
  const [_ok, matches] = partsMatch(typeParts, queryParts, caseSensitive);
  const match = new typeMatch();
  [match.pkg, match.type] = matches;
  let score = 0;
  if (match.pkg.hasHighlight()) {
    score += 5;
    if (match.pkg.endHighlighted()) {
      score += 5;
    }
  }
  if (match.type.hasHighlight()) {
    score += 7;
    if (match.type.endHighlighted()) {
      score += 7;
    }
  }
  return [match, score];
}

// matchVariable matches a function and variable expression against a query,
// returning the details of the match and the score. If there is no match,
// [undefined, 0] is returned.
//
// Supported queries look like:
// foo.bar.baz:s.id=123 -> complete query explicitly specifying function, variable and value
// s=123  -> explicit variable and value, no function
// bar:id -> explicit function (or type or pkg) and variable, no value
// s.id   -> either function or variable, no value
// baz    -> either function or variable, no value
export function matchVariable(
  func: FunctionName,
  varExpr: string,
  query: string,
): [varMatch, number] {
  const caseSensitive = query.toLowerCase() != query;

  let explicitFunc = false,
    explicitVar = false;
  // If we have a query like "foo:bar", what comes before the colon needs to
  // match the function name (including pkg and type).
  const tmp = prefixAndRest(query, ":");
  // Destructure tmp into a variable and a constant, in order to appease the linter.
  let [funcQuery] = tmp;
  const [, rest] = tmp;
  if (funcQuery != "") {
    explicitFunc = true;
  }
  // If we have a query like "...=123", what comes before the equals sign needs
  // to match the variable name.
  const [varQuery, valueQuery] = splitOnFirst(rest, "=");
  if (valueQuery != "") {
    explicitVar = true;
  }

  // A query without delimiters, e.g. "foo", will be parsed as varQuery="foo",
  // funcQuery="", valueQuery="". "foo" might refer to either a variable expression
  // or a function name. We'll try to match both.
  if (!explicitFunc && !explicitVar) {
    funcQuery = varQuery;
  }

  const [fnMatch, funcScore] = matchFunctionName(func, funcQuery);

  const vMatch = new varMatch(
    fnMatch,
    highlightPart(varExpr, varQuery, caseSensitive),
    highlightedString.allHighlighted(valueQuery),
  );
  let score: number;
  if (explicitFunc && explicitVar) {
    // They both have to match.
    if (funcScore == 0 || !vMatch.varExpr.hasHighlight()) {
      score = 0;
    } else {
      score = funcScore * 10;
    }
  } else {
    score = funcScore;
    if (vMatch.varExpr.hasHighlight()) {
      score = 10;
    }
  }
  return [vMatch, score];
}

export class varMatch {
  funcMatch: funcMatch | undefined;
  varExpr: highlightedString;
  varValue: highlightedString;

  constructor(
    funcMatch: funcMatch,
    varExpr: highlightedString,
    varValue: highlightedString,
  ) {
    this.funcMatch = funcMatch;
    this.varExpr = varExpr;
    this.varValue = varValue;
  }

  html(): React.JSX.Element {
    return (
      <>
        {this.funcMatch!.html()}

        <span className="text-muted" style={{marginLeft: 5}}>
          var:
        </span>
        {this.varExpr.html()}

        {this.varValue.raw() != "" && (
          <>
            <span className="text-highlight">=</span>
            {this.varValue.html()}
          </>
        )}
      </>
    );
  }
}

// partsMatch takes a list of function parts (e.g. ["github.com/mypkg",
// "myType", "myFunc"]) and a list of query parts (e.g. ["mypk", "myFunc"]) and
// the function parts with highlights for the parts that match the query parts.
// A query part can match only one function part. If there are more function
// parts than query parts some function parts will be skipped. If the query
// parts cannot be matched to the function parts, the boolean return value is
// false and all returned strings are un-highlighted.
function partsMatch(
  funcParts: string[],
  queryParts: string[],
  caseSensitive: boolean,
): [boolean, highlightedString[]] {
  const matches = partsMatchInner(funcParts, queryParts, 0, 0, caseSensitive);
  const res = [] as highlightedString[];
  if (matches == undefined) {
    for (const p of funcParts) {
      res.push(highlightedString.fromString(p));
    }
    return [false, res];
  }
  let nextMatch = 0;
  for (let i = 0; i < funcParts.length; i++) {
    if (nextMatch < matches.length && matches[nextMatch] == i) {
      res.push(
        highlightPart(funcParts[i], queryParts[nextMatch], caseSensitive),
      );
      nextMatch++;
    } else {
      res.push(highlightedString.fromString(funcParts[i]));
    }
  }
  return [true, res];
}

function partsMatchInner(
  funcParts: string[],
  queryParts: string[],
  i: number,
  j: number,
  caseSensitive: boolean,
): number[] | undefined {
  const funcToMatch = funcParts.length - i;
  const queryToMatch = queryParts.length - j;
  if (queryToMatch == 0) {
    return []; // success, we've found a match.
  }
  if (funcToMatch < queryToMatch) {
    return undefined;
  }

  // Look for the funcPart matching queryParts[j].
  for (let k = i; k < funcParts.length; k++) {
    if (includes(funcParts[k], queryParts[j], caseSensitive)) {
      const restOfMatch = partsMatchInner(
        funcParts,
        queryParts,
        k + 1,
        j + 1,
        caseSensitive,
      );
      if (restOfMatch != undefined) {
        return [k, ...restOfMatch];
      }
    }
  }
  return undefined;
}

function splitOnFirst(s: string, sep: string): [string, string] {
  const idx = s.indexOf(sep);
  if (idx == -1) {
    return [s, ""];
  }
  return [s.substring(0, idx), s.substring(idx + sep.length)];
}

function prefixAndRest(s: string, sep: string): [string, string] {
  const idx = s.indexOf(sep);
  if (idx == -1) {
    return ["", s];
  }
  return [s.substring(0, idx), s.substring(idx + sep.length)];
}

function splitOnLast(s: string, sep: string): [string, string] {
  const idx = s.lastIndexOf(sep);
  if (idx == -1) {
    return ["", s];
  }
  return [s.substring(0, idx), s.substring(idx + sep.length)];
}

export class suggestionCategoriesInfo {
  // If a category is explicitly specified, then we only show suggestions of
  // that category. If a category is not explicitly requested (but the query is
  // a prefix of one or more categories) then we show all suggestions that match
  // the query, plus all suggestions from categories that match.
  suggestionCategories: FilteringOptionType[];
  explicitCategories: boolean;
  categoryLabels: Map<FilteringOptionType, highlightedString>;

  constructor(
    suggestionCategories: FilteringOptionType[],
    explicitCategories: boolean,
  ) {
    this.suggestionCategories = suggestionCategories;
    this.explicitCategories = explicitCategories;
    this.categoryLabels = new Map();
    for (const [cat, _] of suggestionCategoryStrings) {
      this.categoryLabels.set(
        cat,
        highlightedString.fromString(suggestionCategoryToString(cat)),
      );
    }
  }
}

// ScoredSuggestion represents a FilteringSuggestion that has been scored
// against a query.
export class ScoredSuggestion {
  // The suggestion that was scored against a query.
  suggestion: FilteringSuggestion;
  // The score of the match. 0 if the suggestion does not match the query.
  score: number;
  // The details of the match. Even if the suggestion does not match the query (i.e. score==0),
  // match will still be set to the non-highlighted representation of the suggestion.
  match: varMatch | funcMatch | typeMatch | highlightedString | undefined;
  // The type of the suggestion, e.g. "pkg", "type", "func", "var".
  suggestionCategory: highlightedString;

  constructor(s: FilteringSuggestion) {
    this.suggestion = s;
    this.score = 0;
    // Initialize the suggestionType. It might be overwritten later if anything needs to be
    // highlighted.
    this.suggestionCategory = highlightedString.fromString(
      suggestionCategoryToString(s.Category),
    );
  }

  getLabel(): string {
    const f = this.suggestion;
    switch (f.Category) {
      case FilteringOptionType.Package:
        return f.Package!;
      case FilteringOptionType.PackagePrefix:
        return "prefix: " + f.Package!;
      case FilteringOptionType.Type:
        return `${f.Package!} : ${f.TypeName!}`;
      case FilteringOptionType.Function:
        return f.FuncName!.QualifiedName;
      case FilteringOptionType.Variable:
        return f.FuncName!.QualifiedName + " - " + f.VarExpr;
      case FilteringOptionType.BinaryId:
        return f.BinaryName!;
      case FilteringOptionType.ProgramName:
        return f.ProgramName!;
      case FilteringOptionType.GoroutineStatus:
        return f.GoroutineStatus!;
      default:
        throw new Error("unexpected suggestion type " + f.Category);
    }
  }
}
