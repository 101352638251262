import {AgentReportsContext} from "src/providers/agent-reports-provider.tsx";
import React, {useContext, useEffect, useState} from "react";
import {Box, List, Stack} from "@mui/material";
import {jsonMapReplacer, SessionStorageKeys} from "src/util/local-storage.ts";
import {defaultSelectionForEnvironment} from "./helpers/defaultSelectionForEnvironment.function.ts";
import {ProcessHierarchy} from "./helpers/processHierarchy.class.ts";
import {EnvironmentSelectionState} from "./helpers/environmentSelectionState.class.ts";
import {selectionFromSessionStorage} from "./helpers/select-from-session-storage.ts";
import {ProcessInfo} from "./@types.ts";
import {syncSelectionWithReport} from "./helpers/syncSelectionWithReport.function.ts";
import {ProgramSelector} from "./components/ProgramSelector.tsx";
import {ProcessSelectorNoData} from "./components/ProcessSelectorNoData.tsx";
import {ProcessSelectorEnvironment} from "./components/ProcessSelectorEnvironment.tsx";
import {NO_ENV_NAME} from "../../constants/no-env-name.ts"; // ProcessSelector renders the interface for selecting an environment and

type Props = {
  // If set, this key will be used to initialize the selection options; any
  // changes to the selection are saved to this key.
  initialSessionStorageKey?: SessionStorageKeys;
  // Callback called whenever the selection changes. A value of undefined means
  // that there's no valid selection.
  onSelectionUpdated: (
    newSelection: EnvironmentSelectionState | undefined,
  ) => void;
};

// ProcessSelector renders the interface for selecting an environment and
// programs/processes out of the options presented in agent reports.
export function ProcessSelector(props: Props): React.JSX.Element {
  const agentReports = useContext(AgentReportsContext);
  // selectionStatus will track all the user-selected state in this component:
  // what environment is selected and which programs/processes within that
  // environment are selected.
  let [selectionStatus, setSelectionStatusInner] = useState<
    EnvironmentSelectionState | undefined
  >(() => {
    if (props.initialSessionStorageKey) {
      const selection = selectionFromSessionStorage(
        props.initialSessionStorageKey,
      );
      return selection;
    }
    return undefined;
  });

  function setSelectionStatus(
    newStatus: EnvironmentSelectionState | undefined,
  ) {
    setSelectionStatusInner(newStatus);
    if (props.initialSessionStorageKey && newStatus != undefined) {
      // We don't overwrite the session storage with an undefined value.
      sessionStorage.setItem(
        props.initialSessionStorageKey,
        JSON.stringify(newStatus, jsonMapReplacer),
      );
    }
  }

  // Process the reports into a processHierarchy.
  const procHierarchy = agentReports
    ? new ProcessHierarchy(agentReports.Report.Reports)
    : undefined;

  // Reconcile the selection with the report, in case the report has changed.
  const newSelection = syncSelectionWithReport(procHierarchy, selectionStatus);
  // If newSelection is not undefined, we can rely on newSelection.environment
  // existing in the report and containing the programs referenced in
  // newSelection.programSelection (if any).

  // Whenever the selection changes, notify the parent.
  useEffect(() => {
    props.onSelectionUpdated(newSelection);
  }, [newSelection]);

  // Deal with the edge cases where the report either hasn't loaded yet, or is
  // empty.

  if (
    agentReports == undefined || // report not available yet
    agentReports.NoAgentsReporting || // no agents connected
    agentReports.NoProgramsConfigured || // no programs configured
    procHierarchy!.empty() // no processes reported
  ) {
    return (
      <Stack justifyContent="center" flexWrap="nowrap">
        <ProcessSelectorNoData agentReports={agentReports} />
      </Stack>
    );
  }

  // Keep track of the updated selection in the state. We'll also continue
  // rendering with the new selection.
  if (newSelection !== selectionStatus) {
    setSelectionStatus(newSelection);
    selectionStatus = newSelection;
  }

  const report = procHierarchy!;

  type envOption = "none" | typeof NO_ENV_NAME | string;

  const selectedEnv: envOption =
    selectionStatus == undefined
      ? "none"
      : selectionStatus.environment == undefined
        ? NO_ENV_NAME
        : selectionStatus.environment;

  const programSelection = selectionStatus?.programsSelection;

  const processesInSelectedEnv = selectionStatus
    ? report.environments.get(
        selectedEnv == NO_ENV_NAME ? undefined : selectedEnv,
      )
    : undefined;

  if (selectionStatus != undefined && processesInSelectedEnv == undefined) {
    throw new Error("unexpected undefined processes when there is a selection");
  }

  // We will show the environment selector if environments are in use (i.e. any
  // environments have been reported).
  const showEnvSelector =
    report.multipleEnvironments() || report.singleEnvironment() != undefined;

  function onEnvironmentSelectionChange(newEnv: string | undefined) {
    // Make a new selection comprised of everything in the newly-selected
    // environment.
    setSelectionStatus(defaultSelectionForEnvironment(report, newEnv));
  }

  // onProgramToggle toggles the checked state of all processes for one program.
  function onProgramToggle(programName: string, checked: boolean) {
    setSelectionStatus(
      selectionStatus!.setProgramSelection(
        programName,
        checked ? "all" : "none",
      ),
    );
  }

  const onProcessToggle = (process: ProcessInfo, checked: boolean) => {
    setSelectionStatus(
      selectionStatus!.setProcessSelection(process, checked, report),
    );
  };

  return (
    <Box sx={{minHeight: "100px"}}>
      {showEnvSelector && (
        <ProcessSelectorEnvironment
          onEnvironmentSelectionChange={onEnvironmentSelectionChange}
          selectedEnv={selectedEnv}
          report={report}
        />
      )}

      {programSelection && (
        <List dense={true}>
          {Array.from(programSelection).map(
            ([programName, programSelectionStatus]) => (
              <ProgramSelector
                key={programName}
                programName={programName}
                selectionStatus={programSelectionStatus}
                processes={processesInSelectedEnv!.programs.get(programName)!}
                onProgramToggle={(checked) =>
                  onProgramToggle(programName, checked)
                }
                onProcessToggle={onProcessToggle}
              />
            ),
          )}
        </List>
      )}
    </Box>
  );
}
