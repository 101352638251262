import React, {useState} from "react";
import {Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import {IconCopy} from "./icons/IconCopy.tsx";

export function ClipboardCopy({copyText}: {copyText: string}) {
  const [recentlyCopied, setRecentlyCopied] = useState(false);

  const handleClick = () => {
    void navigator.clipboard.writeText(copyText).then(() => {
      setRecentlyCopied(true);
      setTimeout(() => {
        setRecentlyCopied(false);
      }, 2000);
    });
  };

  return (
    <Tooltip title={"Copy to clipboard"}>
      {recentlyCopied ? (
        <CheckIcon fontSize={"small"} color={"success"} sx={{height: 24}} />
      ) : (
        <SvgIcon
          onClick={handleClick}
          sx={{
            ":hover *": {
              transition: "all 0.1s ease-in-out",
              stroke: (theme) => theme.palette.secondary.main,
            },
            cursor: "pointer",
          }}
        >
          <IconCopy />
        </SvgIcon>
      )}
    </Tooltip>
  );
}
