import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiFormControlLabel = (): Pick<
  Components<typeof theme>,
  "MuiFormControlLabel"
> => ({
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        // for some reason switch inside the FormControlLabel crosses the edge
        marginLeft: 0,
      },
    },
  },
});
