import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiTextField = (): Pick<
  Components<typeof theme>,
  "MuiTextField"
> => ({
  MuiTextField: {
    variants: [
      {
        props: {datatype: "date"},
        style: ({theme}) => ({
          ".MuiInputBase-root": {
            flexDirection: "row-reverse",
          },
          ".MuiButtonBase-root": {
            color: theme.palette.info.main,

            "&.clearButton": {
              opacity: 1,
            },
          },
          ".MuiInputAdornment-root:has(.clearButton)": {
            order: -1,
          },
        }),
      },
    ],
  },
});
