import {Box} from "@mui/material";
import IconWelcome from "../../assets/icons/IconWelcome.svg";
import IconSideEye from "../../assets/icons/IconSideEye.svg";
import React from "react";

export const IconWelcomeScreen: React.FC = () => {
  return (
    <Box position="relative">
      <img src={IconWelcome} alt="Welcome" width="100%" />
      <img
        src={IconSideEye}
        alt="SideEye"
        width="60%"
        style={{
          position: "absolute",
          left: "50%",
          top: "10%",
          transform: "translateX(-50%)",
        }}
      />
    </Box>
  );
};
