import React, {useContext} from "react";
import {signOutFromAuth} from "../helpers.ts";
import {Button} from "@mui/material";
import {AppConfigContext} from "../../../providers/app-config-provider.tsx";
import {useApolloClient} from "@apollo/client";
import {IconExit} from "../../../components/icons/IconExit.tsx";

export const LogoutButton: React.FC = () => {
  const appCfg = useContext(AppConfigContext);
  const client = useApolloClient();

  return (
    <Button
      variant={"contained"}
      onClick={() => {
        void signOutFromAuth(appCfg.Auth!, client); //client
      }}
      size="large"
      startIcon={<IconExit />}
    >
      Log out
    </Button>
  );
};
