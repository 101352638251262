import {ApolloClient} from "@apollo/client";
import {
  DELETE_LABEL_RULE,
  GET_ALL_PROCESSES,
  GET_LABEL_RULES,
} from "../gqlHelper.ts";

export async function deleteLabelRule(client: ApolloClient<any>, id: number) {
  const {errors} = await client.mutate({
    mutation: DELETE_LABEL_RULE,
    variables: {
      id,
    },
    refetchQueries: [GET_LABEL_RULES, GET_ALL_PROCESSES],
  });
  return errors;
}
