import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import {getLabelValue, Label} from "../../../util/labels.ts";
import {Link as ReactLink} from "react-router-dom";
import React from "react";
import {
  AgentReport,
  AgentReportsResult,
} from "../../../providers/agent-reports-provider.tsx";
import {DeepPartial} from "@apollo/client/utilities";
import {BinaryDetails} from "../../../__generated__/graphql.ts";
import {
  BinariesArgs,
  KnownBinary,
  UnknownBinary,
} from "../../Binaries/Binaries.tsx";
import {updateBinariesFilter} from "../../Binaries/gqlHelper.ts";

type Props = {
  reports: AgentReport["Report"]["Reports"];
};

export const TableAgents: React.FC<Props> = ({reports}) => {
  return (
    <TableContainer sx={{maxHeight: 500}}>
      <Table color="secondary" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{minWidth: 160}}>Type</TableCell>
            <TableCell sx={{minWidth: 240}}>Host</TableCell>
            <TableCell sx={{minWidth: 240}}>IP Addresses</TableCell>
            <TableCell sx={{minWidth: 240}}>Agent Version</TableCell>
            <TableCell sx={{minWidth: 300}}>Monitored processes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((agent) => (
            <TableRow key={agent.Hostname}>
              <TableCell
                sx={{
                  /*TODO: this color hardcoded - need to be a palette for agent type */
                  color: "#EB00FF",
                }}
              >
                Agent
              </TableCell>
              <TableCell>{agent.Hostname}</TableCell>
              <TableCell>
                {agent.IPAddresses?.length ? agent.IPAddresses.join(", ") : "-"}
              </TableCell>
              <TableCell>{agent.AgentVersion}</TableCell>
              <TableCell>
                {agent.Processes.map((proc) => (
                  <div key={proc.PID}>
                    {getLabelValue(Label.Program, proc.Labels)}
                    &nbsp; (PID {proc.PID}, binary&nbsp;
                    <Link
                      component={ReactLink}
                      to={{
                        pathname: "../binaries",
                        search: argsForBinariesPage(proc),
                      }}
                    >
                      {binaryName(proc.Binary)}
                      {proc.Binary.__typename === "UnknownBinaryInfo" && (
                        <span>(unknown binary)</span>
                      )}
                    </Link>
                    )
                  </div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// binaryName returns a name for the binary. If the binary is known, this is the
// name that was assigned to it. If the binary is unknown, this is the suggested
// name.
function binaryName(binary: DeepPartial<BinaryDetails>): string {
  if (binary.__typename === "Binary") {
    return binary.userName!;
  } else if (binary.__typename === "UnknownBinaryInfo") {
    return binary.SuggestedName!;
  }
  return "unknown";
}

function argsForBinariesPage(
  proc: AgentReportsResult["Reports"][0]["Processes"][0],
): string {
  const binary = proc.Binary;
  let b: KnownBinary | UnknownBinary;
  if (binary.__typename === "Binary") {
    b = {
      type: "known-binary",
      id: binary.id,
    };
  } else if (binary.__typename === "UnknownBinaryInfo") {
    b = {
      type: "unknown-binary",
      id: binary.Hash,
      suggestedName: binary.SuggestedName,
      programs: [getLabelValue(Label.Program, proc.Labels)!],
    };
  } else {
    throw new Error(`unknown binary type ${binary}`);
  }
  const args: BinariesArgs = {
    binary: b,
  };
  const searchParams = updateBinariesFilter(new URLSearchParams(), args);
  return searchParams.toString();
}
