import {Components} from "@mui/material/styles/components";
import {theme} from "./theme.ts";

export const MuiCardContent = (): Pick<
  Components<typeof theme>,
  "MuiCardContent"
> => ({
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 24,
      },
    },
  },
});
