import {StringParamUpdater} from "../../util/url.ts";
import {gql} from "../../__generated__";

type snapshotsParamUpdaters = {
  env: StringParamUpdater;
};

export function makeSnapshotsParamUpdaters(): snapshotsParamUpdaters {
  return {
    env: new StringParamUpdater("env"),
  };
}

export const GET_SNAPSHOTS = gql(/* GraphQL */ `
  query GetSnapshots($input: GetSnapshotsInput!) {
    getSnapshots(input: $input) {
      id
      captureTime
      environment
      programs
      user {
        id
        firebaseUID
        name
        photoURL
        email
      }
    }
  }
`);

export const DELETE_SNAPSHOT = gql(/* GraphQL */ `
  mutation DeleteSnapshot($id: ID!) {
    deleteSnapshot(id: $id)
  }
`);

export const DELETE_LOG = gql(/* GraphQL */ `
  mutation DeleteLog($id: ID!) {
    deleteLog(id: $id)
  }
`);

export const GET_ENVIRONMENTS = gql(/* GraphQL */ `
  query GetEnvironments {
    getEnvironments
  }
`);

export const GET_LOGS = gql(/* GraphQL */ `
  query GetLogs($input: GetLogsInput!) {
    getLogs(input: $input) {
      id
      startTime
      environment
      status
      user {
        id
        firebaseUID
        name
        photoURL
        email
      }
    }
  }
`);
