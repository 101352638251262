import {Stack, Link} from "@mui/material";
import React from "react";
import {AgentReport} from "../../../providers/agent-reports-provider.tsx";

type Props = {
  agentReports: AgentReport | undefined;
};

export const ProcessSelectorNoData: React.FC<Props> = ({agentReports}) => {
  if (agentReports == undefined) {
    return <span>Failed to retrieve the list of agents from server.</span>;
  }

  if (agentReports.NoAgentsReporting) {
    return (
      <span>
        No agents connected.{" "}
        <Link
          href="http://docs.side-eye.io/installing/#installing-the-agent"
          target="_blank"
        >
          Read about
        </Link>{" "}
        how to connect agents.
      </span>
    );
  }

  if (agentReports.NoProgramsConfigured) {
    return (
      <span>
        Agents are connected but no processes are monitored because no programs
        are configured.
        <br />
        Configure program rules on the <Link href={"/agents"}>Agents</Link>{" "}
        page.
        <br />
        <Link
          href="http://docs.side-eye.io/installing/#installing-the-side-eye-agent"
          target="_blank"
        >
          More info
        </Link>
      </span>
    );
  }

  return <Stack>No processes reported by agents.</Stack>;
};
