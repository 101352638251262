import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {v4 as uuidv4} from "uuid";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import {Predicate} from "src/__generated__/graphql.ts";
import {HelpCircle} from "@components/HelpCircle.tsx";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  conjunction: Predicate[];
  // edit dictates if we are in edit mode or view mode.
  edit: boolean;
  // The list of labels that predicates can reference.
  labels: {label: string; note?: string}[];
  // onChange is ignored when edit==false.
  onChange?: (predicates: Predicate[]) => void;
};

// PredicatesConjunctionEditor renders a conjunction of predicates in either
// viewing or editing mode.
export default function PredicatesConjunctionEditor(
  props: Props,
): React.JSX.Element {
  const predicates = props.conjunction;

  return (
    <>
      {predicates.map((predicate) => (
        <ProcessPredicateEditor
          key={predicate.id}
          predicate={predicate}
          labels={props.labels}
          edit={props.edit}
          onDelete={
            // The last predicate cannot be deleted.
            props.conjunction.length <= 1
              ? undefined
              : () => {
                  props.onChange!(
                    props.conjunction.filter((p) => p.id != predicate.id),
                  );
                }
          }
          onChange={(label, valueRegex) => {
            const newPreds = props.conjunction.map((p) =>
              p.id != predicate.id
                ? p
                : {
                    ...p,
                    label: label,
                    valueRegex: valueRegex,
                  },
            );
            props.onChange!(newPreds);
          }}
        />
      ))}

      {props.edit && (
        <Button
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() =>
            props.onChange!([
              ...predicates,
              {id: uuidv4(), label: "", valueRegex: ""},
            ])
          }
        >
          Add predicate
        </Button>
      )}
    </>
  );
}

// ProcessPredicateEditor renders a predicate (label and value regex) in either
// view or edit mode.
function ProcessPredicateEditor(props: {
  // predicate is the predicate to render and edit.
  predicate: Predicate;
  edit: boolean;
  // The list of labels that the predicate can reference.
  labels: {label: string; note?: string}[];
  // onChange is ignored when edit==false.
  onChange?: (label: string, valueRegex: string) => void;
  onDelete?: () => void;
  // onCancel does not have to be specified when editing an existing predicate
  // (as opposed to adding a new one).
  onCancel?: () => void;
}) {
  // Sanity checks.
  if (!props.predicate && !props.edit) {
    throw new Error(
      "ProcessPredicateEditor: editing must be set when adding a new predicate",
    );
  }
  if (props.edit && !props.onChange) {
    throw new Error(
      "ProcessPredicateEditor: onChange must be set when edit is set",
    );
  }

  if (!props.edit) {
    return (
      <Stack direction="row" columnGap={4} flexWrap="wrap" mb={1}>
        <Stack direction="row" minWidth={200} gap={2}>
          <Typography color="secondary" variant="body4">
            Label
          </Typography>
          <Typography color="primary.light" variant="body4">
            {props.predicate.label}
          </Typography>
        </Stack>

        <Stack direction="row" minWidth={200} gap={2}>
          <Typography color="secondary" variant="body4">
            Regex
          </Typography>
          <Typography color="primary.light" variant="body4">
            {props.predicate.valueRegex}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={1.5} flexWrap="nowrap" mb={1.5}>
      <Select
        value={props.predicate?.label ?? ""}
        error={!props.predicate?.label}
        onChange={(e) =>
          props.onChange!(e.target.value, props.predicate?.valueRegex)
        }
        sx={{
          flexGrow: 1,
          minWidth: 400,
          ".MuiSelect-select": {
            display: "flex",
            gap: "10px",
            alignItems: "center",
          },
        }}
        size="medium"
        color="secondary"
        displayEmpty
      >
        <MenuItem value="" disabled sx={{display: "none"}}>
          <Typography variant="error">Label</Typography>
        </MenuItem>
        {props.labels.map(({label, note}, index) => (
          <MenuItem
            key={index + label}
            value={label}
            sx={{display: "flex", alignItems: "center", gap: 1}}
          >
            {label}
            {note && <HelpCircle tip={note} />}
          </MenuItem>
        ))}
      </Select>

      <TextField
        value={props.predicate.valueRegex}
        placeholder="Value Regex"
        onChange={(e) => props.onChange!(props.predicate.label, e.target.value)}
        sx={{width: "50%", minWidth: 400}}
        color="secondary"
        error={!props.predicate.valueRegex}
      />

      {props.edit && props.onDelete && (
        <Tooltip title="Delete predicate">
          <Button color="info" variant="outlined" onClick={props.onDelete}>
            <DeleteIcon />
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
}
