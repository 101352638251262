import React from "react";
import {useSearchParams} from "react-router-dom";
import {
  SkipToken,
  skipToken,
  SuspenseQueryHookOptions,
  useSuspenseQuery,
} from "@apollo/client";
import {
  GetBinariesQuery,
  QueryGetBinariesArgs,
} from "../../__generated__/graphql.ts";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BinaryRow from "./BinaryRow.tsx";
import {
  FILTER_URL_PARAM,
  GET_BINARIES,
  updateBinariesFilter,
} from "./gqlHelper.ts";
import {HelpCircle} from "@components/HelpCircle.tsx";

export type BinariesArgs = {
  // If set, info on only one binary will be displayed.
  binary?: KnownBinary | UnknownBinary;
  // IDs of multiple binaries to display.
  binaries?: string[];
};

export type UnknownBinary = {
  type: "unknown-binary";
  id: string;
  suggestedName: string;
  programs: string[];
};

export type KnownBinary = {
  type: "known-binary";
  id: string;
};

type BinaryInfo = {
  type: "known-binary";
  id: string;
  programs: string[];
};

export default function Binaries() {
  const [queryParams, setQueryParams] = useSearchParams();
  const searchArgs = queryParams.get(FILTER_URL_PARAM);
  let urlParams: BinariesArgs = {};
  if (searchArgs) {
    urlParams = JSON.parse(searchArgs);
  }

  // We'll either retrieve a single binary (if type == 'known-binary'), retrieve
  // no binaries (if type == 'unknown-binary'), retrieve some binaries (if a
  // bunch of ids are passed in), or retrieve all binaries (if args was not
  // passed in).
  let queryArgs:
    | SuspenseQueryHookOptions<GetBinariesQuery, QueryGetBinariesArgs>
    | SkipToken;
  if (urlParams.binary?.type == "known-binary") {
    // Retrieve on particular binary.
    queryArgs = {
      variables: {
        ids: [urlParams.binary.id],
      },
    };
  } else if (urlParams.binary?.type == "unknown-binary") {
    // Nothing to retrieve.
    queryArgs = skipToken;
  } else if (urlParams.binaries) {
    // Retrieve all the specified binaries.
    queryArgs = {
      variables: {
        ids: urlParams.binaries,
      },
    };
  } else {
    // Retrieve all binaries.
    queryArgs = {};
  }
  const {data: binariesResult} = useSuspenseQuery<{getBinaries: BinaryInfo[]}>(
    GET_BINARIES,
    queryArgs,
  );

  let binaries: (BinaryInfo | UnknownBinary)[];
  if (urlParams.binary?.type == "unknown-binary") {
    binaries = [urlParams.binary];
  } else {
    binaries = binariesResult!.getBinaries.map((b) => ({
      ...b,
      type: "known-binary",
    }));
  }

  // Group the binaries by program. Binaries with no programs are grouped under
  // undefined.
  const programToBinaries = new Map<
    string | undefined,
    (BinaryInfo | UnknownBinary)[]
  >();

  for (const b of binaries) {
    if (b.programs.length == 0) {
      const bins = programToBinaries.get(undefined) ?? [];
      bins.push(b);
      programToBinaries.set(undefined, bins);
      continue;
    }
    for (const p of b.programs) {
      const binariesForProgram = programToBinaries.get(p) ?? [];
      binariesForProgram.push(b);
      programToBinaries.set(p, binariesForProgram);
    }
  }

  const onBinaryCreated = (id: string) => {
    // Make the URL match the new binary. If there was an unknown binary, that
    // implies that the filter consisted of only that binary.
    const args: BinariesArgs = {
      binary: {
        type: "known-binary",
        id: id,
      },
    };
    setQueryParams(updateBinariesFilter(queryParams, args));
  };

  const clearFilter = () => {
    setQueryParams(updateBinariesFilter(queryParams, undefined /* filter */));
  };

  return (
    <>
      <Box sx={{my: 3}}>
        <Typography variant="h1">Binaries</Typography>
      </Box>

      <Stack gap={3} pb={4}>
        {Array.from(programToBinaries.entries()).map(([program, binaries]) => (
          <TableContainer key={program ?? "<no program>"} sx={{maxHeight: 400}}>
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell>Program</TableCell>
                  <TableCell sx={{width: "50%"}}>Binaries</TableCell>
                  <TableCell sx={{width: "40px"}}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {program || (
                      <Stack direction="row" gap={1} alignItems="center">
                        {"<no program>"}
                        <HelpCircle
                          tip={`These binaries are not found in any snapshots and are not currently being used by any processes reported by agents.`}
                        />
                      </Stack>
                    )}
                  </TableCell>
                  <TableCell sx={{px: 0}}>
                    <Table sx={{px: 0}}>
                      <TableBody>
                        {binaries.map((binary) => (
                          <BinaryRow
                            key={binary.id}
                            binary={binary}
                            onBinaryCreated={onBinaryCreated}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Stack>
    </>
  );
}
