import {z} from "zod";
import {parse as parseLoseless} from "lossless-json";
import {parseNumber, ProgramCounter} from "../../util/types.ts";
import {FocusedNodeType} from "./FlamegraphState.ts";
import {GoroutineId} from "@graphql/graphql.ts";

export class TreeNode {
  funcName: string;
  type: string;
  value: number;
  complete: string;
  binaryID: string;
  file: string;
  line: number;
  pc?: ProgramCounter;
  hash: number;
  children: TreeNode[];
  parent?: TreeNode; // undefined for the root.
  // The ids of the goroutines whose stacks contain this node. A goroutine ID is
  // only reflected in the TreeNode corresponding to the goroutine's leaf
  // function. Note that a TreeNode can correspond to a leaf function for some
  // goroutines but not others; in other words, a node can have both children
  // and goroutineIDs. A node's value is gIDs.length plus all the children's
  // value.
  gIDs?: GoroutineId[];
  uid: number; // A unique identifier.

  constructor(nodeData: treeNodeData, parent: TreeNode | undefined) {
    this.funcName = nodeData.funcName;
    this.type = nodeData.type;
    this.value = nodeData.value;
    this.complete = nodeData.complete;
    this.binaryID = nodeData.binaryID || "";
    this.file = nodeData.file;
    this.line = nodeData.line;
    this.pc = nodeData.pc;
    this.hash = nodeData.hash;
    this.parent = parent;

    const children =
      nodeData.children?.map((child) => new TreeNode(child, this)) || [];
    // We order the children by weight here, so the flamegraph shouldn't
    // modify data.
    children.sort((a: TreeNode, b: TreeNode) => {
      if (a.value === b.value) {
        // Use parent hashes as tie-breakers, to ensure a consistent ordering.
        const aPath = a.path().join("-");
        const bPath = b.path().join("-");
        if (aPath === bPath) {
          // This should never happen
          return 0;
        }
        return aPath < bPath ? -1 : 1;
      }
      return (b.value || 0) - (a.value || 0);
    });
    this.children = children;

    this.gIDs = nodeData.gids?.map((g) => ({
      ProcessSnapshotID: g[0],
      ID: g[1],
    }));

    // uid will be filled in later.
    this.uid = -1;
  }

  getName() {
    // TODO get a more generic logic here
    const trimPrefix = (s: string, prefix: string) => {
      if (s.startsWith(prefix)) {
        return s.slice(prefix.length);
      }
      return s;
    };

    return trimPrefix(this.complete, "github.com/cockroachdb/cockroach/pkg/");
  }

  // path returns the list of hashes of nodes on the path from the root to the
  // receiver node.
  path(): number[] {
    const hashes: number[] = [];
    let node: TreeNode = this;
    while (node.hash) {
      hashes.push(node.hash);
      node = node.parent!;
    }
    // Return the pcs from root to the receiver node.
    return hashes.reverse();
  }

  // Find the node corresponding to the given path starting from the given
  // binary.
  findNode(binaryID: string, path?: number[]): TreeNode | undefined {
    if (this.children == undefined) {
      return undefined;
    }
    // If the root's children are binaries, we need to first find the right
    // binary and descend into it. Otherwise, the path starts directly from the
    // root.
    const childrenAreBinaries = this.children[0].pc == undefined;
    if (childrenAreBinaries) {
      return this.children
        .find((child) => child.binaryID == binaryID)
        ?.findNodeInner(path);
    } else {
      return this.findNodeInner(path);
    }
  }

  findNodeInner(path?: number[]): TreeNode | undefined {
    if (path == undefined || path.length == 0) {
      // We've reached the end of the path.
      return this;
    }
    if (this.children === undefined) {
      return undefined;
    }

    // Descend into the child with the correct pc.
    const childHash = path[0];
    const restOfPath = path.slice(1);
    return this.children
      .find((child) => child.hash == childHash)
      ?.findNodeInner(restOfPath);
  }

  hasAncestorInNodes(nodes: Set<TreeNode>) {
    const ancestors = GetPathFromRoot(this, true);
    return ancestors.find((ancestor) => nodes.has(ancestor)) != null;
  }
}

class idAllocator {
  nextID: number;

  constructor() {
    this.nextID = 1;
  }

  next() {
    return this.nextID++;
  }
}

export function ParseTreeData(data: string): TreeNode {
  const assignNodeIDs = (node: TreeNode, idAlloc: idAllocator) => {
    node.uid = idAlloc.next();
    for (const child of node.children) {
      assignNodeIDs(child, idAlloc);
    }
  };

  const parsedJSON = parseLoseless(data, null, parseNumber);
  // Validate the parsed JSON with Zod.
  const treeData = treeNodeSchema.parse(parsedJSON);
  const root = new TreeNode(treeData, undefined /* parent */);
  const idAlloc = new idAllocator();
  // To ensure consistent IDs, we assign them after the tree is fully built.
  assignNodeIDs(root, idAlloc);
  return root;
}

// Maps on the server to TreeNode.MarshalJSON().
//
// Recursive type definition for the zod schema done according to
// https://github.com/colinhacks/zod?tab=readme-ov-file#recursive-types
type treeNodeData = z.infer<typeof baseTreeNodeSchema> & {
  children?: treeNodeData[];
};

const baseTreeNodeSchema = z.object({
  funcName: z.string(),
  type: z.string(),
  value: z.number(),
  complete: z.string(),
  binaryID: z.string().optional(),
  file: z.string(),
  line: z.number(),
  pc: z.union([z.number(), z.bigint()]).optional(),
  hash: z.number(),
  gids: z.array(z.array(z.number())).optional(),
});

const treeNodeSchema: z.ZodType<treeNodeData> = baseTreeNodeSchema.extend({
  // Extend with the recursive fields.
  children: z.lazy(() => treeNodeSchema.array()).optional(),
});

export function GetNodeIds(nodes: Array<TreeNode>): number[] {
  return nodes.map((node) => node.uid);
}

// Does the reverse of the previous serializer.
export function FilterNodesByIds(
  root: TreeNode,
  hashes: number[],
): Array<TreeNode> {
  const result: Array<TreeNode> = [];
  const validIds = new Set<number>(hashes);

  const walk = (node: TreeNode) => {
    if (validIds.has(node.uid)) {
      result.push(node);
    }
    for (const child of node.children || []) {
      walk(child);
    }
  };

  walk(root);

  return result;
}

export interface NodeOrRangeWithWeight {
  // If node is an array, it means we're visually merging multiple elements
  // No node means empty space
  node?: TreeNode | Array<TreeNode>;
  weight: number;
}

export function GetPathFromRoot(
  node: TreeNode | undefined,
  excludeSelf: boolean = false,
): Array<TreeNode> {
  const ancestors: Array<TreeNode> = [];
  while (node) {
    ancestors.push(node);
    node = node.parent;
  }
  ancestors.reverse();
  if (excludeSelf) {
    ancestors.pop();
  }
  return ancestors;
}

// Reduces a set of nodes so that they are non-overlapping.
// This simply means removing any descendents of nodes from the array.
export function GetMinimalAncestors(nodes: Array<TreeNode>): Array<TreeNode> {
  const nodeSet = new Set(nodes);
  const shouldInclude = (node: TreeNode) => !node.hasAncestorInNodes(nodeSet);
  return nodes.filter(shouldInclude);
}

// For each level from the root to the given node, create a set of all nodes
// that are on a path to at least one of the nodes.
export function MergePathsFromRoot(
  nodes: FocusedNodeType | undefined,
): Array<Set<TreeNode>> {
  const result: Array<Set<TreeNode>> = [];

  for (const node of Array.isArray(nodes) ? nodes : [nodes]) {
    const path = GetPathFromRoot(node);
    path.forEach((pathNode, index) => {
      if (index >= result.length) {
        // Ensure there's a set to insert into
        result.push(new Set<TreeNode>());
      }
      result[index].add(pathNode);
    });
  }

  return result;
}

export enum FlamegraphWidthStyle {
  Linear = "linear", // x -> x
  Log = "log", // x -> log(1 + x)
}
