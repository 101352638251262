import {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";
import {IconCalendar} from "./icons/IconCalendar.tsx";

type Props = {
  state: Dayjs | undefined;
  setState: (state: Dayjs | undefined) => void;
};

export const SelectorDate: React.FC<Props> = ({state, setState}) => {
  return (
    <DatePicker
      sx={{width: 300}}
      slots={{
        openPickerIcon: IconCalendar,
      }}
      slotProps={{
        textField: {
          placeholder: "MM / DD / YYYY",
          color: "secondary",
          datatype: "date",
        },
        field: {
          clearable: true,
        },
      }}
      value={state ?? null}
      onChange={(newDate: Dayjs | null) => {
        // Check if the filter was cleared. Also deal with incomplete
        // dates.
        if (newDate == null || !newDate.isValid()) {
          setState(undefined);
          return;
        }

        setState(newDate);
      }}
    />
  );
};
