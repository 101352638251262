interface Event {
  data: string;
  goroutineID: number;
  message: string;
  pid: number;
  timestamp: string;
}

export class EventCounter {
  countByOffset: Map<number, number> = new Map();
  firstSecond: number = -1;
  lastOffset: number = -1;

  constructor(events?: Event[]) {
    if (events) {
      for (const event of events) {
        this.addEvent(event);
      }
    }
  }

  addEvent(event: Event) {
    const timestamp = Date.parse(event.timestamp);
    const second = Math.floor(timestamp / 1000);
    if (this.firstSecond == -1) {
      this.firstSecond = second;
    }
    const offset = second - this.firstSecond;
    this.countByOffset.set(offset, (this.countByOffset.get(offset) || 0) + 1);
    if (offset > this.lastOffset) {
      this.lastOffset = offset;
    }
  }

  getEventsPerSecond(): [number[], number[]] {
    const xData: number[] = [];
    const yData: number[] = [];
    for (let i = 0; i <= this.lastOffset + 1; i++) {
      xData.push(i);
      yData.push(this.countByOffset.get(i) || 0);
    }
    return [xData, yData];
  }
}
