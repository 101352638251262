import {ModuleSpec} from "@graphql/graphql.ts";
import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {DELETE_TYPE_SPEC} from "@util/queries.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import {useApolloClient} from "@apollo/client";
import {TableNoDataMessage} from "@components/TableNoDataMessage.tsx";

type Props = {
  module: ModuleSpec;
  onEditTypeClick: (typeName: string) => void;
};

export const ModuleTypesSpec: React.FC<Props> = ({module, onEditTypeClick}) => {
  const client = useApolloClient();

  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} color="secondary">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell sx={{width: "115px"}} align="right">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {module.typeSpecs.length == 0 ? (
            <TableNoDataMessage message="No type specs" />
          ) : (
            <>
              {module.typeSpecs.map((typeSpec) => (
                <TableRow key={typeSpec.typeQualifiedName}>
                  <TableCell>{typeSpec.typeQualifiedName}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <>
                          <Typography>Edit type spec</Typography>
                          Edit this type spec to control which fields (or fields
                          of fields, recursively) are collected in a snapshot
                          whenever this type is encountered while exploring a
                          function.
                        </>
                      }
                    >
                      <IconButton
                        onClick={() =>
                          void onEditTypeClick(typeSpec.typeQualifiedName)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        <>
                          <Typography>Delete type spec</Typography>
                          Deleting this type spec will cause fields of the
                          respective type to be collected according to the
                          default rules. In particular, if this type is wrapped
                          in an interface, it will not be collected at all.
                        </>
                      }
                    >
                      <IconButton
                        onClick={() => {
                          void client.mutate({
                            mutation: DELETE_TYPE_SPEC,
                            variables: {
                              typeQualifiedName: typeSpec.typeQualifiedName,
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
