import {
  FlamegraphWidthStyle,
  MergePathsFromRoot,
  NodeOrRangeWithWeight,
  TreeNode,
} from "./FlamegraphData.ts";
import {FlamegraphState} from "@components/Flamegraph/FlamegraphState.ts";

// The minimum merge threshold is the percentage of the row's total width under
// which we'll merge individual nodes, as they can't even be clicked on.
// This can be made a setting of the Flamegraph to stop merging for instance.
const MIN_MERGE_THRESHOLD = 0.01;
const DEFAULT_COLLAPSED_WIDTH = 2 * MIN_MERGE_THRESHOLD;

function MergeIntoRange(
  range: NodeOrRangeWithWeight,
  {node, weight}: NodeOrRangeWithWeight,
) {
  range.weight += weight;
  if (!node) {
    return;
  }
  if (!Array.isArray(range.node)) {
    // A blank range can't merge with anything other than a blank range.
    // Only a node can be upgrade to an array before merge.
    range.node = range.node && [range.node];
  }
  if (Array.isArray(node)) {
    range.node?.push(...node);
  } else {
    range.node?.push(node);
  }
}

function GenericCompressToRange(
  nodesWithWeight: Array<NodeOrRangeWithWeight>,
  shouldMerge: (
    range: NodeOrRangeWithWeight,
    weightedNode: NodeOrRangeWithWeight,
  ) => boolean,
): Array<NodeOrRangeWithWeight> {
  const result: Array<NodeOrRangeWithWeight> = [];

  for (const nodeOrRange of nodesWithWeight) {
    const prevNodeWithWeight = result[result.length - 1];

    if (prevNodeWithWeight && shouldMerge(prevNodeWithWeight, nodeOrRange)) {
      MergeIntoRange(prevNodeWithWeight, nodeOrRange);
    } else {
      result.push(nodeOrRange);
    }
  }

  return result;
}

function CompressIntoRanges(
  nodesWithWeight: Array<NodeOrRangeWithWeight>,
): Array<NodeOrRangeWithWeight> {
  const shouldMerge = (
    range: NodeOrRangeWithWeight,
    weightedNode: NodeOrRangeWithWeight,
  ) => {
    if (!range.node || !weightedNode.node) {
      return false;
    }

    if (!weightedNode.node || weightedNode.weight >= MIN_MERGE_THRESHOLD) {
      return false;
    }

    // The ranges/nodes must have the same parent to be able to be merged.
    const prevNode = Array.isArray(range.node) ? range.node[0] : range.node;
    const nextNode = Array.isArray(weightedNode.node)
      ? weightedNode.node[0]
      : weightedNode.node;

    return (
      prevNode?.parent === nextNode.parent &&
      (Array.isArray(range.node) || range.weight < MIN_MERGE_THRESHOLD)
    );
  };

  const result = GenericCompressToRange(nodesWithWeight, shouldMerge);
  // In case the last entry is a single node that also too small, put that in a
  // range of one. It might get expanded later after being assigned the minimal
  // width, or merged with hidden nodes.
  const lastEntry = result.at(-1);
  if (
    lastEntry?.node instanceof TreeNode &&
    lastEntry.weight < MIN_MERGE_THRESHOLD
  ) {
    lastEntry.node = [lastEntry.node];
  }
  return result;
}

function CompressEmptySpace(
  nodesWithWeight: Array<NodeOrRangeWithWeight>,
): Array<NodeOrRangeWithWeight> {
  const shouldMerge = (
    range: NodeOrRangeWithWeight,
    weightedNode: NodeOrRangeWithWeight,
  ) => {
    // Only merge whitespaces
    return !range.node && !weightedNode.node;
  };

  return GenericCompressToRange(nodesWithWeight, shouldMerge);
}

// Rebalances the weights, in case some of them should have a new fixed size.
function NormalizeWeights(
  nodes: Array<NodeOrRangeWithWeight>,
  availableWeight: number,
  fixedWeights: Map<NodeOrRangeWithWeight, number> = new Map(),
) {
  // Sum the weights
  const weightSum = nodes.reduce(
    (partialSum, node) =>
      partialSum + (fixedWeights.has(node) ? 0 : node.weight),
    0,
  );

  // Remove the fixed width from the available weight.
  for (const value of fixedWeights.values()) {
    availableWeight -= value;
  }

  nodes.forEach((node) => {
    const fixedValue = fixedWeights.get(node);
    if (fixedValue != null) {
      node.weight = fixedValue;
    } else {
      node.weight = (availableWeight * node.weight) / weightSum;
    }
  });
}

// Expands a node into the nodes and weights of all its children.
// Hidden values are not included, and children weights are normalized.
export function ExpandNodeOrRange(
  node: TreeNode | Array<TreeNode> | undefined,
  weight: number,
  expandedNodes: Set<TreeNode> | undefined,
  state: FlamegraphState,
): Array<NodeOrRangeWithWeight> {
  if (!(node instanceof TreeNode) || weight === 0) {
    // Ranges or blank expand into blank
    // Just pass along the weight
    return [{weight}];
  }

  const children = node?.children;
  if (!children?.length) {
    return [{weight}];
  }

  const weightFunc = (weight: number) => {
    if (state.widthStyle === FlamegraphWidthStyle.Log) {
      return Math.log(1 + weight);
    }
    return weight;
  };

  const hiddenChildren: Array<TreeNode> = [];
  let result: Array<NodeOrRangeWithWeight> = [];
  for (const child of children) {
    if (state.hiddenNodes.has(child) && !expandedNodes?.has(child)) {
      hiddenChildren.push(child);
    } else {
      result.push({
        node: child,
        weight:
          weight > 0 && (!expandedNodes || expandedNodes.has(child))
            ? weightFunc(child.value)
            : 0,
      });
    }
  }

  // Move all the hidden nodes at the end.
  if (hiddenChildren.length) {
    result.push({
      node: hiddenChildren,
      weight: 0,
    });
  }

  // Normalize to the sum of all children that we expand.
  NormalizeWeights(result, weight);

  result = CompressIntoRanges(result);

  // Now try to fix the width of the compressed ranges. Try to use the default.
  // Only do this if there are no expanded nodes.
  if (!expandedNodes?.size) {
    const fixedWidth: Map<NodeOrRangeWithWeight, number> = new Map();
    for (const node of result) {
      if (Array.isArray(node.node)) {
        fixedWidth.set(node, DEFAULT_COLLAPSED_WIDTH);
      }
    }
    // Cap the limit to half the available space.
    if (fixedWidth.size * DEFAULT_COLLAPSED_WIDTH > weight / 2.0) {
      for (const key of fixedWidth.keys()) {
        fixedWidth.set(key, weight / (2 * fixedWidth.size));
      }
    }

    if (fixedWidth.size) {
      NormalizeWeights(result, weight, fixedWidth);
    }
  }

  // Maybe we'll have to simply compress everything into a single node.
  result = CompressIntoRanges(result);

  if (result.length === 1) {
    // A single compressed child should just have the parent's full width.
    result[0].weight = weight;
  }

  // Any range that was resized to the min but only has a single element should
  // be expanded to see the node.
  for (const node of result) {
    if (
      Array.isArray(node.node) &&
      node.node.length === 1 &&
      !state.hiddenNodes.has(node.node[0])
    ) {
      node.node = node.node[0];
    }
  }

  return result;
}

// Expand the previous row into the next row.
// Also merges consecutive empty spaces.
export function GenerateNextRow(
  nodesWithWeight: Array<NodeOrRangeWithWeight>,
  expandedNodes: Set<TreeNode> | undefined,
  state: FlamegraphState,
): Array<NodeOrRangeWithWeight> {
  const nextNodes: Array<NodeOrRangeWithWeight> = [];

  for (const {node, weight} of nodesWithWeight) {
    const childrenWithWeights = ExpandNodeOrRange(
      node,
      weight,
      expandedNodes,
      state,
    );
    nextNodes.push(...childrenWithWeights);
  }

  return CompressEmptySpace(nextNodes);
}

export function BuildFlamegraphRows(
  root: TreeNode,
  state: FlamegraphState,
): Array<Array<NodeOrRangeWithWeight>> {
  const pathsToFocusedNodes = MergePathsFromRoot(state.focusedEntry);
  const result: Array<Array<NodeOrRangeWithWeight>> = [];
  let nextRow: Array<NodeOrRangeWithWeight> = [{node: root, weight: 1}];

  while (nextRow.length > 1 || nextRow[0]?.node) {
    result.push(nextRow);
    const depth = result.length;
    nextRow = GenerateNextRow(nextRow, pathsToFocusedNodes[depth], state);
  }
  return result;
}
